import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolBarPaper: {
            borderRadius: 0
        },
        toolBar: {
            position: 'relative',
            height: '58px'
        },
        paper: {
            display: 'flex',
            flexWrap: 'wrap',
            borderRadius: 0,
            height: '58px',
            lineHeight: '56px',
            boxShadow: 'none',
            boxSizing: 'border-box'
        },
        divider: {
            margin: theme.spacing(1, 0.5)
        },
        graphTitle: {
            paddingLeft: 20,
            fontSize: 18
        },
        toolBarButtons: {
            '& > *': {
                color: theme.palette.type === 'light' ? theme.palette.grey[500] : theme.palette.grey[200],
                minWidth: 50
            },
            ' & > *:hover ': {
                borderRadius: 'none'
            }
        },
        openHiddenToolBar: {
            color: theme.palette.grey[600],
            minWidth: 40,
            width: 40,
            minHeight: 40,
            borderRadius: 20,
            marginRight: 10
        },
        hiddenToolBar: {
            zIndex: 10
        },
        hiddenToolBarBtn: {
            marginTop: 4,
            zIndex: 20,
            background: theme.palette.background.paper,
            borderRadius: 10,
            padding: 5,
            '& > *': {
                color: theme.palette.grey[500],
                minWidth: 40,
                width: 40,
                minHeight: 40,
                borderRadius: 20,
                margin: '2px 0px'
            }
        },
        viewButton: {
            '& button': {
                color: theme.palette.type === 'light' ? '#9e9e9e' : '#eeeeee'
            }
        },
        viewButtonSelected: {
            color: theme.palette.type === 'light' ? '#b1b1b1 !important' : '#ffffff !important'
        }
    })
);

export const materialColor = [
    '#f44336',
    '#e91e63',
    '#9c27b0',
    '#673ab7',
    '#3f51b5',
    '#2196f3',
    '#03a9f4',
    '#00bcd4',
    '#009688',
    '#4caf50',
    '#8bc34a',
    '#cddc39',
    '#ffeb3b',
    '#ffc107',
    '#ff9800',
    '#f57c00',
    '#ff5722',
    '#795548',
    '#9e9e9e',
    '#607d8b'
];

export const graphColorTheme = [
    '#fafafa',
    '#fce4ec',
    '#bbdefb',
    '#b3e5fc',
    '#b2ebf2',
    '#b2dfdb',
    '#c8e6c9',
    '#f0f4c3',
    '#fff9c4',
    '#ffe0b2',
    '#232323',
    '#263238',
    '#193c4d',
    '#31354b',
    '#3d3f34',
    '#334241',
    '#34485f',
    '#1b2818',
    '#1b3436',
    '#1b2c36'
];

export const lineColor = [
    '#ffffff',
    '#ff9800',
    '#ffeb3b',
    '#ff5722',
    '#8bc34a',
    '#2d6986',
    '#1b3436',
    '#194d48',
    '#862d4b',
    '#232323'
];
