import React, { forwardRef, useImperativeHandle, useEffect, useState, useMemo } from 'react';
import type { FC } from 'react';
import { useForm } from 'react-hook-form';
import { FormHookControl, InputField, SelectField } from '@/components/common/form/FormFields';
import { Box } from '@mui/material';
// import { getThemeSetStyle } from '@/utils/hooks';
// import { FormSelectProps } from '@components/common/form/types';
import CustomAutocomplete from '@components/common/autocomplete';
import { semestersOption } from '@components/common/dataAtRest';

export const formSx = {
    wrap: { width: 'calc(100% / 3)', height: '62px', padding: '0 3em' },
    selectStyle: { backgroundColor: '#fff', height: '44px', '& > div': { fontSize: '14px' } },
    inputStyle: { backgroundColor: '#fff', fontSize: '14px', input: { fontSize: '14px', height: '24px' } },
    labelStyle: { fontSize: '14px', width: '140px', color: '#000', textAlign: 'right', marginRight: '20px' }
};

export type DefayltType = { id: string; name: string };
export interface DefaultValues {
    name: string; // 问题名称
    // keywords: string[]; // 关键词
    proposerTypes: DefayltType[]; // 提出者
    ideaIds: string; // 关联概念
    isEssentialProblem: boolean; // 是否是本质问题
    reference: string; // 参考来源
    situationAuthenticityDegree: number; // 情境真实性
    problemSituationTypes: DefayltType[]; // 问题情境类型
    isCrosscuttingProblem: boolean; // 是否跨学科
    semesters: string[]; // 年级学期
    // !习得维度
    cognitive: boolean; // 认知
    skills: boolean; // 技能
    emotions: boolean; // 情感
    typicalityDegree: number; // 典型程度
    learningTransferType: string; // 促进迁移类型
    complexity: number; // 复杂度
    difficulty: number; // 难度
    illStructureDegree: number; // 问题劣构程度
}
export type GetDefaultValues = {
    ideas: { id: string; title: string }[];
    id?: string;
    acquisitionDimensions?: any; // 习得维度
    content?: string; // 问题内容
    keywords?: string[]; // 关键词
    form?: any;
} & DefaultValues;

export const defaultValues = {
    name: '', // 问题名称
    keywords: '', // 关键词
    proposerTypes: '', // 提出者

    ideaIds: '', // 关联概念

    isEssentialProblem: '', // 是否是本质问题
    reference: '', // 参考来源
    situationAuthenticityDegree: '', // 情境真实性
    problemSituationTypes: '', // 问题情境类型
    isCrosscuttingProblem: '', // 是否跨学科
    semesters: [],

    cognitive: '', // 认知
    skills: '', // 技能
    emotions: '', // 情感

    learningTransferType: '', // 促进迁移类型
    complexity: '', // 复杂度
    difficulty: '', // 难度
    illStructureDegree: '' // 问题劣构程度
};
const boolOption = [
    { id: 'true', name: '是' },
    { id: 'false', name: '否' }
];
const presentedByOption = [
    { id: 'authority', name: '官方' },
    { id: 'teaching_researcher', name: '教研员' },
    { id: 'teacher', name: '教师' },
    { id: 'student', name: '学生' }
];
const typeOption = [
    { id: 'definition', name: '定义' },
    { id: 'viewpoint', name: '观点' },
    { id: 'thesis', name: '论题' }
];
const dimensionOption = [
    { id: 'self', name: '自我' },
    { id: 'other_people', name: '其他人' },
    { id: 'objective_world', name: '客观世界' }
];
const appearsTypeOption = [
    { id: 'planned', name: '计划中的' },
    { id: 'supplementary', name: '增补的' },
    { id: 'created', name: '自我创造的' }
];
const levelOption = [
    { id: 30, name: '低' },
    { id: 50, name: '中' },
    { id: 80, name: '高' }
];
const questionOption = [
    { id: '设计类', name: '设计类' },
    { id: '探究类', name: '探究类' },
    { id: '决策类', name: '决策类' },
    { id: '鉴赏类', name: '鉴赏类' }
];
const moveOption = [
    { id: '高通路迁移', name: '高通路迁移' },
    { id: '低通路迁移', name: '低通路迁移' }
];
const keywordOption = [
    { id: '元认知', title: '元认知' },
    { id: '知识构建', title: '知识构建' },
    { id: '认知论信念', title: '认知论信念' },
    { id: '复杂系统', title: '复杂系统' },
    { id: '知识观', title: '知识观' },
    { id: '概念转变', title: '概念转变' }
];
// const semestersOption = [
//     { id: 'G07F', name: '七年级上学期' },
//     { id: 'G07S', name: '七年级下学期' },
//     { id: 'G08F', name: '八年级上学期' },
//     { id: 'G08S', name: '八年级下学期' },
//     { id: 'G09F', name: '九年级上学期' },
//     { id: 'G09S', name: '九年级下学期' }
// ];

export interface FormRef {
    getWatchSelect: () => any;
    resetForm: () => void;
}
interface FormProps {
    nodeIdMap: Map<string, any>;
    defaultData?: GetDefaultValues;
    changeTitle?: (title: string) => void;
}

// !返回对应的值
const returnStr = (list, val, key = 'id') => {
    if (val === null) return '';
    const findVal = list.find(l => l[key] == (typeof val === 'boolean' ? val + '' : val));
    return findVal && findVal ? JSON.stringify(findVal) : '';
};

/**
 * 问题 新建或编辑 form 表单
 * @returns
 */
const QuestionSetForm = forwardRef<FormRef, FormProps>((props, parentRef) => {
    const { nodeIdMap, defaultData } = props;

    const [keywords, setKeyWords] = useState<KeyWords>([]);
    const [ideaIds, setIdeaIds] = useState<{ id: string }[]>([]);

    // 表单数据
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        control,
        watch
    } = useForm<any>({ defaultValues });

    // !关联概念下拉选择
    const ideaIdsOptions = useMemo(() => {
        const list: { id: string; title: string }[] = [];
        nodeIdMap.forEach(n => {
            list.push({ id: n['properties']['id'], title: n['properties']['name'] });
        });
        return list;
    }, [nodeIdMap]);

    const questionTopList = [
        { type: 'input', label: '问题名称', stateName: 'name' },
        { label: '关键词', stateName: 'keywords', components: FormCustomAutocomplete(keywords, setKeyWords) },
        { label: '提出者', stateName: 'proposerTypes', getAllOption: true, options: presentedByOption },

        {
            label: '关联概念',
            stateName: 'ideaIds',
            components: FormCustomAutocompleteIdeaIds(ideaIds, setIdeaIds, ideaIdsOptions)
        },
        // { label: '关联概念', stateName: 'ideaIds', getAllOption: true, options: typeOption },
        { label: '是否是本质问题', stateName: 'isEssentialProblem', getAllOption: true, options: boolOption },
        { type: 'input', label: '参考来源', stateName: 'reference' },
        { label: '情境真实性', stateName: 'situationAuthenticityDegree', getAllOption: true, options: levelOption },
        { label: '问题情境类型', stateName: 'problemSituationTypes', getAllOption: true, options: questionOption },
        { label: '是否跨学科', stateName: 'isCrosscuttingProblem', getAllOption: true, options: boolOption },
        { label: '学期', stateName: 'semesters', multiple: true, getAllOption: true, options: semestersOption },

        { label: '典型程度', stateName: 'typicalityDegree', getAllOption: true, options: levelOption },
        { label: '促进迁移类型', stateName: 'learningTransferType', getAllOption: true, options: moveOption },
        { label: '复杂度', stateName: 'complexity', getAllOption: true, options: levelOption },
        { label: '难度', stateName: 'difficulty', getAllOption: true, options: levelOption },
        { label: '问题劣构程度', stateName: 'illStructureDegree', getAllOption: true, options: levelOption }
    ];
    // const contentList = [
    //     { label: '认知', stateName: 'cognitive', getAllOption: true, options: boolOption },
    //     { label: '技能', stateName: 'skills', getAllOption: true, options: boolOption },
    //     { label: '情感', stateName: 'emotions', getAllOption: true, options: boolOption }
    // ];
    // const footerList = [
    //     { label: '典型程度', stateName: 'typicalityDegree', getAllOption: true, options: levelOption },
    //     { label: '促进迁移类型', stateName: 'learningTransferType', getAllOption: true, options: moveOption },
    //     { label: '复杂度', stateName: 'complexity', getAllOption: true, options: levelOption },
    //     { label: '难度', stateName: 'difficulty', getAllOption: true, options: levelOption },
    //     { label: '问题劣构程度', stateName: 'illStructureDegree', getAllOption: true, options: levelOption }
    // ];

    const getJSONValue = val => {
        if (!val) return val;
        try {
            return val instanceof Array ? val.map(v => JSON.parse(v)) : JSON.parse(val);
        } catch (error) {
            return val;
        }
    };
    // !数据中的字符串转对象
    const valueToJSON = data => {
        const keyList = Object.keys(data);
        for (const key of keyList) {
            if (getJSONValue(data[key]) instanceof Object) {
                // console.log(data[key], getJSONValue(data[key]));
                data[key] = getJSONValue(data[key]);
            }
        }
        return data;
    };
    // 存在手动输入
    const haveCustomData = (list, val, key = 'id') => {
        const defaultIdList = list.map(d => d.id);
        const defaultOptionList = val
            .map(v => {
                return list.find(l => {
                    if (typeof v === 'object') {
                        return l[key] === v[key];
                    }
                    return l[key] === v;
                });
            })
            .filter(d => d);
        const differenceData = val
            .filter(v => {
                if (typeof v === 'object') {
                    return !defaultIdList.includes(v[key]);
                }
                return !defaultIdList.includes(v);
            })
            .map(n => (typeof n === 'object' ? n : { id: n, title: n }));
        // console.log('defaultOptionList', defaultOptionList, differenceData);
        return [...defaultOptionList, ...differenceData];
    };

    useEffect(() => {
        if (defaultData) {
            reset();
            setKeyWords([]);
            setIdeaIds([]);
            const { keywords, ideas, problemSituationTypes, semesters } = defaultData;

            // console.log('ideaIds', ideas);
            keywords && setKeyWords(haveCustomData(keywordOption, keywords));
            setIdeaIds(ideaIds ? haveCustomData(ideaIdsOptions, ideas) : []);

            const nodeValues = {
                name: defaultData.name, // 问题名称
                proposerTypes: defaultData.proposerTypes[0] ? JSON.stringify(defaultData.proposerTypes[0]) : '', // 提出者
                // // ideaIds: string, // 关联概念
                isEssentialProblem: returnStr(boolOption, defaultData.isEssentialProblem), // 是否是本质问题
                reference: defaultData.reference, // 参考来源
                situationAuthenticityDegree: returnStr(levelOption, defaultData?.situationAuthenticityDegree), // 情境真实性
                problemSituationTypes: JSON.stringify(problemSituationTypes[0]), // 问题情境类型
                isCrosscuttingProblem: returnStr(boolOption, defaultData.isCrosscuttingProblem), // 是否跨学科
                semesters: semesters.map(s => JSON.stringify(s)),
                // // !习得维度
                // cognitive: returnStr(boolOption, defaultData?.acquisitionDimensions['认知']), // 认知
                // skills: returnStr(boolOption, defaultData?.acquisitionDimensions['技能']), // 技能
                // emotions: returnStr(boolOption, defaultData?.acquisitionDimensions['情感']), // 情感
                typicalityDegree: returnStr(levelOption, defaultData.typicalityDegree), // 典型程度
                learningTransferType: returnStr(moveOption, defaultData.learningTransferType), // 促进迁移类型
                complexity: returnStr(levelOption, defaultData.complexity), // 复杂度
                difficulty: returnStr(levelOption, defaultData.difficulty), // 难度
                illStructureDegree: returnStr(levelOption, defaultData.illStructureDegree) // 问题劣构程度
            };
            // console.log('nodeValues', nodeValues);
            reset(nodeValues);
        }
    }, [defaultData]);

    // 编辑的时候设置标题
    useEffect(() => {
        props.changeTitle?.(watch('name'));
    }, [watch('name')]);

    // !子组件暴露的部分方法
    useImperativeHandle(parentRef, () => ({
        getWatchSelect: () => {
            const allValues = valueToJSON(watch());
            const { name, reference, proposerTypes, isCrosscuttingProblem, typicalityDegree } = allValues;
            const { isEssentialProblem, semesters } = allValues;

            // console.log('allValues', allValues, 'semesters', semesters);
            // console.log('keywords', keywords, 'ideaIds', ideaIds);
            return {
                name, // 问题名称
                keywords: keywords.map(k => k.title), // 关键词
                proposerTypes: proposerTypes ? [proposerTypes] : null, // 提出者
                ideaIds: ideaIds.map(n => n.id), // 关联概念
                isEssentialProblem: isEssentialProblem ? isEssentialProblem['id'] === 'true' : null, // 是否是本质问题
                reference, // 参考来源
                situationAuthenticityDegree: allValues.situationAuthenticityDegree['id'] || null, // 情境真实性
                problemSituationTypes: allValues.problemSituationTypes ? [allValues.problemSituationTypes] : null, // 问题情境类型
                isCrosscuttingProblem: isCrosscuttingProblem ? isCrosscuttingProblem['id'] === 'true' : null, // 是否跨学科
                semesters: semesters, // 年级学期
                typicalityDegree: allValues.typicalityDegree['id'] || null, // 典型程度

                // isCoreIdea: isCoreIdea ? isCoreIdea['id'] === 'true' : null, // 核心概念
                // pointingDimension: allValues.pointingDimension['id'] || null, // 指向维度
                // appearanceWay: allValues.appearanceWay['id'] || null, // 出现方式

                // 习得维度
                // acquisitionDimensions: {
                //     认知: cognitive ? cognitive['id'] === 'true' : null,
                //     技能: skills ? skills['id'] === 'true' : null,
                //     情感: emotions ? emotions['id'] === 'true' : null
                // },
                learningTransferType: allValues.learningTransferType['id'] || null, // 促进迁移类型
                complexity: allValues.complexity['id'] || null, // 复杂度
                difficulty: allValues.difficulty['id'] || null, // 难度
                illStructureDegree: allValues.illStructureDegree['id'] || null // 问题劣构程度

                // reference: allValues.reference || null, // 参考来源
                // abstractionDegree: allValues.abstractionDegree['id'] || null, // 抽象程度
                // ideaFocusDegree: allValues.ideaFocusDegree['id'] || null, // 概念聚焦程度
                // inertActivatingProbability: allValues.inertActivatingProbability['id'] || null, // 惰性可激活程度
            };
        },
        resetForm: () => {
            reset();
            setKeyWords([]);
            setIdeaIds([]);
        }
    }));

    return (
        <Box sx={{ widht: '100%', padding: '16px' }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                {questionTopList.map((s, i) =>
                    s.components ? (
                        <div key={i} style={{ width: formSx.wrap.width }}>
                            {s.components}
                        </div>
                    ) : !s.type ? (
                        <FormHookControl key={s.stateName} name={s.stateName} control={control}>
                            <SelectField
                                label={s.label + ':'}
                                control={control}
                                multiple={s.multiple || false}
                                required={false}
                                getAllOption={s?.getAllOption}
                                optionsType={['id', 'name']}
                                registerRef={register(s.stateName, { required: false })}
                                // placeholder={'请选择' + s.label}
                                style={formSx}
                                options={s.options || []}
                            />
                        </FormHookControl>
                    ) : s.type === 'input' ? (
                        <InputField
                            key={i}
                            required={false}
                            label={s.label + ':'}
                            placeholder={'请输入' + s.label}
                            registerRef={register(s.stateName, { required: false })}
                            style={formSx}
                        />
                    ) : (
                        ''
                    )
                )}
            </Box>
            {/* <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}> */}
            {/* <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', ...formSx.wrap }}>
                    <span style={{ color: '#000', marginRight: '8px', width: '135px', textAlign: 'right' }}>
                        习得维度:
                    </span>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                        {contentList.map(s => (
                            <FormHookControl key={s.stateName} name={s.stateName} control={control}>
                                <SelectField
                                    label={s.label + ':'}
                                    control={control}
                                    multiple={false}
                                    required={false}
                                    getAllOption={s?.getAllOption}
                                    optionsType={['id', 'name']}
                                    registerRef={register(s.stateName, { required: false })}
                                    // placeholder={'请选择' + s.label}
                                    style={{
                                        ...formSx,
                                        wrap: {
                                            ...formSx.wrap,
                                            height: '40px',
                                            width: '33%',
                                            flexDirection: 'row',
                                            alignContent: 'center'
                                        },
                                        labelStyle: {
                                            ...formSx.labelStyle,
                                            margin: '8px',
                                            lineHeight: '30px',
                                            width: '60px'
                                        }
                                    }}
                                    options={s.options || []}
                                />
                            </FormHookControl>
                        ))}
                    </Box>
                </Box> */}
            {/* {footerList.map(s => (
                    <FormHookControl key={s.stateName} name={s.stateName} control={control}>
                        <SelectField
                            label={s.label + ':'}
                            control={control}
                            multiple={false}
                            required={false}
                            getAllOption={s?.getAllOption}
                            optionsType={['id', 'name']}
                            registerRef={register(s.stateName, { required: false })}
                            // placeholder={'请选择' + s.label}
                            style={formSx}
                            options={s.options || []}
                        />
                    </FormHookControl>
                ))} */}
            {/* </Box> */}
        </Box>
    );
});
export default QuestionSetForm;

/**
 * 多选 关键词手动输入 + 下拉选择
 * @returns
 */
type KeyWords = { id: number | string; title: string }[];
export function FormCustomAutocomplete(keyWords, setKeyWords) {
    // console.log('关键词 组件中的值', keyWords);
    return (
        <div style={{ width: '100%' }}>
            <CustomAutocomplete
                label='关键词：'
                placeholder='请选择关键词'
                limitTags={1}
                value={keyWords}
                options={keywordOption}
                handleCloseAll={() => {
                    setKeyWords([]);
                }}
                onChange={(e, newVal) => {
                    const saveNewValue = newVal as KeyWords;
                    setKeyWords(saveNewValue);
                }}
                onKeyDown={(e, v) => {
                    if (e.keyCode === 13 && v.toString().trim()) {
                        const kList = [...keyWords, { id: v, title: v }];
                        setKeyWords(kList);
                    }
                }}
                handleTagClick={(v, i) => {
                    const newKwyWord = keyWords.filter(k => k.id !== v.id);
                    setKeyWords(newKwyWord);
                }}
                style={{
                    wrapStyle: { ...formSx.wrap, width: '100%' },
                    autocompleteStyle: { width: '100%', zIndex: '1', input: { height: '26px' } },
                    labelStyle: formSx.labelStyle,
                    textFieldStyle: { backgroundColor: '#fff', width: '100%' }
                }}
            />
        </div>
    );
}

/**
 * 单选概念 输入 + 下拉选择
 * @param ideaIds
 * @param setKeyWords
 * @param options 选择项
 * @returns
 */
export function FormCustomAutocompleteIdeaIds(ideaIds, setIdeaIds, options) {
    // console.log('关联概念', ideaIds);
    return (
        <div style={{ width: '100%' }}>
            <CustomAutocomplete
                label='关联概念：'
                placeholder='请选择关联概念'
                limitTags={1}
                value={ideaIds}
                options={options}
                handleCloseAll={() => {
                    setIdeaIds([]);
                }}
                onChange={(e, newVal) => {
                    // console.log('e onChange', e, newVal);
                    const saveNewValue = newVal as KeyWords;
                    setIdeaIds(saveNewValue);
                }}
                // onKeyDown={(e, v) => {
                //     if (e.keyCode === 13 && v.toString().trim()) {
                //         const kList = [...keyWords, { id: v, title: v }];
                //         setKeyWords(kList);
                //     }
                // }}
                handleTagClick={(v, i) => {
                    const newKwyWord = ideaIds.filter(k => k.id !== v.id);
                    setIdeaIds(newKwyWord);
                }}
                style={{
                    wrapStyle: { ...formSx.wrap, width: '100%' },
                    autocompleteStyle: { width: '100%', zIndex: '1', input: { height: '26px' } },
                    labelStyle: formSx.labelStyle,
                    textFieldStyle: { backgroundColor: '#fff', width: '100%' }
                }}
            />
        </div>
    );
}
