import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import userBackground from '../../../assets/image/user-info.jpg';

// Current Page Style
const drawerWidth = 240;
export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbarHeader: {
            minHeight: '56px !important',
            backgroundColor: theme.palette.type === 'light' ? theme.palette.primary.dark : '#233044',
            color: theme.palette.common.white
        },
        logo: {
            marginRight: theme.spacing(1),
            width: theme.spacing(4),
            height: theme.spacing(4),
            transition: 'margin 100ms'
        },
        logoSingle: {
            marginLeft: -12,
            width: theme.spacing(4),
            height: theme.spacing(4),
            transition: 'margin 100ms'
            // paddingLeft: 24,
            // paddingRight: 16,
        },
        logoLess600: {
            marginLeft: -6
        },
        title: {
            flexGrow: 1,
            fontSize: 18,
            letterSpacing: 3
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            '& .MuiDrawer-paper': {
                backgroundColor: theme.palette.type === 'light' ? '#ffffff' : '#233044'
            },
            '& .MuiDrawer-paperAnchorDockedLeft': {
                borderRight: 0
            }
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen
            })
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
            }),
            overflowX: 'hidden',
            width: theme.spacing(7) + 1,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(7) + 1
            }
        },
        drawerContainer: {
            overflowY: 'auto',
            overflowX: 'hidden',
            marginBottom: 55,
            backgroundColor: theme.palette.type === 'light' ? '#ffffff' : '#233044',
            '&::-webkit-scrollbar': {
                width: 5,
                backgroundColor: theme.palette.type === 'light' ? '#ffffff' : '#233044'
            },
            '&::-webkit-scrollbar-thumb': {
                background: theme.palette.type === 'light' ? '#cecdcdb8' : '#444a53a6',
                borderRadius: '6px'
            }
        },
        drawerContainerClose: {
            marginBottom: 0
        },
        hide: {
            display: 'none'
        },
        toolbar: {
            backgroundImage: `url(${userBackground})`,
            minHeight: '135px !important',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 2),
            color: '#e8e8e8',
            backgroundSize: '240px'
            // backgroundPosition: 0,
            // necessary for content to be below app bar
            // ...theme.mixins.toolbar,
        },
        menuList: {
            height: 50
        },
        menuButton: {
            marginTop: 5
        },
        userHeader: {
            marginTop: 25,
            marginRight: 110,
            width: theme.spacing(6),
            height: theme.spacing(6),
            boxShadow: '0px 0px 20px #294761',
            backgroundColor: theme.palette.secondary.main,
            fontWeight: 'bolder',
            fontSize: 18,
            color: theme.palette.common.white,
            textTransform: 'capitalize',
            filter: 'blur(18)'
        },
        useNameBox: {
            background: '#00000099',
            marginTop: -35,
            color: '#ffffff',
            height: 35,
            lineHeight: '35px',
            fontSize: 15,
            paddingLeft: 25,
            letterSpacing: '2px'
        },
        menuMainIcon: {
            minWidth: 35
        },
        menuIcon: {
            minWidth: 35,
            color: '#000000'
        },
        bottomNavLight: {
            overflow: 'hidden',
            position: 'fixed',
            bottom: 0,
            zIndex: theme.zIndex.drawer + 1,
            width: 240,
            backgroundColor: '#f3f2f2',
            '& .MuiBottomNavigationAction-root': {
                minWidth: 60
            }
        },
        bottomNavDark: {
            overflow: 'hidden',
            position: 'fixed',
            bottom: 0,
            zIndex: theme.zIndex.drawer + 1,
            width: 240,
            color: theme.palette.common.white,
            backgroundColor: '#202b3b',
            '& .MuiBottomNavigationAction-root': {
                minWidth: 60
            }
        },
        nested: {
            paddingLeft: theme.spacing(4)
        },
        listSubTitle: {
            padding: '0px !important'
        },
        bottomNavIcon: {
            color: theme.palette.type === 'light' ? '#848484' : '#f2f2f2',
            '& .MuiSvgIcon-root': {
                width: 22
            }
        },
        emojiPicker: {
            '& .emoji-mart-scroll': {
                height: 160,
                '&::-webkit-scrollbar': {
                    width: 5,
                    backgroundColor: theme.palette.type === 'light' ? '#ffffff' : '#222222'
                },
                '&::-webkit-scrollbar-thumb': {
                    background: theme.palette.type === 'light' ? '#cecdcdb8' : '#444a53a6',
                    borderRadius: '6px'
                }
            }
        },
        emojiStyle: {
            '& > span': {
                left: 10,
                top: 5
            }
        }
    })
);
