import { kngViewColumns, kngManageViewColumns } from './BasicDataType';

// 预设的列
const defaultColumns = [
    { name: 'name', header: 'Name', minWidth: 50, defaultFlex: 2, sortable: false },
    { name: 'age', header: 'Age', maxWidth: 1000, defaultFlex: 1, sortable: false }
];

// 基础数据
type BasicDataType =
    // 资源管理
    'kngManageList' | 'kngViewList';

export type ColumnsType = BasicDataType | undefined;

// 根据字符串获取对应columns
export const getColumnsByType = (type: ColumnsType) => {
    switch (type) {
        case 'kngViewList':
            return kngViewColumns;
        case 'kngManageList':
            return kngManageViewColumns;
        default:
            return defaultColumns;
    }
};
