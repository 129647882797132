import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from '@redux/hooks';
import md5 from 'js-md5';

export const useLoading: (req, setData?) => [loading: boolean, wrapReq: (...args: any[]) => any | any[]] = (
    req,
    setData
) => {
    const [loading, setLoading] = useState(false);
    const wrapReq = useCallback(
        (...args) => {
            setLoading(true);
            if (typeof req === 'function') {
                return req(...args)
                    .then(data => {
                        setLoading(false);
                        setData?.(data);
                        return Promise.resolve(data);
                    })
                    .catch(reason => {
                        setLoading(false);
                        return Promise.reject(reason);
                    });
            } else {
                setLoading(false);
                return req;
            }
        },
        [req]
    );

    return [loading, wrapReq];
};

/** 根据全局设置的类型，返回样式 */
export const getThemeSetStyle = (myStyle?: any) => {
    const currentTheme = useSelector(state => state.theme.currentTheme);

    const defaultStyle = {
        color: currentTheme === 'light' ? '#000' : '#fff',
        background: currentTheme === 'light' ? '#f7f7f7' : '#1f2733',
        backgroundColor: currentTheme === 'light' ? '#f7f7f7' : '#fff'
    };

    return {
        ...defaultStyle
    };
};

// 防抖，触发高频事件后n秒内函数只会执行一次，如果n秒内高频事件再次被触发，则重新计算时间
export function useDebounce(value, delay = 500) {
    const [debounceValue, setDebounceValue] = useState(value);
    useEffect(() => {
        const timer = setTimeout(() => setDebounceValue(value), delay);
        return () => clearTimeout(timer);
    }, [value, delay]);
    return debounceValue;
}

export function debounce(func: any, delay = 500) {
    let timerId: NodeJS.Timeout;
    return function (this: any, ...args: any[]) {
        clearTimeout(timerId);
        timerId = setTimeout(() => {
            func.apply(this, args);
        }, delay);
    };
}

// 导出文件
export const downloadFile = (res, name?: string) => {
    const { data, headers } = res;
    const filename = headers ? headers['content-disposition'] : name;
    const blob = new Blob([data ?? res]);
    let downloadElement = document.createElement('a');
    // URL.createObjectURL()方法会根据传入的参数创建一个指向该参数对象的URL. 这个URL的生命仅存在于它被创建的这个文档里. 新的对象URL指向执行的File对象或者是Blob对象.
    let href = window.URL.createObjectURL(blob);
    downloadElement.href = href;
    downloadElement.download = decodeURIComponent(name ?? filename?.split('filename=')[1]);
    document.body.appendChild(downloadElement);
    downloadElement.click();
    document.body.removeChild(downloadElement);
    // URL.revokeObjectURL()方法会释放一个通过URL.createObjectURL()创建的对象URL. 当你要已经用过了这个对象URL,然后要让浏览器知道这个URL已经不再需要指向对应的文件的时候,就需要调用这个方法.
    window.URL.revokeObjectURL(href);
};

// 密码加密
export const md5Password = password => {
    const salt = '2e08859d4156ba4120ed96795746e19a';
    return Array(3)
        .fill(1)
        .reduce(res => {
            return md5(res + salt);
        }, password);
};
