import React, { useEffect, useState, useCallback, useRef } from 'react';

import clsx from 'clsx';
// import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Box, Button, TextField } from '@mui/material';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AssignmentIcon from '@material-ui/icons/Assignment';
import MoreIcon from '@material-ui/icons/MoreVert';
// import MapIcon from '@material-ui/icons/Map';
import BookIcon from '@material-ui/icons/Book';
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormControl from '@material-ui/core/FormControl';
import ListAltIcon from '@material-ui/icons/ListAlt';
// import { Chip, CircularProgress } from '@material-ui/core';
// import Skeleton from '@material-ui/lab/Skeleton';
import ScatterPlotIcon from '@material-ui/icons/ScatterPlot';
// import redux
import { useSelector } from '../../redux/hooks';
import { useDispatch } from 'react-redux';
import { getGraphDetail, updateGraphTheme } from '../../redux/knm/graphSlice';
// import { getMapNotebooks } from '../../redux/knm/notebookSlice';
import { NotebookSlice } from '../../redux/knm/notebookSlice';
// import mock data
// import { rows } from '../../settings/mocks/DefaultNotebooks';
// import { nodeData, linkData, relations } from '../../settings/mocks/DefaultGraph';
// import panel page
// import {
//     NodeInfoEditPanel,
//     LinkInfoEditPanel,
//     GraphBasicInfoEditPanel,
//     AddNewNodePanel,
//     AddNewLinkPanel,
//     ModifyGraphThemePanel
// } from './knmDetailSubPage/infoPanelContent';

// import graph view
import { GraphView, NotebookListView, NewNoteBookView } from './knmDetailSubPage';
// import emoji
// import { Emoji } from 'emoji-mart';
// import 'emoji-mart/css/emoji-mart.css';
// import { findAllMapLinks } from '../../redux/knm/linkSlice';
// import { findAllMapNodes } from '../../redux/knm/nodeSlice';
// import { BasicDataTable } from '@components/common/dataTable';

import { State, SnackbarAlert } from '@components/common/SnackbarAlert';
import { getQuestionList, delQuestion } from '@/api/creatMap';
import DataGrid from '@components/common/datagrid';
// import CreateQuestion from '@components/sections/detail-page/CreateQuestion';
import NotebookDefault from '@/components/sections/detail-page/NotebookDefault';
import PopConfirm from '@components/common/popconfirm';
import { customizeToast } from '@components/common/toast';

// import IconButton from '@material-ui/core/IconButton';
import { CloudDownload, CloudUpload } from '@material-ui/icons';
import { debounce, downloadFile } from '@/utils/hooks';
import { useStyles, materialColor, graphColorTheme, lineColor } from '@/utils/consts';
import { ExcelModal } from '@/components/common/dialogBox/ExcelModal';
import { excelUploadConcept, excelUploadRelationship, exportConception, exportRelationship } from '@/api/creatMap';

import type { ChartRef } from '@/components/common/knowledgeGraph/KnowledgeGraph';
import type { UploadParams } from '@/api/creatMap';

const cerateList = [
    { name: '创建问题', type: 'question' }
    // { name: '创建案例', type: 'demo' },
    // { name: '创建任务', type: 'task' }
];

interface KnowledgeGraphState {
    node: any[];
    link: any[];
    relations: any[];
    themeColor: string; // 主题颜色
    lineStyleType: 'solid' | 'dashed' | 'dotted'; //关联线样式
    lineStyleColor: string; // 关联线颜色
    lineStyleWidth: number; // 关联线宽度
    lineStyleOpacity: number; // 关联线透明度
    lineStyleCurveness: number; // 关联线曲度
    labelFontSize: number; //节点标签字体大小
    labelPosition: 'top' | 'left' | 'right' | 'bottom' | 'inside';
    edgeLabelFontSize: number;
    layout: 'force' | 'circular';
    forcePower: number;
}
type TopCreatType = null | 'question' | 'demo' | 'task';
let timerId;
const defaultOpenInfoPanel = {
    graphBasicInfoEditPanel: false,
    addNewNodePanel: false,
    addNewLinkPanel: false,
    modifyGraphThemePanel: false,
    nodeInfoEditPanel: false,
    linkInfoEditPanel: false,
    excelDown: false,
    excelUp: false
};

/**
 * 截取指定长度的字符串
 * @param str 全部字符串
 * @param length 需要截取的长度
 * @returns result, splitResult, row
 */
export const splitStringByLength = (str, length) => {
    let result = '';
    let splitResult = '';
    let row = 0;
    if (str < 4) return { result: str, row: 0 };
    for (let i = 0; i < str.length; i += length) {
        const subStr = str.substr(i, length);
        const isEnd = str.endsWith(subStr);
        result += subStr + (!isEnd ? '\n\n' : '');
        row += !isEnd ? 2 : 0;
        // !截取五行，第五行不添加换行
        splitResult += i < 50 ? subStr + (i < 40 ? '\n\n' : '') : '';
    }
    return { result, splitResult, row };
};

/**
 * * Knowledge Network Map: Main Component Page
 */
export const KNMDetailPage: React.FC = () => {
    // component class style
    const classes = useStyles();
    const chartRef = useRef<ChartRef>(null);
    // redux
    const dispatch = useDispatch();
    const jwt = useSelector(state => state.user.token);
    const currentKnmMapInfo = useSelector(state => state.knmMap.currentOpenMapInfo);
    // const knmInfoLoading = useSelector(state => state.knmMap.loading);
    const graphLoading = useSelector(state => state.graph.loading);
    const currentTheme = useSelector(state => state.theme.currentTheme);
    const currentOpenGraphInfo = useSelector(state => state.graph.currentOpenGraphInfo);
    const currentOpenGraphLoading = useSelector(state => state.graph.loading);
    const currentActivatedTab = useSelector(state => state.pageTabs.currentActivatedTab);
    // const alreadyOpenedTabs = useSelector(state => state.pageTabs.alreadyOpenedTabs);
    // const currentSystemNavItems = useSelector(state => state.pageTabs.projectNavMenuItems);

    // const currentMapNotebooks = useSelector(state => state.notebook.currentNotebooksList);
    // media query
    const mediaWidth = useMediaQuery('(min-width:1050px)');
    /**
     * * Components State
     */
    // graph edit tool bar
    const [openHiddenToolBar, setOpenHiddenToolBar] = useState(false);
    // switch view
    const [views, setViews] = useState<string>('graphView');
    // Edit Panel : node & link info panel: graph basic info + new node + new link + modify node or link info + ...
    // 编辑 不同面板的编辑状态
    const [openInfoPanel, setOpenInfoPanel] = useState(defaultOpenInfoPanel);

    // each time when change currenOpenGraphInfo, close the info panel
    useEffect(() => {
        handleCloseInfoPanel();
    }, [currentActivatedTab]);

    // node name show in InfoPanel
    const [nodeName, setNodeName] = useState('');
    // 节点ID
    const [nodeId, setNodeId] = useState('');
    // 全部节点ID
    const [nodeIdMap, setNodeIdMap] = useState<Map<string, any>>(new Map());
    // 刷新表格
    const [renderTable, setRenderTable] = useState(0);
    // 提示信息
    // const [snackbar, setSnackbar] = useState<State>({ open: false, type: 'success', msg: '' });
    // 弹窗信息
    const [popConfirmData, setPopConfirmData] = useState<{ id: string; open: boolean; anchorEl: any }>({
        id: '',
        open: false,
        anchorEl: null
    });

    // Graph 中的数据
    const [graph, setGraph] = useState<KnowledgeGraphState>({
        node: [],
        link: [],
        relations: currentOpenGraphInfo['relations'],
        themeColor: currentOpenGraphInfo['themeColor'], // 主题颜色
        lineStyleType: currentOpenGraphInfo['lineStyleType'], //关联线样式
        lineStyleColor: currentOpenGraphInfo['lineStyleColor'], // 关联线颜色
        lineStyleWidth: currentOpenGraphInfo['lineStyleWidth'], // 关联线宽度
        lineStyleOpacity: currentOpenGraphInfo['lineStyleOpacity'], // 关联线透明度
        lineStyleCurveness: currentOpenGraphInfo['lineStyleCurveness'], // 关联线曲度
        labelFontSize: currentOpenGraphInfo['labelFontSize'], //节点标签字体大小
        labelPosition: currentOpenGraphInfo['labelPosition'],
        edgeLabelFontSize: currentOpenGraphInfo['edgeLabelFontSize'],
        layout: currentOpenGraphInfo['layout'],
        forcePower: currentOpenGraphInfo['forcePower']
    });

    // !设置chart图的数据
    useEffect(() => {
        setViews('graphView');
        // change the format to which echarts could read
        if (Object.keys(currentOpenGraphInfo).length !== 0) {
            let graphNode: any[] = [];
            let graphLink: any[] = [];

            // console.log('currentOpenGraphInfo 初始化数据', currentOpenGraphInfo);
            const nNodeList = currentOpenGraphInfo['nodes'];
            const nLinkList = currentOpenGraphInfo['links'];
            const nodeMap = new Map();
            const nodeIdMap: Map<string, any> = new Map();

            nNodeList.map(node => {
                const { name, isCoreIdea, contentSemesterMap } = node?.properties;

                // 当是核心概念的时候需要显示的是内容
                let allName = '',
                    splitName = '',
                    rowNum = 0;
                if (contentSemesterMap && isCoreIdea) {
                    const contentSemesterMapJson = JSON.parse(contentSemesterMap);
                    const str = splitStringByLength(Object.keys(JSON.parse(contentSemesterMap)).join(''), 10);
                    allName = str.result;
                    splitName = str.splitResult as string;
                    rowNum = str.row;
                }

                nodeMap.set(node?.identity, node);
                nodeIdMap.set(node['properties']['id'], node);

                graphNode.push({
                    // !此处的id需要唯一
                    id: node['identity'],
                    nodeId: node['properties']['id'],
                    // name: contentSemesterMap && isCoreIdea ? splitName : name,
                    name,
                    allName: contentSemesterMap && isCoreIdea ? allName : name, // 详情
                    allMap: (contentSemesterMap && JSON.parse(contentSemesterMap)) || name,
                    // draggable: true, // 节点是否可拖拽，只在使用力引导布局的时候有用。
                    symbolSize: node['properties']['nodeSize'] || 70,
                    itemStyle: {
                        // 设置透明度
                        opacity: 1,
                        // 蓝色：大概念 红色：小概念
                        color: isCoreIdea ? '#5170d4' : node['properties']['nodeColor'] || '#f44336'
                        // color: isCoreIdea ? '#333333' : '#d2cece'
                        // color: isCoreIdea ? '#a5a2a2' : node['properties']['nodeColor'] || '#f44336'
                    }
                    // symbolSize: isCoreIdea
                    //     ? [
                    //           splitName.length >= 5 ? 10 * 13 : 60,
                    //           rowNum > 2 ? (rowNum >= 10 ? 9 * 14 : rowNum * 14) : 60
                    //       ]
                    //     : node['properties']['nodeSize'] || 60,
                    // symbol: isCoreIdea ? 'rect' : '', // 设置节点样式为方形
                });
            });

            const noContain = ['相似', '相关'];

            // !link 连接线
            nLinkList.map(link => {
                if (link['start'] && link['end']) {
                    graphLink.push({
                        value: link['identity'],
                        source: link['start'] + '',
                        target: link['end'] + '',
                        label: {
                            show: true,
                            position: 'middle',
                            formatter: link['properties']['name'] ?? ''
                        },
                        // 设置边透明度，范围 0-1
                        lineStyle: {
                            opacity: 1
                        },
                        // 结束点、开始点 的样式
                        symbol: noContain.includes(link['properties']['name']) ? 'none' : ''
                        // symbolSize: [10, 20]
                    });
                }
            });

            setNodeIdMap(nodeIdMap);
            setGraph({
                node: graphNode,
                link: graphLink,
                relations: currentOpenGraphInfo['relations'],
                themeColor: currentOpenGraphInfo['themeColor'], // 主题颜色
                lineStyleType: currentOpenGraphInfo['lineStyleType'], //关联线样式
                lineStyleColor: currentOpenGraphInfo['lineStyleColor'], // 关联线颜色
                lineStyleWidth: currentOpenGraphInfo['lineStyleWidth'], // 关联线宽度
                lineStyleOpacity: currentOpenGraphInfo['lineStyleOpacity'], // 关联线透明度
                lineStyleCurveness: currentOpenGraphInfo['lineStyleCurveness'], // 关联线曲度
                labelFontSize: currentOpenGraphInfo['labelFontSize'], //节点标签字体大小
                labelPosition: currentOpenGraphInfo['labelPosition'],
                edgeLabelFontSize: currentOpenGraphInfo['edgeLabelFontSize'],
                layout: currentOpenGraphInfo['layout'],
                forcePower: currentOpenGraphInfo['forcePower']
            });
        }
    }, [currentOpenGraphInfo, currentOpenGraphLoading]);

    // detail page info : map title & emoji
    const [detailPageKnmInfo, setDetailPageKnmInfo] = useState({
        // icon: currentKnmMapInfo['emoji'],
        title: currentKnmMapInfo['title']
    });
    // 列表视图 创建
    const [topCreatType, setTopCreatType] = useState<TopCreatType>(null);
    // open create Question
    const [showCreateQuestion, setShowCreateQuestion] = useState(false);
    // 问题ID
    const [questionId, setQuestionId] = useState('');
    // 问题类型
    const [questionType, setQuestionType] = useState<'create' | 'repair'>('create');

    // Excel 下载上传
    const [openModal, setOpenModal] = useState({
        show: false,
        type: ''
    });

    useEffect(() => {
        setDetailPageKnmInfo({
            // icon: currentKnmMapInfo['emoji'],
            title: currentKnmMapInfo['title']
        });
    }, [currentKnmMapInfo]);

    // useEffect(() => {
    //     if (snackbar.open) {
    //         setTimeout(() => {
    //             setSnackbar({ ...snackbar, open: false });
    //         }, 2000);
    //     }
    // }, [snackbar.open]);

    // switch view -> graph edit tool bar: three view - graphView & notebookListView & newNotebookView
    const handleSwitchViews = async (newView: string, isOpenSpecificNotebook = false) => {
        if (newView !== null) {
            // if open neeNotebookView and is not open specific notebook, then means to create a new notebook
            if (newView === 'newNotebookView' && !isOpenSpecificNotebook) {
                await dispatch(NotebookSlice.actions.clearDetail());
            }
            setViews(newView);
            setOpenHiddenToolBar(false);
            // close graph info edit panel
            handleCloseInfoPanel();
        }
        setShowCreateQuestion(false);
    };

    // open hidden tool bar when media width less than 950px
    const handleToolBarOpen = () => {
        setOpenHiddenToolBar(!openHiddenToolBar);
    };

    // close all info panel
    const handleCloseInfoPanel = async () => {
        await setOpenInfoPanel({
            ...defaultOpenInfoPanel
            // graphBasicInfoEditPanel: false,
            // addNewNodePanel: false,
            // addNewLinkPanel: false,
            // modifyGraphThemePanel: false,
            // nodeInfoEditPanel: false,
            // linkInfoEditPanel: false,
            // excelDownUp: false
        });
    };

    // open Graph Basic Info Edit Panel
    const handleOpenGraphBasicInfoEditPanel = () => {
        setOpenHiddenToolBar(false);
        setOpenInfoPanel({
            ...defaultOpenInfoPanel,
            graphBasicInfoEditPanel: true
            // addNewNodePanel: false,
            // addNewLinkPanel: false,
            // modifyGraphThemePanel: false,
            // nodeInfoEditPanel: false,
            // linkInfoEditPanel: false,
            // excelDownUp: false
        });
    };

    // open Add New Node Panel
    const handleOpenAddNewNodePanel = () => {
        setOpenHiddenToolBar(false);
        setOpenInfoPanel({
            ...defaultOpenInfoPanel,
            // graphBasicInfoEditPanel: false,
            addNewNodePanel: true
            // addNewLinkPanel: false,
            // modifyGraphThemePanel: false,
            // nodeInfoEditPanel: false,
            // linkInfoEditPanel: false,
            // excelDownUp: false
        });
    };

    // open Add New Link Panel
    const handleOpenAddNewLinkPanel = () => {
        setOpenHiddenToolBar(false);
        setOpenInfoPanel({
            ...defaultOpenInfoPanel,
            // graphBasicInfoEditPanel: false,
            // addNewNodePanel: false,
            addNewLinkPanel: true
            // modifyGraphThemePanel: false,
            // nodeInfoEditPanel: false,
            // linkInfoEditPanel: false,
            // excelDownUp: false
        });
    };

    // open Modify Graph Theme Panel
    const handleOpenModifyGraphThemePanel = () => {
        setOpenHiddenToolBar(false);
        setOpenInfoPanel({
            ...openInfoPanel,
            // graphBasicInfoEditPanel: false,
            // addNewNodePanel: false,
            // addNewLinkPanel: false,
            modifyGraphThemePanel: true
            // nodeInfoEditPanel: false,
            // linkInfoEditPanel: false
        });
    };

    /**
     * 修改面板的状态
     * @param attr 属性名
     * @param type 状态
     */
    const handleChangeTagsType = (attr, type) => {
        setOpenHiddenToolBar(false);
        setOpenInfoPanel(pre => {
            Array.from(Object.keys(pre), p => (pre[p] = false));
            return { ...pre, [attr]: type };
        });
    };

    // 关系地图节点点击
    const echartsClick = {
        click: e => {
            // console.log(e, e.dataType);
            if (e.dataType === 'node') {
                setNodeName(e.name);
                setNodeId(e.data.nodeId);
                setOpenInfoPanel({
                    ...defaultOpenInfoPanel,
                    // graphBasicInfoEditPanel: false,
                    // addNewNodePanel: false,
                    // addNewLinkPanel: false,
                    // modifyGraphThemePanel: false,
                    nodeInfoEditPanel: true
                    // linkInfoEditPanel: false,
                    // excelDownUp: false
                });
            }
            if (e.dataType === 'edge') {
                setNodeName(e.data.label.formatter);
                setNodeId(e.value);
                setOpenInfoPanel({
                    ...defaultOpenInfoPanel,
                    // graphBasicInfoEditPanel: false,
                    // addNewNodePanel: false,
                    // addNewLinkPanel: false,
                    // modifyGraphThemePanel: false,
                    // nodeInfoEditPanel: false,
                    linkInfoEditPanel: true
                    // excelDownUp: false
                });
            }
        }
    };

    // add graph node
    const handleAddNode = (newNode: any) => {
        // 将原本的数组深拷贝到新的数组中, 防止useState无法检测数组内容的变化
        let nodes = graph.node.concat();
        nodes.push(newNode);
        setGraph({
            ...graph,
            node: nodes
        });
    };

    // add graph link & relation
    const handleAddNewLink = (newLink: any, newRelation: any) => {
        let link = graph.link.concat();
        link.push(newLink);
        let relations = graph.relations.concat();
        relations.push(newRelation);
        setGraph({
            ...graph,
            link: link,
            relations: relations
        });
    };

    // modify graph theme style
    const handleModifyGraph = (target: string, newValue: any) => {
        setGraph({
            ...graph,
            [target]: newValue
        });
    };
    // save the graph theme
    const handleSaveGraphTheme = async () => {
        // 1. update theme to server
        await dispatch(
            updateGraphTheme({
                jwt: jwt,
                currentOpenMapId: currentKnmMapInfo['id'] || '',
                currentGraphId: currentOpenGraphInfo['id'],
                newGraphTheme: graph
            })
        );
        // 2. get new knm graph detail
        dispatch(
            getGraphDetail({
                currentOpenMapId: currentKnmMapInfo['id'],
                jwt: jwt
            })
        );
    };

    // 问题列表 创建
    const handleChangeType = type => {
        setQuestionType('create');
        setTopCreatType(type);
        if (type === 'question') {
            setShowCreateQuestion(true);
        }
    };

    // !权限审批 - 表格
    const dataSource = useCallback(
        ({ skip, limit, sortInfo }) => {
            const params: any = {};
            return getQuestionList({
                pageNumber: skip / limit + 1,
                pageSize: limit,
                // ideaId: currentActivatedTab.id,
                ...params
            }).then(({ code, data }) => {
                // data.data.list.map(
                //     n =>
                //         (n.ideaIds = n.ideaIds.map(idea =>
                //             nodeIdMap.has(idea) ? nodeIdMap.get(idea)['properties']['name'] : ''
                //         ))
                // );
                return {
                    data: code === 0 ? data.list : [],
                    count: code === 0 ? data.totalCount : 0
                };
            });
        },
        [renderTable, nodeIdMap]
    );

    // 表格 -> 删除
    const delTableQuestion = id => {
        // let snackbarData = snackbar;
        delQuestion(id).then(res => {
            if (res.code === 0) {
                setRenderTable(new Date().getTime());
                customizeToast.success('删除成功');
                setPopConfirmData({ id: '', open: false, anchorEl: null });
            } else {
                customizeToast.error('删除失败');
            }
        });
    };

    // 导入 模板
    const handleImport = useCallback(
        async (file, cb, data) => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('graphId', currentKnmMapInfo.id);

            let upExcelData: any = null;
            if (data === 'gn') {
                upExcelData = await excelUploadConcept(formData);
            } else if (data === 'gx') {
                upExcelData = await excelUploadRelationship(formData);
            }
            if (upExcelData.code === 0) {
                cb();
                customizeToast.success('Excel上传成功');
                setOpenModal({ show: false, type: '' });
                // !刷新数据
                dispatch(
                    getGraphDetail({
                        currentOpenMapId: currentKnmMapInfo.id,
                        xhrParams: {
                            graphId: currentKnmMapInfo.id
                        }
                    })
                );
            } else {
                customizeToast.error(upExcelData.data.msg ?? 'Excel上传失败');
            }
            // excelUploadConcept(formData).then(res => {
            //     if (res.code === 0) {
            //         cb();
            //         customizeToast.success('Excel上传成功');
            //         setOpenModal({ show: false, type: '' });
            //         // !刷新数据
            //         dispatch(
            //             getGraphDetail({
            //                 currentOpenMapId: currentKnmMapInfo.id,
            //                 xhrParams: {
            //                     graphId: currentKnmMapInfo.id
            //                 }
            //             })
            //         );
            //     } else {
            //         customizeToast.error(res.data.msg ?? 'Excel上传失败');
            //     }
            // });
        },
        [currentKnmMapInfo.id]
    );

    // 查询图中的节点 同时设置透明度
    const handelSearchNode = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        const searchName = e.target.value.trim();
        // console.log('searchName', searchName);
        const newNode = graph.node.map(n => {
            if (!n.name.includes(searchName)) {
                return { ...n, itemStyle: { ...n.itemStyle, opacity: 0.2 } };
            }
            return { ...n, itemStyle: { ...n.itemStyle, opacity: 1 } };
        });
        const newLink = graph.link.map(l => ({ ...l, lineStyle: { ...l.lineStyle, opacity: searchName ? 0.1 : 1 } }));
        setGraph({ ...graph, node: newNode, link: newLink });
        // setTimeout(showToolTips, 100);
    });
    const showToolTips = () => {
        const chartInstance = chartRef.current?.graphChartRef?.getEchartsInstance();
        // console.log('chartRef chartInstance', chartInstance);
        // chartInstance.dispatchAction({
        //     type: 'showTip',
        //     seriesIndex: 0, // 要显示tooltip的系列索引
        //     dataIndex: 1 // 要显示tooltip的数据索引
        // });
        chartInstance.dispatchAction({
            type: 'dataZoom',
            seriesIndex: -30,
            dataIndex: 70
        });
    };

    return (
        <React.Fragment>
            {/* 工具栏 */}
            <Paper className={classes.toolBarPaper}>
                {mediaWidth ? (
                    <Grid
                        className={classes.toolBar}
                        container
                        direction='row'
                        // justifyContent="space-between"
                        alignItems='center'
                    >
                        <Grid item>
                            <Paper className={classes.paper}>
                                <Grid container spacing={2} className={classes.graphTitle}>
                                    <React.Fragment>
                                        <Grid item style={{ paddingTop: 14 }}>
                                            {/* {detailPageKnmInfo.icon && typeof detailPageKnmInfo.icon !== 'object' ? (
                                                <Emoji
                                                    emoji={detailPageKnmInfo.icon as string}
                                                    set='twitter'
                                                    size={24}
                                                />
                                                <></>
                                            ) : (
                                                ''
                                            )} */}
                                        </Grid>
                                        <Grid item>{detailPageKnmInfo.title}</Grid>
                                    </React.Fragment>
                                </Grid>
                            </Paper>
                        </Grid>
                        {!graphLoading && (
                            <Grid item style={{ marginLeft: 20 }}>
                                <Paper elevation={0} className={classes.paper}>
                                    <Divider flexItem orientation='vertical' className={classes.divider} />
                                    <ToggleButtonGroup
                                        exclusive
                                        aria-label='text alignment'
                                        size='small'
                                        className={clsx(classes.toolBarButtons, classes.viewButton)}
                                    >
                                        {views === 'graphView' ? (
                                            <Button
                                                className={classes.viewButtonSelected}
                                                value='graphView'
                                                aria-label='centered'
                                                disabled
                                                variant='contained'
                                                onClick={() => handleSwitchViews('graphView')}
                                            >
                                                <ScatterPlotIcon style={{ color: '#1f5ea7' }} />
                                            </Button>
                                        ) : (
                                            <Tooltip title='大观念视图' arrow>
                                                <Button
                                                    value='graphView'
                                                    aria-label='centered'
                                                    onClick={() => handleSwitchViews('graphView')}
                                                >
                                                    <ScatterPlotIcon style={{ color: '#1f5ea7' }} />
                                                </Button>
                                            </Tooltip>
                                        )}
                                        {views === 'notebookListView' ? (
                                            <Button
                                                className={classes.viewButtonSelected}
                                                value='notebookListView'
                                                aria-label='centered'
                                                disabled
                                                variant='contained'
                                                onClick={() => handleSwitchViews('notebookListView')}
                                            >
                                                <ListAltIcon style={{ color: '#1f5ea7' }} />
                                            </Button>
                                        ) : (
                                            <Tooltip title='问题列表' arrow>
                                                <Button
                                                    value='notebookListView'
                                                    aria-label='centered'
                                                    onClick={() => handleSwitchViews('notebookListView')}
                                                >
                                                    <ListAltIcon style={{ color: '#1f5ea7' }} />
                                                </Button>
                                            </Tooltip>
                                        )}
                                        {/* {
                                                views === 'newNotebookView' ? (
                                                    <Button
                                                        className={classes.viewButtonSelected}
                                                        value="newNotebookView"
                                                        aria-label="centered"
                                                        disabled
                                                        variant="contained"
                                                        onClick={() => handleSwitchViews('newNotebookView')}
                                                    >
                                                        <BookIcon />
                                                    </Button>
                                                ) : (
                                                    <Tooltip title='知识笔记视图' arrow>
                                                        <Button
                                                            value="newNotebookView"
                                                            aria-label="centered"
                                                            onClick={() => {
                                                                // clear createSpecificNotebookId, means to create a new notebook by own choice
                                                                dispatch(NotebookSlice.actions.createSpecificNotebook({
                                                                    createSpecificNotebookRelationType: 'node',
                                                                    createSpecificNotebookRelationId: null
                                                                }));
                                                                handleSwitchViews('newNotebookView')
                                                            }}
                                                        >
                                                            <BookIcon />
                                                        </Button>
                                                    </Tooltip>
                                                )
                                            } */}
                                    </ToggleButtonGroup>
                                    {views === 'graphView' && (
                                        <>
                                            <Divider flexItem orientation='vertical' className={classes.divider} />
                                            <ToggleButtonGroup
                                                size='small'
                                                exclusive
                                                aria-label='text alignment'
                                                className={classes.toolBarButtons}
                                            >
                                                <Tooltip title='修改基本信息' arrow>
                                                    {openInfoPanel.graphBasicInfoEditPanel ? (
                                                        <Button
                                                            value='修改基本信息'
                                                            aria-label='centered'
                                                            onClick={handleOpenGraphBasicInfoEditPanel}
                                                            style={{ backgroundColor: '#757d873d' }}
                                                        >
                                                            <AssignmentIcon />
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            value='修改基本信息'
                                                            aria-label='centered'
                                                            onClick={handleOpenGraphBasicInfoEditPanel}
                                                        >
                                                            <AssignmentIcon />
                                                        </Button>
                                                    )}
                                                </Tooltip>
                                                <Tooltip title='新建概念节点' arrow>
                                                    {openInfoPanel.addNewNodePanel ? (
                                                        <Button
                                                            value='新建概念节点'
                                                            aria-label='centered'
                                                            onClick={handleOpenAddNewNodePanel}
                                                            style={{ backgroundColor: '#757d873d' }}
                                                        >
                                                            <AddCircleOutlineIcon />
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            value='新建概念节点'
                                                            aria-label='centered'
                                                            onClick={handleOpenAddNewNodePanel}
                                                        >
                                                            <AddCircleOutlineIcon />
                                                        </Button>
                                                    )}
                                                </Tooltip>
                                                <Tooltip title='添加节点关联' arrow>
                                                    {openInfoPanel.addNewLinkPanel ? (
                                                        <Button
                                                            value='添加节点关联'
                                                            aria-label='right aligned'
                                                            onClick={handleOpenAddNewLinkPanel}
                                                            style={{ backgroundColor: '#757d873d' }}
                                                        >
                                                            <AccountTreeIcon />
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            value='添加节点关联'
                                                            aria-label='right aligned'
                                                            onClick={handleOpenAddNewLinkPanel}
                                                        >
                                                            <AccountTreeIcon />
                                                        </Button>
                                                    )}
                                                </Tooltip>

                                                <Tooltip title='' arrow>
                                                    <Button
                                                        aria-label='toggle password visibility'
                                                        onClick={() => {
                                                            setOpenModal({ show: true, type: 'up' });
                                                            handleChangeTagsType('excelUp', true);
                                                        }}
                                                        sx={{
                                                            backgroundColor: openInfoPanel.excelUp ? '#757d873d' : ''
                                                        }}
                                                    >
                                                        <CloudUpload style={{ color: '#166bcc' }} />
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title='' arrow>
                                                    <Button
                                                        aria-label='toggle password visibility'
                                                        onClick={() => {
                                                            setOpenModal({ show: true, type: 'down' });
                                                            handleChangeTagsType('excelDown', true);
                                                        }}
                                                        sx={{
                                                            backgroundColor: openInfoPanel.excelDown ? '#757d873d' : ''
                                                        }}
                                                    >
                                                        <CloudDownload style={{ color: '#166bcc' }} />
                                                    </Button>
                                                </Tooltip>
                                            </ToggleButtonGroup>
                                        </>
                                    )}
                                    {/* 问题列表 */}
                                    {views === 'notebookListView' && (
                                        <>
                                            {cerateList.map(c => (
                                                <Button
                                                    sx={{ m: 1 }}
                                                    variant='contained'
                                                    size='small'
                                                    key={c.type}
                                                    onClick={() => handleChangeType(c.type)}
                                                >
                                                    {c.name}
                                                </Button>
                                            ))}
                                        </>
                                    )}
                                    <Box
                                        display='flex'
                                        flexDirection='column'
                                        justifyContent='center'
                                        sx={{ position: 'absolute', right: 40, top: 10 }}
                                    >
                                        <TextField
                                            id='outlined-required'
                                            label='节点查询'
                                            size='small'
                                            sx={{ width: 350 }}
                                            onChange={handelSearchNode}
                                        />
                                    </Box>
                                </Paper>
                            </Grid>
                        )}
                    </Grid>
                ) : (
                    <Grid
                        className={classes.toolBar}
                        container
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                    >
                        <Grid item>
                            <Paper className={classes.paper}>
                                <Grid container spacing={2} className={classes.graphTitle}>
                                    <Grid item style={{ paddingTop: 14 }}>
                                        {/* {detailPageKnmInfo.icon && typeof detailPageKnmInfo.icon !== 'object' ? (
                                            <Emoji emoji={detailPageKnmInfo.icon as string} set='twitter' size={24} />
                                        ) : (
                                            ''
                                        )} */}
                                    </Grid>
                                    <Grid item>{detailPageKnmInfo.title}</Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item style={{ marginRight: 20 }}>
                            <Paper elevation={0} className={classes.paper}>
                                <div className={classes.hiddenToolBar}>
                                    <Button
                                        className={classes.openHiddenToolBar}
                                        value='center'
                                        aria-label='centered'
                                        onClick={handleToolBarOpen}
                                    >
                                        <MoreIcon />
                                    </Button>
                                    <Fade
                                        in={openHiddenToolBar}
                                        style={{ display: openHiddenToolBar ? 'flex' : 'none' }}
                                    >
                                        <Grid container direction='column' className={classes.hiddenToolBarBtn}>
                                            {views === 'graphView' ? (
                                                <Button
                                                    className={classes.viewButtonSelected}
                                                    value='graphView'
                                                    aria-label='centered'
                                                    disabled
                                                    variant='contained'
                                                    onClick={() => handleSwitchViews('graphView')}
                                                >
                                                    {/* <MapIcon /> */}
                                                    <ScatterPlotIcon />
                                                </Button>
                                            ) : (
                                                <Tooltip title='大观念视图' arrow>
                                                    <Button
                                                        value='graphView'
                                                        aria-label='centered'
                                                        onClick={() => handleSwitchViews('graphView')}
                                                    >
                                                        {/* <MapIcon /> */}
                                                        <ScatterPlotIcon />
                                                    </Button>
                                                </Tooltip>
                                            )}
                                            {views === 'notebookListView' ? (
                                                <Button
                                                    className={classes.viewButtonSelected}
                                                    value='notebookListView'
                                                    aria-label='centered'
                                                    disabled
                                                    variant='contained'
                                                    onClick={() => handleSwitchViews('notebookListView')}
                                                >
                                                    <ListAltIcon style={{ color: '#1f5ea7' }} />
                                                </Button>
                                            ) : (
                                                <Tooltip title='问题列表' arrow>
                                                    <Button
                                                        value='notebookListView'
                                                        aria-label='centered'
                                                        onClick={() => handleSwitchViews('notebookListView')}
                                                    >
                                                        <ListAltIcon style={{ color: '#1f5ea7' }} />
                                                    </Button>
                                                </Tooltip>
                                            )}
                                            {views === 'newNotebookView' ? (
                                                <Button
                                                    className={classes.viewButtonSelected}
                                                    value='newNotebookView'
                                                    aria-label='centered'
                                                    disabled
                                                    variant='contained'
                                                    onClick={() => handleSwitchViews('newNotebookView')}
                                                >
                                                    <BookIcon />
                                                </Button>
                                            ) : (
                                                <Tooltip title='知识笔记视图' arrow>
                                                    <Button
                                                        value='newNotebookView'
                                                        aria-label='centered'
                                                        onClick={() => handleSwitchViews('newNotebookView')}
                                                    >
                                                        <BookIcon />
                                                    </Button>
                                                </Tooltip>
                                            )}
                                            {views === 'graphView' && (
                                                <>
                                                    <div
                                                        style={{
                                                            borderTop: '1px solid',
                                                            borderRadius: 0,
                                                            marginBottom: -35
                                                        }}
                                                    ></div>
                                                    <Tooltip title='修改基本信息' arrow>
                                                        <Button
                                                            value='修改基本信息'
                                                            aria-label='centered'
                                                            onClick={handleOpenGraphBasicInfoEditPanel}
                                                        >
                                                            <AssignmentIcon />
                                                        </Button>
                                                    </Tooltip>
                                                    <Tooltip title='添加知识节点' arrow>
                                                        <Button
                                                            value='添加知识节点'
                                                            aria-label='centered'
                                                            onClick={handleOpenAddNewNodePanel}
                                                        >
                                                            <AddCircleOutlineIcon />
                                                        </Button>
                                                    </Tooltip>
                                                    <Tooltip title='添加节点关联' arrow>
                                                        <Button
                                                            value='添加节点关联'
                                                            aria-label='right aligned'
                                                            onClick={handleOpenAddNewLinkPanel}
                                                        >
                                                            <AccountTreeIcon />
                                                        </Button>
                                                    </Tooltip>
                                                    <Tooltip title='修改主题风格' arrow>
                                                        <Button
                                                            value='修改主题风格'
                                                            aria-label='centered'
                                                            onClick={handleOpenModifyGraphThemePanel}
                                                        >
                                                            <FormatColorFillIcon />
                                                        </Button>
                                                    </Tooltip>
                                                </>
                                            )}
                                        </Grid>
                                    </Fade>
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                )}
            </Paper>
            {/* main content */}
            <div style={{ backgroundColor: currentTheme === 'light' ? '#f7f7f7' : '#1f2733' }}>
                {views === 'graphView' && (
                    <div>
                        <GraphView
                            parentRef={chartRef}
                            graph={graph}
                            echartsClick={echartsClick}
                            openInfoPanel={openInfoPanel}
                            nodeName={nodeName}
                            nodeId={nodeId}
                            materialColor={materialColor}
                            graphColorTheme={graphColorTheme}
                            lineColor={lineColor}
                            handleCloseInfoPanel={handleCloseInfoPanel}
                            handleAddNode={handleAddNode}
                            handleAddNewLink={handleAddNewLink}
                            handleModifyGraph={handleModifyGraph}
                            handleSaveGraphTheme={handleSaveGraphTheme}
                            handleSwitchViews={handleSwitchViews}
                        />
                    </div>
                )}
                {/* 概念地图 - 新建资源 */}
                {views === 'notebookListView' ? (
                    showCreateQuestion ? (
                        // 创建问题
                        <NotebookDefault
                            id={questionId}
                            type={questionType}
                            nodeIdMap={nodeIdMap}
                            goBack={() => {
                                setShowCreateQuestion(false);
                                setNodeId('');
                            }}
                        />
                    ) : (
                        // 知识地图信息列表
                        // <div style={{ padding: '10px 30px' }}>
                        //     <NotebookListView handleSwitchViews={handleSwitchViews} />
                        // </div>
                        <Box sx={{ padding: '24px' }}>
                            <DataGrid
                                type='kngViewList'
                                dataSource={dataSource}
                                deps={[{ renderTable }]}
                                renderActions={({ data }) => (
                                    <>
                                        <Button
                                            size='small'
                                            onClick={() => {
                                                setQuestionType('repair');
                                                setQuestionId(data.id);
                                                setShowCreateQuestion(true);
                                            }}
                                        >
                                            查看
                                        </Button>
                                        <Button
                                            size='small'
                                            variant='outlined'
                                            color='error'
                                            onClick={e => {
                                                // delTableQuestion(data.id);
                                                setPopConfirmData({
                                                    id: data.id,
                                                    open: true,
                                                    anchorEl: e.currentTarget
                                                });
                                            }}
                                        >
                                            删除
                                        </Button>
                                    </>
                                )}
                            />
                        </Box>
                    )
                ) : (
                    <></>
                )}
                {views === 'newNotebookView' && (
                    <div style={{ padding: '10px 30px' }}>
                        <NewNoteBookView handleSwitchViews={handleSwitchViews} />
                    </div>
                )}

                {/* 二次确认弹窗 */}
                <PopConfirm
                    delText={'确定是否删除？'}
                    open={popConfirmData.open}
                    anchorEl={popConfirmData.anchorEl}
                    onConfirm={() => {
                        delTableQuestion(popConfirmData.id);
                    }}
                    onClose={() => {
                        setPopConfirmData({ id: '', open: false, anchorEl: null });
                    }}
                />
            </div>
            {/* 提示 */}
            {/* <SnackbarAlert open={snackbar.open} type={snackbar.type} msg={snackbar.msg} duration={2000} /> */}

            {/* 下载上传 Excel */}
            <ExcelModal
                key={currentKnmMapInfo.id}
                open={openModal.show}
                type={openModal.type}
                title={(openInfoPanel.excelDown ? '导出' : '导入') + '概念节点或概念关联关系'}
                onClose={() => {
                    setOpenModal({ show: false, type: '' });
                }}
                uplodeFile={handleImport}
                exportRelationship={cb => {
                    // console.log('导出关系', currentKnmMapInfo);
                    exportRelationship({
                        graphId: currentKnmMapInfo.id,
                        graphName: currentKnmMapInfo.name
                    })
                        .then(res => {
                            if (res) {
                                downloadFile(
                                    res,
                                    currentKnmMapInfo.name + ' -地图关联关系- ' + new Date().toLocaleString() + '.xlsx'
                                );
                            }
                        })
                        .finally(() => cb?.());
                }}
                exportConception={cb => {
                    // console.log('导出概念');
                    exportConception(currentKnmMapInfo.id)
                        .then(res => {
                            if (res) {
                                downloadFile(
                                    res,
                                    currentKnmMapInfo.name + ' -地图概念- ' + new Date().toLocaleString() + '.xlsx'
                                );
                            }
                        })
                        .finally(() => cb?.());
                }}
            />
        </React.Fragment>
    );
};
export default KNMDetailPage;
