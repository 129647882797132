import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import type { ForwardedRef, Ref } from 'react';
// import MD
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
// import TextField from '@material-ui/core/TextField';
// import Autocomplete from '@material-ui/lab/Autocomplete';
// import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
// import QueuePlayNextIcon from '@material-ui/icons/QueuePlayNext';
// import { CircularProgress } from '@material-ui/core';
// import react-color
import { CirclePicker } from 'react-color';
// import mock data
// import { mockTags } from '../../../../settings/mocks/DefaultTags';
// redux
import { useSelector } from '../../../../redux/hooks';
import { useDispatch } from 'react-redux';
// import { createNode } from '../../../../redux/knm/nodeSlice';
import { getGraphDetail } from '../../../../redux/knm/graphSlice';

import { getThemeSetStyle } from '@/utils/hooks';
import { useForm } from 'react-hook-form';
import { Box, Icon } from '@mui/material';
import { InputWithLabel, CustomSelect } from '@components/common/form';
import { InputField, FormHookControl, SelectField } from '@/components/common/form/FormFields';
import MoreProperties from '@components/sections/detail-page/MoreProperties';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
//
// import { getAndSetIdeaDetail } from '@/api/creatMap';
import { updateNodeInfo, deleteNode } from '@/redux/knm/nodeSlice';
import AddNewNode from '@components/sections/detail-page/AddNewNode';
import { semestersOption } from '@components/common/dataAtRest';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        infoPanelForms: {
            // marginBottom: theme.spacing(2),
            '&>*': {
                marginBottom: theme.spacing(1),
                width: '100%'
            }
        },
        colorPicker: {
            marginBottom: '0px !important'
        }
    })
);

interface AddNewNodeState {
    nodeName: string;
    nodeTags: any[];
    nodeIntro: string;
    nodeSize: string;
    nodeColor: string;
}
interface AddNewNodePanelState {
    materialColor: any[];
    handleAddNode: (any) => void;
}

interface Semester {
    key: string;
    value: string;
}
type CreateNodeList = { content: string; nodeList: Semester[]; keywords?: string }[];
interface NewNodeFormRef {
    getWatchSelect: () => any;
    resetForm: () => void;
}

// 初始学期
export const initCreatNodeList = semestersOption.map(o => ({ key: o.id, value: o.name }));
// 标签 选项
export const initTagsList = [
    { key: '标签一', value: '标签一' },
    { key: '标签二', value: '标签二' }
];

// ! 滚动条样式
const scrollStyle = {
    '::-webkit-scrollbar': {
        maxHeight: 0,
        maxWidth: 0
    }
};

export const AddNewNodePanel: React.FC<AddNewNodePanelState> = ({ materialColor, handleAddNode }) => {
    const createNodeRef = useRef<NewNodeFormRef>(null);
    const defaultStyle = getThemeSetStyle();
    const classes = useStyles();
    // redux
    const dispatch = useDispatch();
    const jwt = useSelector(state => state.user.token);
    const currentOpenMapInfo = useSelector(state => state.knmMap.currentOpenMapInfo);
    const nodeLoading = useSelector(state => state.node.loading);
    const [values, setValues] = useState<AddNewNodeState>({
        nodeName: '',
        nodeTags: [],
        nodeIntro: '',
        nodeSize: '',
        nodeColor: materialColor[0]
    });

    // 全部新建列表
    const [allNodeList, setAllNodeList] = useState<Semester[]>(initCreatNodeList);
    // 概念名称
    const [ideaName, setIdeaName] = useState('');
    // 对应年级学期 关键词
    const [semesterKeywords, setSemesterKeywords] = useState([]);
    // 节点名称重复
    const [nodeNameRepeat, setNodeNameRepeat] = useState({ name: '', repeat: false });

    // 新建概念全部列表
    const [createNodeList, setCreateNodeList] = useState<CreateNodeList>([]);
    // 添加 年级-学期
    const [addNode, setAddNode] = useState<Semester[]>([]);

    // text change
    const handleChangeText = (prop: keyof AddNewNodeState) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({
            ...values,
            [prop]: event.target.value
        });
    };
    // node size select
    const handleChangeNodeSize = (event: React.ChangeEvent<{ value: unknown }>) => {
        setValues({
            ...values,
            nodeSize: event.target.value as string
        });
    };
    // node color select
    const handleChangeNodeColor = (newNodeColor, event) => {
        setValues({
            ...values,
            nodeColor: newNodeColor.hex
        });
    };

    // 新增一个概念 学期
    const handleAddNewNode = () => {
        setSemesterKeywords([]);
        if (addNode.length) {
            setCreateNodeList([...createNodeList, { content: '', nodeList: addNode, keywords: '' }]);
            setAddNode([]);
        }
    };

    // 添加、新建 一个概念
    const handleAddNewNodeClick = async () => {
        const subjects = currentOpenMapInfo['subjects'];
        const formData = createNodeRef.current?.getWatchSelect();
        const semesters = createNodeList.map(nl => nl.nodeList.map(n => ({ id: n.key, name: n.value })));
        const semesterMap = createNodeList.reduce((all, n) => {
            if (all[n.content]) {
                all[n.content].push(...n.nodeList.map(n => ({ id: n.key, name: n.value })));
            } else {
                all[n.content] = n.nodeList.map(n => ({ id: n.key, name: n.value }));
            }
            return all;
        }, {});

        // console.log('formData', formData);
        // console.log('createNodeList', createNodeList);
        const addNodeInfo = {
            name: ideaName,
            // content: null,
            // keywords: null,
            nodeSize: values.nodeSize,
            nodeColor: values.nodeColor,
            form: 'definition',
            subjects,
            graphId: currentOpenMapInfo?.id,
            semesters: semesters?.flat(2) || [],
            contentSemesterMap: semesterMap, // 学期对应的内容关键词

            ...formData
            // proposerTypes: null, // 提出者
            // isCoreIdea: null, // 核心概念
            // isCrosscuttingIdea: null,
            // pointingDimension: null, // 指向维度
            // appearanceWay: null, // 出现方式

            // acquisitionDimensions: null, // 习得维度

            // complexity: null, // 复杂度
            // abstractionDegree: null, // 抽象程度
            // ideaFocusDegree: null, // 概念聚焦程度
            // inertActivatingProbability: null, // 惰性可激活程度
            // difficulty: null // 难度
        };
        const callBack = () => {
            dispatch(
                getGraphDetail({
                    currentOpenMapId: currentOpenMapInfo?.id,
                    jwt,
                    xhrParams: {
                        graphId: currentOpenMapInfo?.id
                    }
                })
            );
            setIdeaName('');
            setAddNode([]);
            setSemesterKeywords([]);
            setValues({ nodeName: '', nodeTags: [], nodeIntro: '', nodeSize: '', nodeColor: '' });
            setCreateNodeList([]);
            createNodeRef.current?.resetForm();
        };

        dispatch(
            updateNodeInfo({
                jwt: jwt,
                nodeInfo: values,
                graphId: '',
                nodeId: '',
                type: 'add',
                openNodeInfo: addNodeInfo,
                // !刷新数据
                cb: callBack
            })
        );
    };

    return (
        <Box sx={{ height: 'calc(100% - 70px)' }}>
            <Box sx={{ height: '100%', overflowX: 'scroll', ...scrollStyle }}>
                <Box sx={{ color: 'red', display: nodeNameRepeat.repeat ? '' : 'none' }}>名称重复</Box>
                <InputWithLabel
                    placeholder={'请输入概念名称'}
                    label={'概念名称'}
                    value={ideaName}
                    sx={{ display: 'flex', flexDirection: 'column' }}
                    inputStyle={{
                        height: '36px',
                        input: { minHeight: '36px', backgroundColor: defaultStyle.backgroundColor }
                    }}
                    onChange={e => {
                        setIdeaName(e.target.value);
                    }}
                />
                <Box sx={{ display: 'flex', mb: 1.5, mt: 2 }}>
                    <CustomSelect
                        multiple
                        placeholder='请选择学期'
                        value={addNode}
                        selectItemArray={allNodeList.filter(
                            n =>
                                ![...createNodeList]
                                    .map(s => s.nodeList)
                                    .flat(2)
                                    .includes(n)
                        )}
                        onChange={e => {
                            setAddNode(e.target.value as Semester[]);
                        }}
                        formControlStyle={{ backgroundColor: defaultStyle.backgroundColor }}
                    />
                    <Button style={{ cursor: 'point' }} onClick={handleAddNewNode}>
                        <AddCircleOutlineIcon />
                    </Button>
                </Box>

                {/* 关联概念选择 关联概念内容,关键词 */}
                {createNodeList.map((n, i) => (
                    <Box key={i} sx={{ marginBottom: '4px' }}>
                        <CustomSelect
                            key={i}
                            multiple
                            value={n.nodeList}
                            // !过滤已经选择的
                            selectItemArray={[
                                ...n.nodeList,
                                ...allNodeList.filter(
                                    allN =>
                                        ![...createNodeList]
                                            .map(s => s.nodeList)
                                            .flat(2)
                                            .includes(allN)
                                )
                            ]}
                            onChange={e => {
                                setCreateNodeList(pre => {
                                    const nPre = [...pre];
                                    nPre[i].nodeList = e.target.value as Semester[];
                                    return nPre;
                                });
                            }}
                            formControlStyle={{ backgroundColor: defaultStyle.backgroundColor }}
                            selectStyle={{ width: '360px' }}
                        />
                        <InputWithLabel
                            placeholder={'请输入内容'}
                            value={n.content}
                            sx={{ display: 'flex', flexDirection: 'column' }}
                            inputStyle={{ input: { backgroundColor: defaultStyle.backgroundColor } }}
                            onChange={e => {
                                setCreateNodeList(pre => {
                                    const content: string = e.target.value;
                                    const newPre = [...pre];

                                    newPre[i].content = content;
                                    return newPre;
                                });
                            }}
                        />
                        {/* <InputWithLabel
                            placeholder={'请选择关键词'}
                            value={n.keywords}
                            sx={{ display: 'flex', flexDirection: 'column' }}
                            inputStyle={{ input: { backgroundColor: defaultStyle.backgroundColor } }}
                            onChange={e => {
                                setCreateNodeList(pre => {
                                    const keyword: string = e.target.value;
                                    const newPre = [...pre];
                                    newPre[i].keywords = keyword;
                                    return newPre;
                                });
                            }}
                        /> */}
                        {/* <CustomSelect
                            multiple
                            placeholder={'请选择关键词'}
                            // value={semesterKeywords}
                            value={n.keywords}
                            selectItemArray={initTagsList}
                            onChange={e => {
                                setCreateNodeList(pre => {
                                    const keywords = e.target.value as [];
                                    const newPre = [...pre];
                                    newPre[i].keywords = keywords;
                                    return newPre;
                                });
                            }}
                            formControlStyle={{ backgroundColor: defaultStyle.backgroundColor }}
                            selectStyle={{ width: '360px' }}
                        /> */}
                    </Box>
                ))}

                {/* 详细数据操作 */}
                {ideaName ? <AddNewNode ref={createNodeRef} /> : <></>}
            </Box>
            <Button
                variant='contained'
                size='small'
                color='default'
                onClick={handleAddNewNodeClick}
                style={{ position: 'absolute', right: '20px' }}
                disabled={!ideaName}
            >
                新建
            </Button>
        </Box>
    );
};
