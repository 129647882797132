import React from 'react';
import { useForm } from 'react-hook-form';
import { FormHookControl, InputField, SelectField } from '@/components/common/form/FormFields';
import { Modal, Box, Button } from '@mui/material';
import { getThemeSetStyle } from '@/utils/hooks';
import { Close } from '@material-ui/icons';
import { FormSelectProps } from '@components/common/form/types';

type FormItem = Omit<FormSelectProps, 'registerRef'> & {
    // ! 定义的 state 变量属性名称
    stateName: string;
};

interface CreateDemoProps<T> {
    open: boolean;
    title?: string;
    defaultData?: T;
    formList?: FormItem[];
    // 关闭函数
    onClose: () => void;
    // 数据重置
    onReset?: () => void;
    // 数据确认
    onSubmit?: (data: T) => Promise<{ type: number }>;
}

/** 更多属性 */
export default function MoreProperties<T = unknown>(props: CreateDemoProps<T>, parentRef) {
    const defaultStyle = getThemeSetStyle();

    const { open, title, onClose, onReset, onSubmit } = props;
    const { defaultData, formList } = props;

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 480,
        bgcolor: 'rgb(74, 83, 95)',
        boxShadow: 24,
        p: 2,
        backgroundColor: defaultStyle.backgroundColor
    };
    const formStyle = {
        wrap: { height: '50px', fontSize: '12px' },
        labelStyle: {
            fontSize: '14px',
            width: '120px',
            color: defaultStyle.color,
            marginRight: 4,
            display: 'flex',
            justifyContent: 'flex-end'
        },
        inputStyle: {
            color: defaultStyle.color,
            width: '240px',
            input: {
                backgroundColor: '#fff',
                '&::placeholder': {
                    color: '#000'
                }
            }
        },
        formControlStyle: { width: '240px' },
        selectStyle: { width: '240px', backgroundColor: '#fff' }
    };

    const hightOptios = [
        { value: 'hight', name: '高' },
        { value: 2, name: '中' },
        { value: 3, name: '低' }
    ];
    // 表单列表
    const propertiesList = formList || [
        {
            label: '提出问题',
            stateName: 'a',
            multiple: true,
            required: false,
            getAllOption: true,
            options: [
                { value: '111', name: '测试1' },
                { value: '222', name: '测试2' },
                { value: '333', name: '测试3' }
            ]
        },
        { label: '参考来源', stateName: 'b', required: false, options: [] },
        { label: '复杂度', stateName: 'c', required: false, options: hightOptios },
        { label: '情境真实性', stateName: 'e', required: false, options: hightOptios },
        { label: '难度', stateName: 'f', required: false, options: hightOptios },
        {
            label: '问题情境类型',
            stateName: 'questionType',
            required: false,
            options: [
                { value: 1, name: '设计类' },
                { value: 2, name: '探究类' },
                { value: 3, name: '决策类' },
                { value: 4, name: '鉴赏类' }
            ]
        },
        { label: '典型程度', stateName: 'h', required: false, options: hightOptios },
        { label: '问题劣构程度', stateName: 'i', required: false, options: hightOptios },
        { label: '是否是跨学科问题', stateName: 'j', required: false, options: hightOptios }
    ];

    // 表单数据
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        control
    } = useForm<any>({
        defaultValues: defaultData || {
            title: '',
            conception: '',
            keyWord: [],
            creatUser: '',
            questionType: { value: 1, name: '设计类' }, // 问题类型
            contextualType: {}, // 问题情境类型
            Interdisciplinary: '', // 跨学科问题
            content: '' // 内容
        }
    });

    // 弹窗关闭
    const handleClose = () => {
        // 数据重置
        reset();
        onClose?.();
    };

    // 表单数据重置
    const handleReset = () => {
        reset();
        onReset?.();
    };

    // 表单数据确认
    const handleOnSubmit = (data: T) => {
        onSubmit?.(data)
            .then(res => {
                if (res.type === 0) reset();
            })
            .catch(rej => {});
    };

    return (
        <Modal open={open} onClose={handleClose} hideBackdrop>
            <Box sx={{ ...style, ...defaultStyle }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box>{title || '新建问题 / 更多属性'}</Box>
                    <Button onClick={handleClose}>
                        <Close />
                    </Button>
                </Box>
                <Box sx={{ paddingTop: '18px' }}>
                    {propertiesList.map(s => (
                        <FormHookControl key={s.stateName} name={s.stateName} control={control}>
                            <SelectField
                                label={s.label + ':'}
                                control={control}
                                multiple={s?.multiple}
                                required={s.required}
                                getAllOption={s?.getAllOption}
                                optionsType={s?.optionsType || ['value', 'name']}
                                registerRef={register(s.stateName, { required: s.required })}
                                placeholder={'请选择' + s.label}
                                style={formStyle}
                                options={s.options || []}
                            />
                        </FormHookControl>
                    ))}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={handleReset}>重置</Button>
                    <Button onClick={handleSubmit(handleOnSubmit)}>确认</Button>
                </Box>
            </Box>
        </Modal>
    );
}
