import React, { useRef, forwardRef, useImperativeHandle, useState } from 'react';

import tinyMce from 'tinymce/tinymce';
import 'tinymce/themes/silver/theme'; // 编辑器主题
import 'tinymce/themes/silver';
import 'tinymce/icons/default';
import 'tinymce/plugins/advlist'; //高级列表
import 'tinymce/plugins/autolink'; //自动链接
import 'tinymce/plugins/link'; //超链接
import 'tinymce/plugins/lists'; //列表插件
import 'tinymce/plugins/charmap'; //特殊字符
import 'tinymce/plugins/media'; //插入编辑媒体
import 'tinymce/plugins/wordcount'; // 字数统计
import 'tinymce/plugins/image';
import 'tinymce/plugins/imagetools';
import '@assets/tinymce/zh_CN';
import { Editor } from '@tinymce/tinymce-react';
// MathJax
import MathJax from 'react-mathjax';
import katex from 'katex'; // katex 数学公式

import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.inline.min.css';
import 'katex/dist/katex.min.css';

interface TinymceEditor {
    height?: number;
    initialValue?: string;
    toolbar?: string | boolean;
    handleEditorChange?: (contxt?: any, editor?: any) => void;
}

// 编辑器组件
export function TinymceEditor(props: TinymceEditor, ref?: any) {
    const editorRef = useRef<any>(null);
    // 编辑器内容
    const [editorContent, setEditorContent] = useState('');

    const { height = 300, initialValue, toolbar } = props;
    const { handleEditorChange } = props;

    const editorChange = (content, editor) => {
        setEditorContent(content);
        handleEditorChange?.(content, editor);
    };

    const clearEditorContetn = () => {
        setEditorContent('');
    };

    const editorBlur = e => {
        // console.log('editorBlur', e);
        console.log('获取内容：', tinyMce.activeEditor.getContent());
    };

    const renderMath = content => {
        MathJax.startup.promise.then(() => {
            const rendered = MathJax.tex2svg(content);
            // 将SVG插入到你想要显示数学公式的位置
        });
    };

    // !获取编辑器内容
    const getContentFromEditor = () => {
        if (editorRef.current) {
            const editorInstance = editorRef.current.editor;
            const content = editorInstance.getContent();
            return content;
        }
        return '';
    };

    useImperativeHandle(ref, () => ({
        getContent: getContentFromEditor,
        clearEditor: clearEditorContetn
    }));

    return (
        <MathJax.Provider>
            <Editor
                ref={editorRef}
                // apiKey='[your_api_key]' // 替换为你自己的API密钥
                // initialValue='<p>This is the initial content of the editor</p>'
                initialValue={initialValue}
                value={editorContent}
                init={{
                    height: height,
                    menubar: false,
                    language: 'zh_CN',
                    language_url: 'zh_CN',
                    content_css: false,
                    statusbar: false,
                    // ! 先移除 plugins 只显示原始
                    // plugins: [
                    //     'advlist lists link image charmap print preview anchor',
                    //     'searchreplace visualblocks code fullscreen',
                    //     'insertdatetime media table paste code help wordcount',
                    //     'nonbreaking pagebreak',
                    //     'template emoticons',
                    //     'directionality',
                    //     'spellchecker'
                    // ],
                    toolbar:
                        toolbar ??
                        'undo redo | bold italic underline strikethrough | ' +
                            'forecolor backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'styleselect | link image media | ' +
                            'spellchecker | mathjaxbutton | mathjax',
                    // content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    setup: editor => {
                        // editor.ui.registry.addButton('mathjax', {
                        //     text: '数学公式',
                        //     onAction: function (_) {}
                        // });
                        // ! 移除自定义按钮 --- 数学函数
                        // editor.ui.registry.addButton('mathjaxbutton', {
                        //     // 数学公式
                        //     text: 'Insert Math Formula',
                        //     onAction: () => {
                        //         editor.selection.setContent(
                        //             `<p>Insert your math formula here: <math xmlns="http://www.w3.org/1998/Math/MathML"><mrow><mi>x</mi><mo>=</mo><mfrac><mrow><mo>&#x2212;</mo><mi>b</mi><mo>&#x00B1;</mo><msqrt><mrow><mi>b</mi><mo>&#x00B2;</mo><mo>&#x2212;</mo><mn>4</mn><mo>&#x22A5;</mo><mi>a</mi><mi>c</mi></mrow></msqrt></mrow><mrow>   <mn>2</mn><mo>&#x22A5;</mo><mi>a</mi></mrow></mfrac></mrow></math></p>`
                        //         );
                        //     }
                        // });
                        // editor.on('init', () => {});
                    }
                }}
                // 文本框内容变化
                onEditorChange={editorChange}
                // 焦点离开
                onBlur={editorBlur}
            />
        </MathJax.Provider>
    );
}
export default forwardRef<any, TinymceEditor>(TinymceEditor);
