// 覆盖原有伪类样式
const afterStyle = {
    width: 0,
    border: 'none'
};

// 锁定列样式：列尾
const lockedEndColumnStyle = {
    boxShadow: '-5px 1px 10px -3px #0000000D',
    borderLeft: 'none',
    '&:after': afterStyle
};

// 锁定列样式：列首
const lockedStartColumnStyle = {
    boxShadow: '5px 1px 10px -3px #0000000D',
    borderRight: 'none',
    '&:after': afterStyle
};

export const gridRootStyle = {
    height: '100%',
    fontSize: 14,
    color: theme => theme.palette.text.main,
    // 如果你需要在 reactdatagrid 表格中自定义 tooltip 样式， .inovua-react-toolkit-tooltip-content 和 .inovua-react-toolkit-tooltip-arrow 两个类名，并设置相应的样式。
    '.inovua-react-toolkit-tooltip-content,.inovua-react-toolkit-tooltip-arrow:after': {
        whiteSpace: 'pre-wrap',
        maxWidth: '500px',
        wordBreak: 'break-word'
    },
    // 覆盖reactdatagrid样式
    '& .InovuaReactDataGrid--theme-default-light ': {
        // 表头
        '& .InovuaReactDataGrid__header': {
            color: '#86909C',
            '& .InovuaReactDataGrid__column-header__content': {
                fontWeight: 'normal'
            }
        },
        // 锁定列相关样式
        '& .InovuaReactDataGrid__locked-end-wrapper--direction-ltr': {
            '& .InovuaReactDataGrid__column-header--show-border-left.InovuaReactDataGrid__column-header--locked-end.InovuaReactDataGrid__column-header--first-in-section, .InovuaReactDataGrid__column-header__resize-wrapper--show-border-left.InovuaReactDataGrid__column-header__resize-wrapper--locked-end.InovuaReactDataGrid__column-header__resize-wrapper--first-in-section':
                lockedEndColumnStyle
        },

        '& .InovuaReactDataGrid__column-header__resize-wrapper--direction-ltr.InovuaReactDataGrid__column-header__resize-wrapper--locked-start.InovuaReactDataGrid__column-header__resize-wrapper--last-in-section':
            lockedStartColumnStyle,
        '& .InovuaReactDataGrid__column-header--direction-ltr.InovuaReactDataGrid__column-header--unresizable.InovuaReactDataGrid__column-header--locked-start.InovuaReactDataGrid__column-header--last-in-section':
            lockedStartColumnStyle,

        '& .InovuaReactDataGrid__cell--direction-ltr': {
            '&.InovuaReactDataGrid__cell--locked-end:first-of-type': lockedEndColumnStyle,
            '&.InovuaReactDataGrid__cell--locked-start.InovuaReactDataGrid__cell--last-in-section:not(.InovuaReactDataGrid__cell--last)':
                lockedStartColumnStyle
        },
        // 操作栏可以溢出滚动
        '& .data-grid-action-columns': {
            // background: 'green',
            '& .InovuaReactDataGrid__cell__content': {
                // background: 'red'
                overflowX: 'auto',
                textOverflow: 'clip'
            }
        }
    },
    // 行样式
    '& .InovuaReactDataGrid__row--active': {
        backgroundColor: '#F9FAFD'
    }
};
// 超出隐藏
export const ellipsis = { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' };
