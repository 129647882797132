const BASE_URL = process.env.NODE_ENV === 'development' ? 'http://47.107.34.33:3000' : 'http://localhost:3001';
export const API = {
    user: {
        login: BASE_URL + '/user/login',
        register: BASE_URL + '/user/register',
        jwtVerify: BASE_URL + '/user/jwt',
        passwordVerify: BASE_URL + '/user/verify',
        update: BASE_URL + '/user/',
        diary: BASE_URL + '/user/diary',
        avatar: BASE_URL + '/user/avatar',
        statics: BASE_URL + '/user/statics'
    },
    map: BASE_URL + '/map',
    graph: BASE_URL + '/map/:mapId/graph',
    node: BASE_URL + '/graph/:graphId/node',
    link: BASE_URL + '/graph/:graphId/link',
    notebook: {
        all: BASE_URL + '/graph/:graphId/allNotebook',
        normal: BASE_URL + '/graph/:graphId/:target/:targetId/notebook',
        node: BASE_URL + '/graph/:graphId/node/:nodeId/notebook',
        link: BASE_URL + '/graph/:graphId/link/:linkId/notebook'
    }
};
