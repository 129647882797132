import React from 'react';
import { InfoPanel, KnowledgeGraph, RowPanel } from '../../../../components/common';
import {
    AddNewLinkPanel,
    AddNewNodePanel,
    GraphBasicInfoEditPanel,
    LinkInfoEditPanel,
    ModifyGraphThemePanel,
    NodeInfoEditPanel
} from '../infoPanelContent';
import NodeQuestion from '@/components/sections/detail-page/NodeQuestion';

import Graph, { option } from '@/components/common/knowledgeGraph/Graph';

interface KnowledgeGraphState {
    node: any[];
    link: any[];
    relations: any[];
    themeColor: string; // 主题颜色
    lineStyleType: 'solid' | 'dashed' | 'dotted'; //关联线样式
    lineStyleColor: string; // 关联线颜色
    lineStyleWidth: number; // 关联线宽度
    lineStyleOpacity: number; // 关联线透明度
    lineStyleCurveness: number; // 关联线曲度
    labelFontSize: number; //节点标签字体大小
    labelPosition: 'top' | 'left' | 'right' | 'bottom' | 'inside';
    edgeLabelFontSize: number;
    layout: 'force' | 'circular';
    forcePower: number;
}

interface OpenInfoPanelState {
    graphBasicInfoEditPanel: boolean;
    addNewNodePanel: boolean;
    addNewLinkPanel: boolean;
    modifyGraphThemePanel: boolean;
    nodeInfoEditPanel: boolean;
    linkInfoEditPanel: boolean;
}

interface GraphViewState {
    parentRef: any;
    graph: KnowledgeGraphState;
    echartsClick: {};
    openInfoPanel: OpenInfoPanelState;
    nodeName: string;
    nodeId: string;
    materialColor: string[];
    graphColorTheme: string[];
    lineColor: string[];
    handleCloseInfoPanel: () => {};
    handleAddNode: (newNode: any) => void;
    handleAddNewLink: (newLink: any, newRelation: any) => void;
    handleModifyGraph: (target: string, newValue: any) => void;
    handleSaveGraphTheme: () => {};
    handleSwitchViews: (newView: string, isOpenSpecificNotebook?: boolean) => void;
}
export const GraphView: React.FC<GraphViewState> = props => {
    const { parentRef } = props;
    const { graph, echartsClick, openInfoPanel, nodeName, nodeId, materialColor, graphColorTheme, lineColor } = props;
    const {
        handleCloseInfoPanel,
        handleAddNode,
        handleAddNewLink,
        handleModifyGraph,
        handleSaveGraphTheme,
        handleSwitchViews
    } = props;

    return (
        <React.Fragment>
            <KnowledgeGraph
                ref={parentRef}
                nodeData={graph.node}
                linkData={graph.link}
                relations={graph.relations}
                themeColor={graph.themeColor}
                lineStyleType={graph.lineStyleType}
                lineStyleColor={graph.lineStyleColor}
                lineStyleWidth={graph.lineStyleWidth}
                lineStyleOpacity={graph.lineStyleOpacity}
                lineStyleCurveness={graph.lineStyleCurveness}
                labelFontSize={graph.labelFontSize}
                labelPosition={graph.labelPosition}
                edgeLabelFontSize={graph.edgeLabelFontSize}
                layout={graph.layout}
                forcePower={graph.forcePower}
                echartsClick={echartsClick}
            />
            {/* 节点信息编辑面板 */}
            {openInfoPanel.nodeInfoEditPanel && (
                <>
                    <InfoPanel
                        title={'观念节点 | 信息编辑'}
                        handleClosePanel={handleCloseInfoPanel}
                        contain={
                            <NodeInfoEditPanel
                                nodeId={nodeId}
                                nodeName={nodeName}
                                materialColor={materialColor}
                                handleSwitchViews={handleSwitchViews}
                                handleCloseInfoPanel={handleCloseInfoPanel}
                            />
                        }
                    />
                    <RowPanel
                        title={''}
                        handleClosePanel={handleCloseInfoPanel}
                        contain={<NodeQuestion nodeId={nodeId} />}
                    />
                </>
            )}
            {/* node info edit panel */}
            {openInfoPanel.linkInfoEditPanel && (
                <InfoPanel
                    title={'大观念关联 | 编辑概念关联'}
                    handleClosePanel={handleCloseInfoPanel}
                    contain={
                        <LinkInfoEditPanel
                            linkName={nodeName}
                            linkId={nodeId}
                            materialColor={materialColor}
                            handleSwitchViews={handleSwitchViews}
                            handleCloseInfoPanel={handleCloseInfoPanel}
                        />
                    }
                />
            )}
            {/* graph info edit panel */}
            {openInfoPanel.graphBasicInfoEditPanel && (
                <InfoPanel
                    title={'大观念地图 | 基础信息'}
                    handleClosePanel={handleCloseInfoPanel}
                    contain={<GraphBasicInfoEditPanel handleCloseInfoPanel={handleCloseInfoPanel} />}
                />
            )}
            {/* add new node panel */}
            {openInfoPanel.addNewNodePanel && (
                <InfoPanel
                    // title={'大观念 | 新增知识节点'}
                    title='新建概念'
                    handleClosePanel={handleCloseInfoPanel}
                    contain={<AddNewNodePanel materialColor={materialColor} handleAddNode={handleAddNode} />}
                />
            )}
            {/* add new link panel */}
            {openInfoPanel.addNewLinkPanel && (
                <InfoPanel
                    title={'大观念地图 | 新增概念关联'}
                    handleClosePanel={handleCloseInfoPanel}
                    contain={<AddNewLinkPanel handleAddNewLink={handleAddNewLink} />}
                />
            )}
            {/* modify graph theme panel */}
            {openInfoPanel.modifyGraphThemePanel && (
                <InfoPanel
                    title={'大观念地图 | 修改主题样式'}
                    handleClosePanel={handleCloseInfoPanel}
                    contain={
                        <ModifyGraphThemePanel
                            currentGraphThemeOption={graph}
                            graphColorTheme={graphColorTheme}
                            lineColor={lineColor}
                            handleModifyGraph={handleModifyGraph}
                            handleSaveGraphTheme={handleSaveGraphTheme}
                        />
                    }
                />
            )}
            {/* <Graph options={option} /> */}
        </React.Fragment>
    );
};
