import React, { useEffect, useState } from 'react';
// MD components
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

export interface State {
    open: boolean;
    type: 'success' | 'error' | 'warning' | 'info';
    msg: string;
    handleCloseSnackbar?: (event?: React.SyntheticEvent, reason?: string) => void;
    autoClose?: boolean;
    duration?: number;
}

export const SnackbarAlert: React.FC<State> = ({
    open,
    type,
    msg,
    handleCloseSnackbar,
    autoClose = true,
    duration = 5000
}) => {
    // const [hide, setHide] = useState(open);

    // useEffect(() => {
    //     setHide(open);
    // }, [open, hide]);
    // useEffect(() => {
    //     if (hide) {
    //         setTimeout(() => {
    //             setHide(false);
    //         }, 2000);
    //     }
    // }, [hide]);

    return (
        <Snackbar
            open={open}
            // open={hide}
            autoHideDuration={duration}
            // onClose={autoClose ? handleCloseSnackbar : () => {}}
            onClick={handleCloseSnackbar}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert onClose={handleCloseSnackbar} severity={type}>
                {msg}
            </Alert>
        </Snackbar>
    );
};
