import React, { useEffect, useState, useRef, useImperativeHandle, forwardRef } from 'react';
// import MD
import { CircularProgress } from '@material-ui/core';
// redux
import { useSelector } from '../../../redux/hooks';
// import ECharts
import ReactEchartsCore from 'echarts-for-react/lib/core';
import { GraphChart } from 'echarts/charts';
import { LegendComponent, TooltipComponent } from 'echarts/components';
import * as echarts from 'echarts/core';
import { SVGRenderer, CanvasRenderer } from 'echarts/renderers';
import { splitStringByLength } from '@/pages';

import 'echarts/lib/component/graphic';
echarts.use([GraphChart, CanvasRenderer, LegendComponent, TooltipComponent]);

const initialOptions = {
    backgroundColor: '#1b2c36', // 背景颜色
    legend: {
        x: 'center',
        show: true,
        data: []
    },
    tooltip: {
        show: true
        // trigger: 'axis'
        // showDelay: 500 // 设置延迟显示
    },
    series: [
        {
            type: 'graph', // 系列类型:关系图
            top: '10%', // 图表距离容器顶部的距离
            zoom: 1,
            roam: true, // 是否开启鼠标缩放和平移漫游:'scale','move',true,false
            focusNodeAdjacency: true, // 移动到节点时突出周边节点与关联
            force: {
                // 力引导布局相关的配置项
                forcePower: 500, // 节点之间的斥力因子。
                repulsion: 500, // [ default: 50 ]节点之间的斥力因子(关系对象之间的距离)
                edgeLength: [120, 200], // [ default: 30 ]边的两个节点之间的距离
                friction: 1
                // initLayout: 'circular',
                // repulsion: 300,
                // gravity: 0.1,
                // edgeLength: 0.01,
                // layoutAnimation: true
            },
            // animationDuration: 1000,
            // animationEasingUpdate: 'quinticInOut',
            // layout: 'circular', // 图的布局,none(需提供节点x,y), circular(环形不具), force(力引导图)
            symbol: 'circle', // 图标形状
            edgeSymbol: ['none', 'arrow'], //关联线头尾的样式
            lineStyle: {
                // 关联线的样式设置
                normal: {
                    // 深色系时: '#ffffff'; 浅色系时: '#232323'
                    color: '#232323', // 颜色
                    width: 1.6, // 线宽
                    type: 'dashed', // 类型:solid, dashed, dotted
                    opacity: 1, // 图形透明度: 0~1
                    curveness: 0.2 // 曲度: 0~1
                }
            },
            // labelLayout: {
            //     hideOverlap: true
            // },
            label: {
                // 节点上的标签
                normal: {
                    show: true, // 是否显示标签
                    position: 'inside', // 标签位置: top,left,right,bottom,inside,inside+(可组合)
                    textStyle: {
                        // 文本样式
                        fontSize: 14,
                        borderWidth: 1,
                        // borderColor: '#000',
                        fontWeight: 'bold',
                        // padding: 4,
                        color: '#212B36'
                        // backgroundColor: '#fff'
                    }
                }
            },
            edgeLabel: {
                // 连接线上的标签
                normal: {
                    show: true,
                    textStyle: {
                        fontSize: 14
                    },
                    formatter: function (param) {
                        // 标签内容
                        // return param.data.value;
                        return '';
                    }
                }
            },
            grid: { top: 8, right: 8, bottom: 24, left: 36 },
            data: [],
            links: []
        }
    ]
};
export interface ChartRef {
    graphChartRef: any;
    showToolTip: () => void;
}
interface KnowledgeGraphState {
    nodeData: any[];
    linkData: any[];
    relations: any[];
    themeColor: string; // 主题颜色
    lineStyleType: 'solid' | 'dashed' | 'dotted'; //关联线样式
    lineStyleColor: string; // 关联线颜色
    lineStyleWidth: number; // 关联线宽度
    lineStyleOpacity: number; // 关联线透明度
    lineStyleCurveness: number; // 关联线曲度
    labelFontSize: number; //节点标签字体大小
    labelPosition: 'top' | 'left' | 'right' | 'bottom' | 'inside';
    edgeLabelFontSize: number;
    layout: 'force' | 'circular';
    forcePower: number;
    echartsClick: {};
}

export const KnowledgeGraph = forwardRef<ChartRef, KnowledgeGraphState>((props, parentRef) => {
    const chartRef = useRef(null);

    const {
        nodeData,
        linkData,
        relations,
        themeColor,
        lineStyleType,
        lineStyleColor,
        lineStyleWidth,
        lineStyleOpacity,
        lineStyleCurveness,
        labelFontSize,
        labelPosition,
        edgeLabelFontSize,
        layout,
        forcePower,
        echartsClick
    } = props;

    // redux
    const graphLoading = useSelector(state => state.graph.loading);
    // echarts option
    const [options, setOptions] = useState(initialOptions);

    // echarts link's label
    const showLinkLabel = param => {
        return param.data.value;
    };

    // listener: whether graph had changed
    useEffect(() => {
        let currentOptions = JSON.parse(JSON.stringify(options));
        currentOptions.tooltip.formatter = params => {
            const { data } = params;
            let result = '';
            let allName = data?.allName;

            if (data.allMap && typeof data.allMap === 'object') {
                const { allMap } = data;
                let nodeResult = '';
                for (const key in allMap) {
                    const { result } = splitStringByLength(key, 30);
                    const name = allMap[key].map(n => n.name).join('、');
                    nodeResult += name + ': &nbsp&nbsp&nbsp' + '\n\n' + '&nbsp&nbsp&nbsp' + result + '\n\n';
                }
                allName = nodeResult;
            }
            allName?.split('\n\n').forEach(t => {
                if (t) {
                    result += t + '<br>';
                }
            });
            return result ? `<div>${result}</div>` : '';
        };
        // assignment
        currentOptions.series[0].data = nodeData;
        currentOptions.series[0].links = linkData;
        currentOptions.legend.data = relations;
        currentOptions.backgroundColor = themeColor || '#ffffff';
        currentOptions.series[0].lineStyle.normal.type = lineStyleType || 'dashed';
        currentOptions.series[0].lineStyle.normal.color = lineStyleColor || '#000';
        currentOptions.series[0].lineStyle.normal.width = lineStyleWidth || 1.6;
        currentOptions.series[0].lineStyle.normal.opacity = lineStyleOpacity || 1;
        currentOptions.series[0].lineStyle.normal.curveness = lineStyleCurveness || 0.2;
        // currentOptions.series[0].label.normal.textStyle.fontSize = labelFontSize || 12;
        currentOptions.series[0].label.normal.position = labelPosition;
        // currentOptions.series[0].edgeLabel.normal.textStyle.fontSize = edgeLabelFontSize;
        currentOptions.series[0].edgeLabel.normal.formatter = showLinkLabel;
        currentOptions.series[0].layout = layout || 'force';
        currentOptions.series[0].force.repulsion = forcePower * 10 || 600;

        const isBigNode = nodeData.length > 100; // 超过100个节点都是大数据节点
        if (!isBigNode) {
            currentOptions.series[0].force = {
                forcePower: 500, // 节点之间的斥力因子。
                repulsion: 500, // [ default: 50 ]节点之间的斥力因子(关系对象之间的距离)
                edgeLength: [120, 200] // [ default: 30 ]边的两个节点之间的距离
            };
        } else {
            currentOptions.series[0].force = {
                initLayout: 'circular',
                repulsion: 300,
                gravity: 0.1,
                edgeLength: 0.01,
                layoutAnimation: true
            };
        }
        const processedNodeData = nodeData.map(item => {
            return {
                ...item,
                symbolSize: isBigNode ? 30 : 70
            };
        });
        // if (processedNodeData.length > 200) {
        //     nodeChunks.current = processedNodeData.splice(200);
        // }
        currentOptions.series[0].data = processedNodeData;
        // currentOptions.series[0].links = linkData.map(item => {
        //     return {
        //         ...item,
        //         label: undefined
        //     };
        // });

        setOptions(currentOptions);
    }, [
        nodeData,
        linkData,
        relations,
        themeColor,
        lineStyleType,
        lineStyleColor,
        lineStyleWidth,
        lineStyleOpacity,
        lineStyleCurveness,
        labelFontSize,
        labelPosition,
        edgeLabelFontSize,
        layout,
        forcePower
    ]);

    const chart = React.useMemo(
        () => (
            <ReactEchartsCore
                ref={chartRef}
                echarts={echarts}
                option={options}
                style={{
                    height: 'calc(100vh - 110px)',
                    width: '100%'
                }}
                onEvents={echartsClick}
                // lazyUpdate={true}
            />
        ),
        [options]
    );

    useImperativeHandle(
        parentRef,
        () => ({
            graphChartRef: chartRef.current,
            showToolTip: () => {}
        }),
        [parentRef, chartRef, options]
    );

    useEffect(() => {
        if (chartRef.current) {
            const aEcharts: any = chartRef.current;
            const echartsInstance = aEcharts.getEchartsInstance();
            echartsInstance.setOption({ renderer: 'canvas' });
        }
    }, [options]);

    return (
        <React.Fragment>
            {graphLoading ? (
                <div style={{ display: 'flex', height: 'calc(100vh - 97px)' }}>
                    <CircularProgress color='secondary' style={{ margin: 'auto', width: 60, height: 60 }} />
                </div>
            ) : (
                chart
            )}
        </React.Fragment>
    );
});
