import React from 'react';

export default function UserSpace() {
    return (
        <div
            style={{
                width: '100%',
                height: 'calc(100vh - 100px)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <h1>敬请期待...</h1>
        </div>
    );
}
