import React, { useEffect, useState } from 'react';
// import MD
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@mui/material';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
// import FormControl from '@material-ui/core/FormControl';
// import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import QueuePlayNextIcon from '@material-ui/icons/QueuePlayNext';
import { CircularProgress } from '@material-ui/core';
// import mock data
import { mockTags } from '../../../../settings/mocks/DefaultTags';
import { relations, nodeData } from '../../../../settings/mocks/DefaultGraph';
// redux
import { useSelector } from '../../../../redux/hooks';
import { useDispatch } from 'react-redux';
import { createLink } from '../../../../redux/knm/linkSlice';
import { getGraphDetail } from '../../../../redux/knm/graphSlice';

import { customizeToast } from '@components/common/toast';
import { addNodeLink } from '@/api/creatMap';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        infoPanelForms: {
            '&>*': {
                marginBottom: theme.spacing(2),
                width: '100%'
            }
        }
    })
);

interface AddNewLinkState {
    linkName: string;
    linkTags: any[];
    linkIntro: string;
    linkStart: string;
    linkEnd: string;
}

interface AddNewLinkPanelState {
    handleAddNewLink: (newLink: any, newRelation: any) => void;
}
export const AddNewLinkPanel: React.FC<AddNewLinkPanelState> = ({ handleAddNewLink }) => {
    const classes = useStyles();
    const [values, setValues] = useState<AddNewLinkState>({
        linkName: '',
        linkTags: [],
        linkIntro: '',
        linkStart: '',
        linkEnd: ''
    });
    // 节点 开始结束 信息
    const [linkNodeName, setLinkNodeName] = useState({ linkStartName: '', linkEndName: '' });
    // 全部的节点
    const [nodes, setNodes] = useState<any[]>([]);
    // redux
    const dispatch = useDispatch();
    const jwt = useSelector(state => state.user.token);
    const currentOpenGraphInfo = useSelector(state => state.graph.currentOpenGraphInfo);
    const currentOpenMapInfo = useSelector(state => state.knmMap.currentOpenMapInfo);
    const currentOpenMapId = useSelector(state => state.knmMap.currentOpenMapInfo)['id'];
    const linkLoading = useSelector(state => state.link.loading);

    useEffect(() => {
        let graphNodes: any[] = [];
        currentOpenGraphInfo['nodes'].map(node => {
            graphNodes.push({
                ...node,
                id: node['properties']['id'],
                name: node['properties']['name']
            });
        });
        setNodes(graphNodes);
    }, [currentOpenGraphInfo]);

    const handleChangeText = (prop: keyof AddNewLinkState) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({
            ...values,
            [prop]: event.target.value
        });
    };

    const handleChangeLinkNodes = (prop: keyof AddNewLinkState) => (event: React.ChangeEvent<{ value: unknown }>) => {
        setValues({
            ...values,
            [prop]: event.target.value as string
        });
    };

    // 交换开始结束位置
    const exchangeNode = () => {
        const start = linkNodeName.linkEndName;
        const end = linkNodeName.linkStartName;
        setLinkNodeName({
            linkStartName: start,
            linkEndName: end
        });
        setValues({
            ...values,
            linkStart: values.linkEnd,
            linkEnd: values.linkStart
        });
    };

    // !创建节点关联
    const handleAddNewLinkClick = async () => {
        const startNode = nodes.find(n => n.id === values.linkStart);
        const endNode = nodes.find(n => n.id === values.linkEnd);
        if (!startNode || !endNode || !values.linkName) return customizeToast.error('请补全关联信息');
        if (startNode.id === endNode.id) return customizeToast.error('关联关系不能指向自身');
        // 创建连接
        addNodeLink({
            graphId: currentOpenMapInfo.id,
            startNodeLabel: startNode['labels'][0],
            endNodeLabel: endNode['labels'][0],
            relationshipType: 'IdeaAndIdea', // 目前是固定的 节点 -> 节点
            startNodeId: values.linkStart,
            endNodeId: values.linkEnd,
            name: values.linkName,
            description: values.linkIntro
        }).then(res => {
            if (res.code === 0) {
                // !刷新数据
                dispatch(
                    getGraphDetail({
                        currentOpenMapId: currentOpenMapInfo.id,
                        xhrParams: {
                            graphId: currentOpenMapInfo.id
                        }
                    })
                );
                // 清空表单内容
                setValues({ linkName: '', linkTags: [], linkIntro: '', linkStart: '', linkEnd: '' });
                setLinkNodeName({ linkStartName: '', linkEndName: '' });
                customizeToast.success('节点关联添加成功');
            } else {
                customizeToast.error(res.msg ?? '节点关联添加失败');
            }
        });

        // // !刷新数据
        // setTimeout(() => {
        //     dispatch(
        //         getGraphDetail({
        //             currentOpenMapId: currentOpenMapInfo.id,
        //             xhrParams: {
        //                 graphId: currentOpenMapInfo.id
        //             }
        //         })
        //     );
        // }, 300);
    };

    return (
        <React.Fragment>
            <form
                className={classes.infoPanelForms}
                noValidate
                autoComplete='off'
                style={{ minHeight: '80vh', paddingTop: '1rem' }}
            >
                <Autocomplete
                    disableClearable
                    style={{ width: '100%', flex: 1, marginBottom: 10 }}
                    // multiple
                    id='tags-filled'
                    options={['相似', '抽象', '构成', '特点', '因果', '前提', '先后', '相关']}
                    value={values.linkName}
                    // freeSolo
                    onChange={(event, newValue) => {
                        setValues({
                            ...values,
                            linkName: newValue as string
                        });
                    }}
                    // onInputChange={(e, newValue) => {
                    //     setValues({ ...values, linkName: newValue });
                    // }}
                    // renderTags={(value: string[], getTagProps) =>
                    //     value.map((option: string, index: number) => (
                    //         <Chip
                    //             key={index}
                    //             variant='default'
                    //             label={option}
                    //             size='small'
                    //             color='primary'
                    //             {...getTagProps({ index })}
                    //         />
                    //     ))
                    // }
                    renderInput={params => <TextField {...params} placeholder='概念关联关系' />}
                />
                {/* <TextField
                    id='knm-node-intro'
                    label='节点关联简介'
                    size='small'
                    value={values.linkIntro}
                    onChange={handleChangeText('linkIntro')}
                    multiline
                /> */}
                <Box display='flex' justifyContent='flex-end' sx={{ mt: 2, mb: 0 }}>
                    <Button
                        variant='contained'
                        color='primary'
                        size='small'
                        disabled={linkNodeName.linkStartName || linkNodeName.linkEndName ? false : true}
                        style={{
                            width: 150,
                            position: 'relative',
                            marginRight: 0
                        }}
                        onClick={exchangeNode}
                    >
                        交换概念关联方向
                    </Button>
                </Box>
                <Autocomplete
                    disableClearable
                    id='controllable-states-demo'
                    options={nodes.map(option => option.name).filter(t => t)}
                    value={linkNodeName.linkStartName}
                    onChange={(event, newValue) => {
                        nodes.map(node => {
                            if (node.name === newValue) {
                                setValues({
                                    ...values,
                                    linkStart: node.id
                                });
                                setLinkNodeName({
                                    ...linkNodeName,
                                    linkStartName: node.name
                                });
                            }
                        });
                    }}
                    style={{ width: '100%' }}
                    renderInput={params => <TextField {...params} label='起始节点' variant='standard' />}
                />

                <Autocomplete
                    disableClearable
                    id='controllable-states-demo'
                    options={nodes.map(option => option.name).filter(t => t)}
                    value={linkNodeName.linkEndName}
                    onChange={(event, newValue) => {
                        nodes.map(node => {
                            if (node.name === newValue) {
                                setValues({
                                    ...values,
                                    linkEnd: node.id
                                });
                                setLinkNodeName({
                                    ...linkNodeName,
                                    linkEndName: node.name
                                });
                            }
                        });
                    }}
                    style={{ width: '100%' }}
                    renderInput={params => <TextField {...params} label='目标节点' variant='standard' />}
                />

                {/* <FormControl>
                    <InputLabel id="demo-simple-select-label">起始节点</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.linkStart}
                        onChange={handleChangeLinkNodes('linkStart')}
                    >
                        {
                            nodes.map((node, index) => {
                                return (
                                    <MenuItem value={node.id} key={`${node.id}`}>{node.name}</MenuItem>
                                );
                            })
                        }
                    </Select>
                </FormControl>
                <FormControl>
                    <InputLabel id="demo-simple-select-label">目标节点</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.linkEnd}
                        onChange={handleChangeLinkNodes('linkEnd')}
                    >
                        {
                            nodes.map((node, index) => {
                                return (
                                    <MenuItem value={node.id} key={`${node.id}`}>{node.name}</MenuItem>
                                );
                            })
                        }
                    </Select>
                </FormControl> */}
                <Button
                    variant='contained'
                    color='primary'
                    endIcon={
                        linkLoading ? (
                            <CircularProgress style={{ width: 20, height: 20, color: 'white' }} />
                        ) : (
                            <QueuePlayNextIcon />
                        )
                    }
                    onClick={handleAddNewLinkClick}
                >
                    新建节点关联
                </Button>
            </form>
        </React.Fragment>
    );
};
