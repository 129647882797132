import React, { ReactNode } from 'react';
import { InputLabel, FormControl, Box, Typography, MenuItem, FormHelperText, Button } from '@mui/material';
import Select, { SelectProps } from '@mui/material/Select';
import { Loading } from '@components/common/loading';

type KMTSelectProps = {
    /** 标签 */
    label?: ReactNode;
    /** 筛选值 */
    value?: string | string[] | unknown;
    /** placeholder */
    placeholder?: string | null;
    /** 筛选项 */
    selectItemArray?: any[];
    /** 筛选框后的按钮 */
    buttonText?: string | null;
    /** 筛选框后按钮的触发函数 */
    onClickButton?: () => void;
    /** 渲染空值*/
    noneFlag?: boolean;
    /** select自带的标签*/
    selectLabel?: string;
    /** 组件样式 */
    sx?: any;
    /** 标签样式 */
    labelStyle?: any;
    /** select选择框样式 */
    selectStyle?: any;
    /** formControl 的样式*/
    formControlStyle?: any;
    /** select外层盒子样式*/
    selectWrapperStyle?: any;
    /** 提示文字 */
    helperText?: string;
    /** 是否为全宽 */
    fullWidth?: boolean;
    /** 是否正在加载选项 */
    isLoading?: boolean;
    /** 是否关闭映射 */
    closeOption?: boolean;
    /** 是否将options的key映射; 为true时默认映射到id-name，或者传入对应映射到什么 */
    mapOptionKeys?:
        | boolean
        | {
              [key: string]: string;
          };
    /** option的键值对；默认为[key,value] */
    defaultOptionKey?: [string, string];
} & SelectProps;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
        }
    }
};

const Placeholder = ({ isLoading, placeholder }) => (
    <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            color: '#a0a2a5'
        }}
    >
        {isLoading && <Loading isLoading={isLoading} size={16} loadIconSx={{ mr: 1 }} />}
        <Box className='KMTSelect-placeholder'>{placeholder}</Box>
    </Box>
);

// 判断value 是否为object
const isValueObj = value => typeof value === 'object' && !Array.isArray(value) && value !== null;

/**
 * created by 季卓琦；
 * @visibleName CustomSelect
 */
export const CustomSelect: React.FC<KMTSelectProps> = ({
    label,
    placeholder = '请选择',
    selectItemArray,
    value,
    required,
    buttonText,
    onChange,
    onClickButton,
    noneFlag,
    selectLabel,
    sx,
    labelStyle,
    selectStyle,
    formControlStyle,
    selectWrapperStyle,
    helperText,
    error,
    fullWidth,
    isLoading,
    closeOption = false,
    mapOptionKeys,
    defaultOptionKey = ['key', 'value'],
    ...others
}: KMTSelectProps) => {
    let [mappedKey, mappedValue] = defaultOptionKey;
    let list = selectItemArray;
    if (mapOptionKeys) {
        mappedKey = mapOptionKeys?.[defaultOptionKey[0]] ?? 'id';
        mappedValue = mapOptionKeys?.[defaultOptionKey[1]] ?? 'name';
        list = selectItemArray?.map(item => ({
            [mappedKey]: item[defaultOptionKey[0]],
            [mappedValue]: item[defaultOptionKey[1]]
        }));
    }
    // console.log('循环出来的 list', list);

    // 预处理一下value（可能出现：传入的value object内容一致但是地址不一致 导致对应选项不是选中状态的情况
    if (value && typeof value === 'object') {
        if (Array.isArray(value)) {
            let v;
            if (closeOption) {
                const vList = value;
                v = list?.filter(option => vList.includes(option));
            } else {
                const valueKeyList = value.map(v => v?.[mappedKey]);
                v = list?.filter(option => valueKeyList.includes(option?.[mappedKey]));
                // console.log(valueKeyList, v, 'valueKeyList v =====');
                // console.log('mappedKey ==-=-', mappedKey);
            }
            // console.log(v, '处理后的v的值');
            if (v && v.length) {
                value = v;
            }
        } else {
            value = list?.find(option => option?.[mappedKey] === value?.[mappedKey]) ?? '';
        }
    }
    // console.log('预处理后的 value 的值-----', value);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', ...sx }}>
            <Typography
                sx={{
                    ...labelStyle
                }}
            >
                {label ? (
                    required ? (
                        <>
                            <span style={{ color: '#eb333f', marginRight: '2px' }}>*</span>
                            {label}：
                        </>
                    ) : (
                        `${label}：`
                    )
                ) : (
                    ''
                )}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', ...selectWrapperStyle }}>
                <FormControl size='small' sx={{ ...formControlStyle }} fullWidth={fullWidth}>
                    {selectLabel && <InputLabel id='demo-select-small'>{selectLabel}</InputLabel>}
                    <Select
                        value={value ?? ''}
                        onChange={onChange}
                        size='small'
                        sx={{ width: '200px', ...selectStyle }}
                        displayEmpty
                        renderValue={value => {
                            if (isLoading || !value) {
                                return (
                                    <Placeholder
                                        isLoading={isLoading}
                                        placeholder={!value ? placeholder : '正在加载'}
                                    />
                                );
                            }
                            if (Array.isArray(value)) {
                                if (value.length === 0) {
                                    return <Placeholder isLoading={isLoading} placeholder={placeholder} />;
                                }
                                return isValueObj(value[0])
                                    ? value?.map(v => v?.[mappedValue]).join('、')
                                    : value.join('、');
                            } else if (typeof value === 'object') {
                                return value?.[mappedValue];
                            }
                            return value;
                        }}
                        {...(selectLabel ? { label: selectLabel } : '')}
                        MenuProps={MenuProps}
                        {...others}
                    >
                        {/* None */}
                        {noneFlag && (
                            <MenuItem value={''}>
                                <em>None</em>
                            </MenuItem>
                        )}
                        {/* 选项 */}

                        {list &&
                            list.length > 0 &&
                            list?.map((item, i) => {
                                return (
                                    <MenuItem
                                        value={item as any}
                                        id={(item?.[mappedKey] ?? item) + i}
                                        key={(item?.[mappedKey] ?? item) + i}
                                    >
                                        {item?.[mappedValue] ?? item}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                    {error && helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
                </FormControl>
                {buttonText && (
                    <Button variant='text' onClick={onClickButton}>
                        {buttonText}
                    </Button>
                )}
            </Box>
        </Box>
    );
};
