import React, { useState, useEffect } from 'react';
// improt MD style
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import img
import logo from '../../../assets/image/logo.png';
import userBackground from '../../../assets/image/user-info.jpg';
import userPicture from '../../../assets/image/users/Franz.png';
// import customize components
import { DialogBox } from '../../common';
// import MD components
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import {
    Button,
    Chip,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    TextField,
    Typography
} from '@material-ui/core';
// import MD icons
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import BrightnessHighIcon from '@material-ui/icons/BrightnessHigh';
import CloudQueueIcon from '@material-ui/icons/CloudQueue';
import AddIcon from '@material-ui/icons/Add';
import LanguageIcon from '@material-ui/icons/Language';
// import MockData
import { SystemNavItems } from '../../../settings/mocks/DefaultNavItem';
import { mockTags } from '../../../settings/mocks/DefaultTags';
// import Redux
import { useSelector } from '../../../redux/hooks';
import { useDispatch } from 'react-redux';
import { openItemToPageTab, openUserSpace } from '../../../redux/pageTabs/slice';
import { leftDrawerStateChange } from '../../../redux/leftDrawer/slice';
import { changeCurrentTheme } from '../../../redux/theme/slice';
import { changeLanguage } from '../../../redux/language/slice';
import { getUserAvatar, UserSlice } from '../../../redux/user/userSlice';
import { knmCreate, getKnmDetail } from '../../../redux/knm/knmMapSlice';
import { getGraphDetail } from '../../../redux/knm/graphSlice';
// import Router
import { useHistory } from 'react-router-dom';
// import emoji
import 'emoji-mart/css/emoji-mart.css';
import { Emoji, Picker } from 'emoji-mart';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import jwt-decode
import jwt_decode, { JwtPayload as DefaultJwtPayload } from 'jwt-decode';

import { getThemeSetStyle } from '@/utils/hooks';
import { InputWithLabel, CustomSelect } from '@/components/common/form';
// 样式
import { useStyles } from './styles';
import AddKNMDialog from './AddKNMDialog';

// 自定义jwt的类型
interface JwtPayload extends DefaultJwtPayload {
    username: string;
}

export const LeftDrawer = () => {
    // style
    const classes = useStyles();
    // router
    const history = useHistory();
    // redux
    const dispatch = useDispatch();
    const jwt = useSelector(state => state.user.token);
    const currentTheme = useSelector(state => state.theme.currentTheme);
    const currentLanguage = useSelector(state => state.language.language);
    const currentSystemNavItems = useSelector(state => state.pageTabs.projectNavMenuItems);
    const currentActivatedNavItem = useSelector(state => state.pageTabs.leftDrawerActivatedItem);
    const alreadyOpenedTabs = useSelector(state => state.pageTabs.alreadyOpenedTabs);
    const knmListInfo = useSelector(state => state.knmMap.knmList);
    const userAvatar = useSelector(state => state.user.userAvatar);
    // drawer open state & style
    const [open, setOpen] = useState(true);
    const matches = useMediaQuery('(min-width:1000px)');
    const matches600 = useMediaQuery('(min-width:600px)');
    // open add new knm dialog
    const [openDialog, setOpenDialog] = useState(false);
    // knm List with detail info
    const [currentKnmList, setCurrentKnmList] = useState<any[]>([]);
    // show username
    const [username, setUsername] = useState('');

    // get username
    useEffect(() => {
        // jwt发生编码且存在, 对jwt进行解码
        if (jwt) {
            // const token = jwt_decode<JwtPayload>(jwt);
            // setUsername(token.username);
            // get user avatar
            // dispatch(getUserAvatar({ jwt: jwt }));
        }
    }, [jwt]);

    // open add new knm dialog
    const handleOpenAddKNMDialog = () => {
        setOpenDialog(true);
    };

    // close add new knm dialog
    const handleCloseAddKNMDialog = () => {
        setOpenDialog(false);
    };

    // open drawer
    const handleDrawerOpen = () => {
        let newDrawerOpenState = !open;
        setOpen(newDrawerOpenState);
        dispatch(leftDrawerStateChange(newDrawerOpenState));
    };

    // listener of Media Query
    useEffect(() => {
        if (!matches) {
            setOpen(false);
        } else {
            setOpen(true);
        }
    }, [matches]);

    // listener: current knm list -> listen whether list have changed
    useEffect(() => {
        let newList: any[] = [];
        // console.log('knmListInfo ====>>>>', knmListInfo);
        knmListInfo.map(item => {
            newList.push({
                id: item['id'],
                title: item['name'],
                icon: '' // item['emoji']
            });
        });
        // setCurrentKnmList(newList);
    }, [knmListInfo]);

    // click nav item: Activate Left Drawer Nav Item
    const getKnmAndGraphInfo = async (id: string) => {
        // 1. get knm detail
        const result = await dispatch(
            getKnmDetail({
                knmId: id,
                jwt: jwt,
                currentKnmList: knmListInfo
            })
        );
        // console.log('result ----======', result);
        const currentOpenMapId = result['payload']['id'];
        // 2. get the knm graph detail
        dispatch(
            getGraphDetail({
                currentOpenMapId: currentOpenMapId,
                jwt: jwt
            })
        );
    };
    const handleClickNavItem = (title: string, router: string, type: string, id: string) => {
        dispatch(
            openItemToPageTab({
                openItemName: title,
                alreadyOpenedTabs: alreadyOpenedTabs,
                projectNavMenuItems: currentSystemNavItems
            })
        );
        // if open knm, then should use map_id to set currentOpenMapInfo
        if (type === 'UserKNMNavItems') {
            // using async-await to get currentOpenMapInfo
            getKnmAndGraphInfo(id);
        }
        history.push(router);
    };

    // bottom btn: enter user space
    const handleClickUserSpace = () => {
        dispatch(openUserSpace(alreadyOpenedTabs));
        history.push('/main/userSpace');
    };

    // bottom btn: change system theme
    const handleChangeTheme = () => {
        if (currentTheme === 'light') {
            dispatch(changeCurrentTheme('dark'));
        } else {
            dispatch(changeCurrentTheme('light'));
        }
    };

    // bottom btn: change system language
    const handleChangeLanguage = () => {
        dispatch(changeLanguage(currentLanguage));
    };

    // bottom btn: logout
    const handleLogout = () => {
        dispatch(UserSlice.actions.logout());
        // 重定向到登录页面
        history.push('/login');
    };

    return (
        <Drawer
            variant='permanent'
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open
                })
            }}
        >
            <Toolbar className={classes.toolbarHeader}>
                <Avatar
                    alt='Logo'
                    src={logo}
                    className={clsx({
                        [classes.logo]: open,
                        [classes.logoSingle]: !open,
                        [classes.logoLess600]: !matches600
                    })}
                />
                <Typography
                    className={clsx(classes.title, {
                        [classes.hide]: !open
                    })}
                >
                    大观念地图
                </Typography>
            </Toolbar>
            <div
                className={clsx(classes.drawerContainer, {
                    [classes.drawerContainerClose]: !open
                })}
            >
                {/* user info */}
                <div className={classes.toolbar}>
                    {/* <Avatar alt={username} src={userAvatar} className={classes.userHeader} /> */}
                    <IconButton
                        color='inherit'
                        aria-label='open drawer'
                        edge='end'
                        className={classes.menuButton}
                        key={'open-drawer'}
                        onClick={handleDrawerOpen}
                    >
                        <MenuIcon />
                    </IconButton>
                </div>
                {/* <div className={clsx(classes.useNameBox, { [classes.hide]: !open })}>{username}</div> */}
                {/* Project Nav Menu*/}
                {SystemNavItems.map(item => {
                    if (item.type === 'SystemNavItems') {
                        return (
                            <Tooltip title={open ? '' : item.title} arrow placement='right' key={`${item.id}-tooltip`}>
                                <ListItem
                                    button
                                    key={`${item.id}-item`}
                                    className={classes.menuList}
                                    selected={currentActivatedNavItem?.title === item.title ? true : false}
                                    onClick={() =>
                                        handleClickNavItem(item.title, item.router, 'SystemNavItems', item.id)
                                    }
                                >
                                    <ListItemIcon className={classes.menuMainIcon} key={`${item.id}-icon`}>
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={item.title}
                                        key={`${item.id}-text`}
                                        className={clsx({ [classes.hide]: !open })}
                                    />
                                </ListItem>
                            </Tooltip>
                        );
                    }
                })}
                {/* KNM list */}
                <Divider className={clsx({ [classes.hide]: open })} />
                <List
                    className={clsx(classes.listSubTitle, {
                        [classes.hide]: !open
                    })}
                    subheader={<ListSubheader>大观念地图</ListSubheader>}
                    key={`knm-list`}
                />
                {currentKnmList.map(item => {
                    return (
                        <Tooltip title={open ? '' : item.title} key={`${item.id}-tooltip`} arrow placement='right'>
                            <ListItem
                                button
                                key={`${item.id}-item`}
                                className={classes.menuList}
                                selected={currentActivatedNavItem?.title === item.title ? true : false}
                                onClick={() =>
                                    handleClickNavItem(item.title, '/main/detail', 'UserKNMNavItems', item.id)
                                }
                                // onClick={() => handleOpenDetailKnmPage(item.id)}
                            >
                                <ListItemIcon className={classes.menuIcon} key={`${item.id}-icon`}>
                                    {/* <Emoji emoji={item.icon} set='twitter' size={20} /> */}
                                </ListItemIcon>
                                <ListItemText
                                    primary={item.title}
                                    key={`${item.id}-text`}
                                    className={clsx({ [classes.hide]: !open })}
                                />
                            </ListItem>
                        </Tooltip>
                    );
                })}
                <ListItem
                    button
                    key={`add-knm-item`}
                    className={classes.menuList}
                    // selected={currentActivatedNavItem.title === item.title ? true : false}
                    onClick={handleOpenAddKNMDialog}
                >
                    <ListItemIcon className={classes.menuMainIcon} style={{ color: '#959595' }} key={`add-knm-icon`}>
                        <AddIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={'大观念地图'}
                        style={{ color: '#959595' }}
                        key={`add-knm-text`}
                        className={clsx({ [classes.hide]: !open })}
                    />
                </ListItem>

                {/* 新建大观念地图弹窗 */}
                <AddKNMDialog openDialog={openDialog} handleCloseDialog={handleCloseAddKNMDialog} />
            </div>
            {/* Bottom Button: Setting & LightOrDark & Logout */}
            <BottomNavigation
                showLabels
                className={clsx(classes.bottomNavLight, {
                    [classes.bottomNavLight]: currentTheme === 'light',
                    [classes.bottomNavDark]: currentTheme === 'dark',
                    [classes.hide]: !open
                })}
            >
                {/* <Tooltip title="用户空间" arrow>
                    <BottomNavigationAction
                        icon={<CloudQueueIcon />}
                        className={classes.bottomNavIcon}
                        key={'设置'}
                        onClick={handleClickUserSpace}
                    />
                </Tooltip> */}
                {/* {currentTheme === 'light' ? (
                    <Tooltip title='切换为深色模式' arrow>
                        <BottomNavigationAction
                            icon={<Brightness4Icon />}
                            className={classes.bottomNavIcon}
                            key={'深色模式'}
                            onClick={handleChangeTheme}
                        />
                    </Tooltip>
                ) : (
                    <Tooltip title='切换为浅色模式' arrow>
                        <BottomNavigationAction
                            icon={<BrightnessHighIcon />}
                            className={classes.bottomNavIcon}
                            key={'浅色模式'}
                            onClick={handleChangeTheme}
                        />
                    </Tooltip>
                )} */}
                <Tooltip title='语言切换' arrow>
                    {/* LanguageIcon */}
                    <BottomNavigationAction
                        icon={<LanguageIcon />}
                        className={classes.bottomNavIcon}
                        key={'语言切换'}
                        onClick={handleChangeLanguage}
                    />
                </Tooltip>
                <Tooltip title='退出' arrow>
                    <BottomNavigationAction
                        icon={<ExitToAppIcon />}
                        className={classes.bottomNavIcon}
                        key={'退出'}
                        onClick={handleLogout}
                    />
                </Tooltip>
            </BottomNavigation>
        </Drawer>
    );
};

interface AddKNMDialogState {
    openDialog: boolean;
    handleCloseDialog: () => void;
}

interface NewKNMInfoState {
    title: string;
    tags: any[];
    intro: string;
    emoji: string;
}

/**
 * 添加知识地图弹框
 * @param param0
 * @returns
 */
// const AddKNMDialog: React.FC<AddKNMDialogState> = ({ openDialog, handleCloseDialog }) => {
//     // redux
//     const dispatch = useDispatch();
//     const currentTheme = useSelector(state => state.theme.currentTheme);
//     const jwt = useSelector(state => state.user.token);
//     const currentKnmList = useSelector(state => state.knmMap.knmList);
//     // style
//     const classes = useStyles();
//     const [values, setValues] = useState<NewKNMInfoState>({
//         title: '',
//         tags: [mockTags[1].title],
//         intro: '',
//         emoji: 'books'
//     });
//     // emoji i18n
//     const emojiI18n = {
//         search: '搜索',
//         clear: '清空', // Accessible label on "clear" button
//         notfound: '没有找到Emoji',
//         skintext: '选择你的默认肤色',
//         categories: {
//             search: '搜索结果',
//             recent: '常用',
//             smileys: '笑脸 & 表情',
//             people: '人 & 身体',
//             nature: '动物 & 自然',
//             foods: '食物 & 饮品',
//             activity: '活动',
//             places: '旅行 & 场地',
//             objects: '物品',
//             symbols: '符号',
//             flags: '旗帜',
//             custom: '自定义'
//         },
//         categorieslabel: 'Emoji类别', // Accessible title for the list of categories
//         skintones: {
//             1: '默认肤色',
//             2: '浅肤色',
//             3: '适中浅肤色',
//             4: '适中肤色',
//             5: '适中深肤色',
//             6: '深肤色'
//         }
//     };

//     useEffect(() => {
//         setValues({
//             title: '',
//             tags: [mockTags[1].title],
//             intro: '',
//             emoji: 'books'
//         });
//     }, [openDialog]);

//     const handleChangeValues = (prop: keyof NewKNMInfoState) => (event: React.ChangeEvent<HTMLInputElement>) => {
//         setValues({
//             ...values,
//             [prop]: event.target.value
//         });
//     };

//     const handleChangeEmoji = emoji => {
//         setValues({
//             ...values,
//             emoji: emoji.id
//         });
//     };

//     const handleNewKNM = () => {
//         // create a new
//         dispatch(
//             knmCreate({
//                 currentKnmMaps: currentKnmList,
//                 jwt: jwt,
//                 title: values.title,
//                 tags: values.tags,
//                 introduction: values.intro,
//                 emoji: values.emoji
//             })
//         );
//         handleCloseDialog();
//     };

//     return (
//         <DialogBox
//             boxSize={'lg'}
//             open={openDialog}
//             title={'添加知识地图'}
//             contain={
//                 <React.Fragment>
//                     <TextField
//                         id='knm-node-name'
//                         label='知识地图标题'
//                         size='small'
//                         value={values.title}
//                         onChange={handleChangeValues('title')}
//                         style={{ width: '100%', marginBottom: 10 }}
//                     />
//                     <Autocomplete
//                         style={{ width: '100%', flex: 1, marginBottom: 10 }}
//                         multiple
//                         id='tags-filled'
//                         options={mockTags.map(option => option.title)}
//                         value={values.tags}
//                         freeSolo
//                         onChange={(event, newValue) => {
//                             setValues({
//                                 ...values,
//                                 tags: newValue
//                             });
//                         }}
//                         renderTags={(value: string[], getTagProps) =>
//                             value.map((option: string, index: number) => (
//                                 <Chip
//                                     key={index}
//                                     variant='default'
//                                     label={option}
//                                     size='small'
//                                     color='primary'
//                                     {...getTagProps({ index })}
//                                 />
//                             ))
//                         }
//                         renderInput={params => <TextField {...params} placeholder='选择或输入新标签' />}
//                     />
//                     <TextField
//                         id='knm-node-intro'
//                         label='知识节点简介'
//                         size='small'
//                         value={values.intro}
//                         onChange={handleChangeValues('intro')}
//                         multiline
//                         style={{ width: '100%', marginBottom: 10 }}
//                     />
//                     <div className={classes.emojiStyle} style={{ width: '100%', marginBottom: 20, fontSize: 16 }}>
//                         知识地图小图标: <Emoji emoji={values.emoji} set='twitter' size={30} />{' '}
//                         &nbsp;&nbsp;（请选择合适的emoji）
//                     </div>
//                     <div className={classes.emojiPicker}>
//                         <Picker
//                             style={{
//                                 width: 451
//                             }}
//                             set='twitter'
//                             title={'选择项目图标'}
//                             emoji='point_up'
//                             i18n={emojiI18n}
//                             onSelect={handleChangeEmoji}
//                             theme={currentTheme === 'light' ? 'light' : 'dark'}
//                         />
//                     </div>
//                 </React.Fragment>
//             }
//             actions={
//                 <React.Fragment>
//                     <Button variant='text' color='secondary' onClick={handleCloseDialog}>
//                         取消
//                     </Button>
//                     <Button variant='text' color='primary' onClick={handleNewKNM}>
//                         新建
//                     </Button>
//                 </React.Fragment>
//             }
//         />
//     );
// };
