import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { openItemToPageTab } from '@/redux/pageTabs/slice';
import { getKnmDetail, addNewKnmList } from '@/redux/knm/knmMapSlice';
import { getGraphDetail } from '@/redux/knm/graphSlice';
import { Box, Button } from '@mui/material';
import { getGraphList } from '@/api/creatMap';
import type { GraphList } from '@/api/creatMap';
import DataGrid from '@components/common/datagrid';
import { InputWithLabel } from '@components/common/form';
import { delGraph } from '@/api/creatMap';
import AddKNMDialog from '@components/layout/drawer/AddKNMDialog';
import { useDebounce } from '@/utils/hooks';
import PopConfirm from '@components/common/popconfirm';
import { State, SnackbarAlert } from '@components/common/SnackbarAlert';

interface GraphParams {
    name?: string;
    description?: string;
    subjects?: string[];
}

const KNMManage: React.FC = () => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const store: any = useSelector(state => state);

    const jwt = useSelector((state: any) => state.user.token);
    const currentSystemNavItems = useSelector((state: any) => state.pageTabs.projectNavMenuItems);
    const alreadyOpenedTabs = useSelector((state: any) => state.pageTabs.alreadyOpenedTabs);
    const knmListInfo = useSelector((state: any) => state.knmMap.knmList);
    const tableRef = useRef<{ list: any[] }>({ list: [...knmListInfo] });

    const [delRender, setDelRender] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    // 弹窗信息
    const [popConfirmData, setPopConfirmData] = useState<{ id: string; open: boolean; anchorEl: any }>({
        id: '',
        open: false,
        anchorEl: null
    });
    // 提示信息
    const [snackbar, setSnackbar] = useState<State>({ open: false, type: 'success', msg: '' });

    // 筛选条件
    const [filterData, setFilterData] = useState({
        type: '',
        class: {},
        map: {},
        name: ''
    });
    // // 表格数据相关
    // const [cardData, setCardData] = useState<GraphList>({
    //     list: [],
    //     totalCount: 0,
    //     totalPage: 0,
    //     pageNumber: 1,
    //     pageSize: 12
    // });

    // !获取数据
    const getKnmAndGraphInfo = async (id: string, name: string, subjects?: []) => {
        // 1. get knm detail
        const result = await dispatch(
            getKnmDetail({
                knmId: id,
                jwt: jwt,
                currentKnmList: knmListInfo
            })
        );
        const currentOpenMapId = result['payload']['id'];
        // 2. get the knm graph detail
        // !获取 graph 数据 --------------------------------------------->>>>>>>>>>
        dispatch(
            getGraphDetail({
                currentOpenMapId,
                jwt,
                xhrParams: { graphId: id }
            })
        );
    };

    // !跳转到对应的页面
    const handleClickNavItem = (title: string, router: string, type: string, id: string, subjects) => {
        dispatch(
            openItemToPageTab({
                openItemName: title,
                alreadyOpenedTabs: alreadyOpenedTabs,
                projectNavMenuItems: currentSystemNavItems
            })
        );
        if (type === 'UserKNMNavItems') {
            getKnmAndGraphInfo(id, title, subjects);
        }
        history.push(router);
    };

    // 权限审批 - 获取表格数据
    const dataSource = ({ skip, limit, sortInfo }) => {
        const params: any = {};
        if (filterData.name) {
            params.name = filterData.name;
        }
        return getGraphList({
            pageNumber: skip / limit + 1,
            pageSize: limit,
            ...params
        }).then(res => {
            const { code, data } = res;
            // !叠加后的导航
            tableRef.current.list.push(...data.list);
            const allNodeList = tableRef.current.list;
            const repeatList = allNodeList.reduce((unique, list) => {
                const existingItem = unique.find(obj => obj.id === list.id);
                if (!existingItem) unique.push(list);
                return unique;
            }, []);
            // !累加
            dispatch(addNewKnmList(repeatList));
            return {
                data: code === 0 ? data.list : [],
                count: code === 0 ? data.totalCount : 0
            };
        });
    };

    // 设置筛选条件的值
    const handleSetFilterData = (e, keyName) => {
        setFilterData({
            ...filterData,
            [keyName]: e.target.value
        });
    };

    const handleCloseAddKNMDialog = () => {
        setOpenDialog(false);
        setDelRender(new Date().getTime());
    };

    // 防抖
    const debounceName = useDebounce(filterData.name, 500);
    // 关闭提示
    useEffect(() => {
        if (snackbar.open) {
            setTimeout(() => {
                setSnackbar({ ...snackbar, open: false });
            }, 2000);
        }
    }, [snackbar.open]);
    useEffect(() => {
        const state = location.state as { reload: boolean };
        if (state && state?.reload) {
            setDelRender(new Date().getTime());
        }
    }, [location.state]);

    return (
        <Box sx={{ padding: '10px 30px' }}>
            <h1>所有地图</h1>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '24px' }}>
                <InputWithLabel
                    placeholder={'请输入名称'}
                    value={filterData.name}
                    sx={{ display: 'flex', flexDirection: 'column' }}
                    inputStyle={{ input: { backgroundColor: '#fff' } }}
                    onChange={e => {
                        handleSetFilterData(e, 'name');
                    }}
                />
                <Button
                    size='small'
                    variant='contained'
                    onClick={() => {
                        setOpenDialog(true);
                    }}
                >
                    新增大观念地图
                </Button>
            </Box>

            <DataGrid
                type='kngManageList'
                dataSource={dataSource}
                deps={[delRender, debounceName]}
                renderActions={({ data }) => (
                    <Box>
                        <Button
                            size='small'
                            onClick={() => {
                                const subjects = data.subjects.map(s => s.id);
                                handleClickNavItem(data.name, '/main/detail', 'UserKNMNavItems', data.id, subjects);
                            }}
                        >
                            查看
                        </Button>
                        <Button
                            size='small'
                            variant='outlined'
                            color='error'
                            onClick={e => {
                                setPopConfirmData({
                                    id: data.id,
                                    open: true,
                                    anchorEl: e.currentTarget
                                });
                            }}
                        >
                            删除
                        </Button>
                    </Box>
                )}
            />

            {/* 新增大观念地图 */}
            <AddKNMDialog openDialog={openDialog} handleCloseDialog={handleCloseAddKNMDialog} />

            {/* 二次确认弹窗 */}
            <PopConfirm
                delText={'确定是否删除？'}
                open={popConfirmData.open}
                anchorEl={popConfirmData.anchorEl}
                onConfirm={() => {
                    delGraph(popConfirmData.id)
                        .then(res => {
                            if (res.code === 0) {
                                setDelRender(new Date().getTime());
                                setSnackbar({ open: true, type: 'success', msg: '删除成功' });
                                setPopConfirmData({ id: '', open: false, anchorEl: null });
                                const newKnmListInfo = knmListInfo.filter(k => k.id !== popConfirmData.id);
                                dispatch(addNewKnmList(newKnmListInfo));
                            }
                        })
                        .catch(rej => {
                            setSnackbar({ open: true, type: 'error', msg: '删除失败' });
                            setPopConfirmData({ id: '', open: false, anchorEl: null });
                        });
                }}
                onClose={() => {
                    setPopConfirmData({ id: '', open: false, anchorEl: null });
                }}
            />
            {/* 提示 */}
            <SnackbarAlert open={snackbar.open} type={snackbar.type} msg={snackbar.msg} duration={2000} />
        </Box>
    );
};
export default KNMManage;
