import React, { forwardRef } from 'react';
import type { Ref } from 'react';
import { FormControl, Box, InputLabel, FormHelperText, TextField, Select, MenuItem } from '@mui/material';
import { Controller } from 'react-hook-form';
import * as FormType from '@components/common/form/types';

/** 输入框 */
const InputForward = (props: FormType.FormInputProps, ref: Ref<FormType.FormDomRef>) => {
    const { required = true, error = false } = props;
    const { label, errorText, registerRef, disabled } = props;
    const { style, placeholder } = props;

    return (
        <React.StrictMode>
            <Box
                ref={ref}
                sx={{
                    height: 66,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    ...style?.wrap
                }}
            >
                <InputLabel
                    required={required === false ? false : true}
                    error={error}
                    sx={{ direction: 'ltr', width: '100px', ...style?.labelStyle }}
                >
                    {label}
                </InputLabel>
                <TextField
                    sx={style?.inputStyle}
                    fullWidth
                    error={error}
                    placeholder={placeholder}
                    disabled={disabled}
                    size='small'
                    {...registerRef}
                />
            </Box>
            {required && (
                <FormHelperText
                    error={error}
                    sx={{
                        visibility: error ? 'visible' : 'hidden',
                        ...style?.errorTextStyle
                    }}
                >
                    {errorText || '此项为必填项'}
                </FormHelperText>
            )}
        </React.StrictMode>
    );
};
export const InputField = forwardRef(InputForward);

// 包装Controller，作用是管理表单数据和验证逻辑。
export const FormHookControl = (props: FormType.FormControl) => {
    const { control, name = '', children } = props;
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => {
                return <>{children}</>;
            }}
        />
    );
};

/**
 * 下拉选择框
 * @param props
 * @param ref
 * @returns
 */
const SelectForward = (props: FormType.FormSelectProps, ref: Ref<any>) => {
    const { required = true, noneFlag = true, control, getAllOption, optionsType = ['value', 'name'] } = props;
    const { label, errorText, registerRef, error, disabled } = props;
    const { style, placeholder, multiple = false, options } = props;

    /**
     * ! optins 中值的类型。
     * ! optionsKey -> MenuItem value
     * ! optionsTitle -> MenuItem 显示的名称
     */
    const [optionsKey, optionsTitle] = optionsType;

    /** 获取下拉选择框的值。首先处理传入的值是否是对象，如果是对象话再判断是否为多选 */
    const getSelValue = field => {
        return field.value instanceof Object
            ? multiple
                ? field.value instanceof Array
                    ? field.value?.map(t => (t && t[optionsTitle] ? t[optionsTitle] : t))
                    : [field.value[optionsTitle]]
                : field.value[optionsTitle] ?? ''
            : field.value;
    };

    return (
        <React.StrictMode>
            <Box
                ref={ref}
                sx={{
                    height: 66,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    ...style?.wrap
                }}
            >
                <InputLabel
                    required={required === false ? false : true}
                    error={error}
                    sx={{ width: '100px', ...style?.labelStyle }}
                >
                    {label}
                </InputLabel>
                {/* 下拉选择框 */}
                <Controller
                    name={registerRef?.name}
                    control={control}
                    defaultValue={{}}
                    render={({ field }) => {
                        return (
                            <FormControl size='small' fullWidth sx={style?.formControlStyle}>
                                <Select
                                    size='small'
                                    fullWidth
                                    displayEmpty
                                    sx={style?.selectStyle}
                                    placeholder={placeholder || '请选择'}
                                    {...registerRef}
                                    disabled={disabled}
                                    error={error}
                                    multiple={multiple}
                                    value={getSelValue(field)}
                                    onChange={field.onChange}
                                    // onChange={e => {
                                    //     console.log('值改变时 e 是什么', e, e.target.value);
                                    //     field.onChange(e);
                                    // }}
                                    renderValue={v => {
                                        // !为空的时候显示提示
                                        if (
                                            !v ||
                                            (v instanceof Array && v.filter(val => val !== undefined)?.length === 0)
                                        ) {
                                            return <Box sx={{ color: '#aaa' }}>{placeholder}</Box>;
                                        }
                                        // !是否使用特殊写法及，需要使用 options 中全部的值
                                        if (v && Array.isArray(v)) {
                                            const newVal = v.filter(val => val !== undefined);
                                            return getAllOption
                                                ? newVal.map(allV => JSON.parse(allV)[optionsTitle])?.join('、')
                                                : newVal?.join('、');
                                        } else if (v && typeof v === 'string') {
                                            return getAllOption ? JSON.parse(v)[optionsTitle] : v;
                                        }
                                        return v;
                                    }}
                                >
                                    {!noneFlag && (
                                        <MenuItem value=''>
                                            <em>请选择</em>
                                        </MenuItem>
                                    )}
                                    {options?.map(m => (
                                        <MenuItem
                                            key={m.value || m[optionsKey] || m}
                                            value={!getAllOption ? m[optionsTitle] || m : JSON.stringify(m)}
                                        >
                                            {m[optionsTitle] || m}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    }}
                />
            </Box>
            {required && (
                <FormHelperText
                    error={error}
                    sx={{
                        visibility: error ? 'visible' : 'hidden',
                        ...style?.errorTextStyle
                    }}
                >
                    {errorText || '此项为必填项'}
                </FormHelperText>
            )}
        </React.StrictMode>
    );
};
export const SelectField = forwardRef(SelectForward);
