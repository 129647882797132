import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
// 通过i18n属性 调整组件语言。有更好的表达请更改相应字段
export const zhCN = Object.assign({}, ReactDataGrid.defaultProps.i18n, {
    pageText: '第',
    ofText: '页，共',
    perPageText: '每页结果',
    showingText: '显示',
    clearAll: '清空所有',
    clear: '清空',
    // showFilteringRow: '',
    // hideFilteringRow: '',
    // enable: '',
    // disable: '',
    sortAsc: '升序',
    sortDesc: '降序',
    unsort: '取消排序',
    group: '分组',
    ungroup: '取消分组',
    lockStart: '固定在列首',
    lockEnd: '固定在列尾',
    unlock: '取消固定',
    columns: '列',
    autoresizeThisColumn: '自动调整此列宽度',
    autoresizeAllColumns: '自动调整所有列宽度',
    autoSizeToFit: '自动调整大小以适应'
    // contains: '',
    // startsWith: '',
    // endsWith: '',
    // notContains: '',
    // inlist: '',
    // notinlist: '',
    // neq: '',
    // inrange: '',
    // notinrange: '',
    // eq: '',
    // notEmpty: '',
    // empty: '',
    // lt: '',
    // lte: '',
    // gt: '',
    // gte: '',
    // before: '',
    // beforeOrOn: '',
    // afterOrOn: '',
    // after: '',
    // start: '',
    // end: '',
    // dragHeaderToGroup: '',
    // noRecords: '',
    // 'calendar.todayButtonText': '',
    // 'calendar.clearButtonText': '',
    // 'calendar.okButtonText': '',
    // 'calendar.cancelButtonText': ''
});
