import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// axios
import axios from 'axios';
// api
import { API } from '../../settings/api';
import { userLogins } from '@/api/creatMap';
// const fs = require('fs');

// User State
interface UserState {
    userAvatar: any;
    userStatics: any;
    loading: boolean;
    staticsLoading: boolean;
    error: string | null;
    token: string | null;
    email: string;
}

const initialUserState: UserState = {
    userAvatar: null,
    userStatics: null,
    loading: false,
    staticsLoading: false,
    error: null,
    token: null,
    email: ''
};

// action: login
export const userLogin = createAsyncThunk(
    'user/Login',
    async (params: { email: string; password: string }, thunkAPI) => {
        try {
            const { code, data } = await userLogins({
                account: params.email, // 'wuseshi',
                password: params.password // ECNU190112
            });
            const { origin } = window.location;
            if (code === 0) {
                localStorage.setItem('token', data);
                window.location.href = origin;
            }
            return {
                // user: data.user,
                token: data
                // email: params.email
            };
        } catch (error) {
            // get the error msg return from the server
            return thunkAPI.rejectWithValue(error);
        }
    }
);

// action: register
export const userRegister = createAsyncThunk(
    'user/Register',
    async (
        params: {
            username: string;
            email: string;
            password: string;
        },
        thunkAPI
    ) => {
        try {
            const { data } = await axios.post(API.user.register, {
                username: params.username,
                e_mail: params.email,
                password: params.password
            });
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

// action: verify whether user jwt is expired
export const userJWTVerify = createAsyncThunk('user/JWTVerify', async (params: { jwt: string | null }, thunkAPI) => {
    try {
        const { data } = await axios.get(API.user.jwtVerify, {
            headers: {
                Authorization: `bearer ${params.jwt}`
            }
        });
        return data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

// action: user upload user avatar
export const uploadUserAvatar = createAsyncThunk(
    'user/uploadAvatar',
    async (params: { jwt: string | null; file: any }, thunkAPI) => {
        const formData = new FormData();
        formData.append('file', params.file[0], params.file[0].file.name);
        const file = await axios.post(
            // 'http://localhost:3001/user/avatar',
            API.user.avatar,
            {
                formData
            },
            {
                headers: {
                    'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
                    Authorization: `bearer ${params.jwt}`
                }
            }
        );
        console.log(file);
    }
);

// get user avatar
export const getUserAvatar = createAsyncThunk('user/getAvatar', async (params: { jwt: string | null }, thunkAPI) => {
    try {
        const userAvatarUrl = await axios.get(
            // 'http://localhost:3001/user/avatar',
            API.user.avatar,
            {
                headers: {
                    Authorization: `bearer ${params.jwt}`
                }
            }
        );
        return {
            userAvatar: userAvatarUrl.data
        };
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

// get user statics
export const getUserStatics = createAsyncThunk('user/getStatics', async (params: { jwt: string | null }, thunkAPI) => {
    try {
        // 'http://localhost:3001/user/statics'
        // const userStatics = await axios.get(API.user.statics, {
        //     headers: {
        //         Authorization: `bearer ${params.jwt}`
        //     }
        // });
        return {
            userStatics: {} // userStatics.data
        };
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

// action: 用户修改信息前的密码校验
export const userPasswordVerify = createAsyncThunk(
    'user/verifyPassword',
    async (
        params: {
            email: string;
            password: string;
            jwt: string | null;
        },
        thunkAPI
    ) => {
        try {
            const data = await axios.post(
                API.user.passwordVerify,
                {
                    e_mail: params.email,
                    password: params.password
                },
                {
                    headers: {
                        Authorization: `bearer ${params.jwt}`
                    }
                }
            );
            return data;
        } catch (error) {
            // get the error msg return from the server
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const userInfoUpdate = createAsyncThunk(
    'user/userInfoUpdate',
    async (
        params: {
            userInfo: any;
            jwt: string | null;
        },
        thunkAPI
    ) => {
        try {
            const userNewInfo = await axios.patch(API.user.update, params.userInfo, {
                headers: {
                    Authorization: `bearer ${params.jwt}`
                }
            });
            return userNewInfo.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

// slice
export const UserSlice = createSlice({
    name: 'user',
    initialState: initialUserState,
    reducers: {
        logout: state => {
            state.token = null;
            state.error = null;
            state.loading = false;
            localStorage.removeItem('token');
        }
    },
    extraReducers: {
        // user login
        [userLogin.pending.type]: state => {
            state.loading = true;
        },
        [userLogin.fulfilled.type]: (state, action) => {
            // state.token = action.payload.token;
            // state.email = action.payload.email;
            state.loading = false;
            state.error = null;
        },
        [userLogin.rejected.type]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        // user register
        [userRegister.pending.type]: state => {
            state.loading = true;
        },
        [userRegister.fulfilled.type]: state => {
            state.loading = false;
            state.error = null;
        },
        [userRegister.rejected.type]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        // user jwt verify
        [userJWTVerify.pending.type]: state => {
            state.loading = true;
        },
        [userJWTVerify.fulfilled.type]: state => {
            state.loading = false;
            state.error = null;
        },
        [userJWTVerify.rejected.type]: (state, action) => {
            state.loading = false;
            state.token = null; // 一定要把token清空! 要不会出大事!
            state.error = action.payload;
            window.localStorage.removeItem('token');
        },
        // get user avatar
        [getUserAvatar.pending.type]: state => {
            state.loading = true;
        },
        [getUserAvatar.fulfilled.type]: (state, action) => {
            state.userAvatar = action.payload.userAvatar;
            state.loading = false;
            state.error = null;
        },
        [getUserAvatar.rejected.type]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        // get user statics
        [getUserStatics.pending.type]: state => {
            state.staticsLoading = true;
        },
        [getUserStatics.fulfilled.type]: (state, action) => {
            state.userStatics = action.payload.userStatics;
            state.staticsLoading = false;
            state.error = null;
        },
        [getUserStatics.rejected.type]: (state, action) => {
            state.staticsLoading = false;
            state.error = action.payload;
        }
    }
});
