import React, { forwardRef, useImperativeHandle, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormHookControl, InputField, SelectField } from '@/components/common/form/FormFields';
import { Box } from '@mui/material';
import CustomAutocomplete from '@components/common/autocomplete';

const formSx = {
    wrap: { width: '45%', height: '65px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' },
    selectStyle: { backgroundColor: '#fff', height: '30px', '& > div': { fontSize: '14px' } },
    inputStyle: { backgroundColor: '#fff', fontSize: '14px', input: { fontSize: '14px' } },
    labelStyle: { fontSize: '14px', width: '100%', color: '#fff' }
};

export interface DefaultValues {
    proposerTypes: string; // 提出者
    relevantideas: string; // 概念类型
    isCoreIdea: string; // 核心概念
    isCrosscuttingIdea: string; // 是否跨学科
    pointingDimension: string; // 指向维度
    appearanceWay: string; // 出现方式
    cognitive: string; // 认知
    skills: string; // 技能
    emotions: string; // 情感
    reference: string; // 参考来源
    complexity: string; // 复杂度
    abstractionDegree: string; // 抽象程度
    ideaFocusDegree: string; // 概念聚焦程度
    inertActivatingProbability: string; // 惰性可激活程度
    difficulty: string; // 难度
}
export type GetDefaultValues = {
    keywords?: string[]; // 关键词
    acquisitionDimensions?: any; // 习得维度
    form?: any;
} & DefaultValues;

export const defaultValues = {
    proposerTypes: JSON.stringify({ id: 'AUTHORITY', name: '权威' }), // 提出者
    relevantideas: '', // 概念类型
    isCoreIdea: JSON.stringify({ id: 'true', name: '是' }), // 核心概念
    isCrosscuttingIdea: JSON.stringify({ id: 'true', name: '是' }), // 是否跨学科
    pointingDimension: '', // 指向维度
    appearanceWay: '', // 出现方式

    cognitive: '', // 认知
    skills: '', // 技能
    emotions: '', // 情感

    reference: '', // 参考来源

    complexity: '', // 复杂度
    abstractionDegree: '', // 抽象程度
    ideaFocusDegree: '', // 概念聚焦程度
    inertActivatingProbability: '', // 惰性可激活程度
    difficulty: '' // 难度
};
const boolOption = [
    { id: 'true', name: '是' },
    { id: 'false', name: '否' }
];
const presentedByOption = [
    // { id: 'AUTHORITY', name: '权威' },
    // { id: 'TEACHING_RESEARCHER', name: '教育研究者' },
    // { id: 'TEACHER', name: '教师' },
    // { id: 'STUDENT', name: '学生' }
    { id: 'authority', name: '权威' },
    { id: 'teaching_researcher', name: '教育研究者' },
    { id: 'teacher', name: '教师' },
    { id: 'student', name: '学生' }
];
const typeOption = [
    { id: 'definition', name: '概念' },
    { id: 'viewpoint', name: '观念' },
    { id: 'thesis', name: '论题' }
];
const dimensionOption = [
    { id: 'self', name: '自我' },
    { id: 'other_people', name: '其他人' },
    { id: 'objective_world', name: '客观世界' }
];
const appearsTypeOption = [
    { id: 'planned', name: '计划中的' },
    { id: 'supplementary', name: '增补的' },
    { id: 'created', name: '自我创造的' }
];
const levelOption = [
    { id: 30, name: '低' },
    { id: 50, name: '中' },
    { id: 80, name: '高' }
];
const keywordOption = [
    { id: '元认知', title: '元认知' },
    { id: '知识构建', title: '知识构建' },
    { id: '认知论信念', title: '认知论信念' },
    { id: '复杂系统', title: '复杂系统' },
    { id: '知识观', title: '知识观' },
    { id: '概念转变', title: '概念转变' }
];

export interface FormRef {
    getWatchSelect: () => any;
    resetForm: () => void;
}
interface FormProps {
    defaultData?: GetDefaultValues;
}
type KeyWords = { id: number | string; title: string }[];

// !返回对应的值
const returnStr = (list, val, key = 'id') => {
    if (val === null) return '';
    const findVal = list.find(l => l[key] == (typeof val === 'boolean' ? val + '' : val));
    // console.log('val', val, 'findVal', findVal);
    return findVal && findVal ? JSON.stringify(findVal) : '';
};

/**
 * 新建概念 form 表单
 * @returns
 */
const AddNewNode = forwardRef<FormRef, FormProps>((props, parentRef) => {
    const { defaultData } = props;
    const [keywords, setKeyWords] = useState<KeyWords>([]);

    // 表单数据
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        control,
        watch
    } = useForm<any>({ defaultValues });

    const topPropertiesList = [
        { label: '提出者', stateName: 'proposerTypes', getAllOption: true, options: presentedByOption },
        { label: '概念类型', stateName: 'relevantideas', getAllOption: true, options: typeOption },
        { label: '是否为核心概念', stateName: 'isCoreIdea', getAllOption: true, options: boolOption },
        { label: '是否跨学科', stateName: 'isCrosscuttingIdea', getAllOption: true, options: boolOption },
        { label: '指向维度', stateName: 'pointingDimension', getAllOption: true, options: dimensionOption },
        { label: '出现方式', stateName: 'appearanceWay', getAllOption: true, options: appearsTypeOption }
    ];
    const contentPropertiesList = [
        { label: '认知', stateName: 'cognitive', getAllOption: true, options: boolOption },
        { label: '技能', stateName: 'skills', getAllOption: true, options: boolOption },
        { label: '情感', stateName: 'emotions', getAllOption: true, options: boolOption }
    ];
    const footerPropertiesList = [
        { label: '复杂度', stateName: 'complexity', getAllOption: true, options: levelOption },
        { label: '抽象程度', stateName: 'abstractionDegree', getAllOption: true, options: levelOption },
        { label: '概念聚焦程度', stateName: 'ideaFocusDegree', getAllOption: true, options: levelOption },
        { label: '惰性可激活程度', stateName: 'inertActivatingProbability', getAllOption: true, options: levelOption },
        { label: '难度', stateName: 'difficulty', getAllOption: true, options: levelOption }
    ];

    const getJSONValue = val => {
        if (!val) return val;
        try {
            return val instanceof Array ? val.map(v => JSON.parse(v)) : JSON.parse(val);
        } catch (error) {
            return val;
        }
    };
    // !数据中的字符串转对象
    const valueToJSON = data => {
        const keyList = Object.keys(data);
        for (const key of keyList) {
            if (getJSONValue(data[key]) instanceof Object) {
                data[key] = getJSONValue(data[key]);
            }
        }
        return data;
    };
    // 存在手动输入
    const haveCustomData = (list, val, key = 'id') => {
        const defaultIdList = list.map(d => d.id);
        const defaultOptionList = val
            .map(v => {
                return list.find(l => l[key] === v);
            })
            .filter(d => d);
        const differenceData = val.filter(v => !defaultIdList.includes(v)).map(n => ({ id: n, title: n }));
        return [...defaultOptionList, ...differenceData];
    };

    useEffect(() => {
        // console.log('props', props, defaultData);
        if (defaultData) {
            setKeyWords(haveCustomData(keywordOption, defaultData?.keywords ?? []));
            reset();
            const nodeValues = {
                proposerTypes: defaultData.proposerTypes[0] ? JSON.stringify(defaultData.proposerTypes[0]) : '', // 提出者
                relevantideas: returnStr(typeOption, defaultData?.form), // 概念类型
                isCoreIdea: returnStr(boolOption, defaultData.isCoreIdea), // 核心概念
                isCrosscuttingIdea: returnStr(boolOption, defaultData.isCrosscuttingIdea), // 是否跨学科
                pointingDimension: returnStr(dimensionOption, defaultData.pointingDimension), // 指向维度
                appearanceWay: returnStr(appearsTypeOption, defaultData.appearanceWay), // 出现方式

                cognitive: returnStr(boolOption, defaultData?.acquisitionDimensions['认知']), // 认知
                skills: returnStr(boolOption, defaultData?.acquisitionDimensions['技能']), // 技能
                emotions: returnStr(boolOption, defaultData?.acquisitionDimensions['情感']), // 情感

                reference: defaultData.reference, // 参考来源
                complexity: returnStr(levelOption, defaultData.complexity), // 复杂度
                abstractionDegree: returnStr(levelOption, defaultData.abstractionDegree), // 抽象程度
                ideaFocusDegree: returnStr(levelOption, defaultData.ideaFocusDegree), // 概念聚焦程度
                inertActivatingProbability: returnStr(levelOption, defaultData.inertActivatingProbability), // 惰性可激活程度
                difficulty: returnStr(levelOption, defaultData.difficulty) // 难度
            };
            // console.log('nodeValues', nodeValues);
            reset(nodeValues);
            return () => {};
        }
    }, [defaultData]);

    // !子组件暴露的部分方法
    useImperativeHandle(parentRef, () => ({
        getWatchSelect: () => {
            const allValues = valueToJSON(watch());
            const { isCoreIdea, isCrosscuttingIdea } = allValues;
            const { cognitive, skills, emotions } = allValues;
            // console.log('allValues', allValues);
            return {
                keywords: keywords.map(k => k.title), // 关键词
                proposerTypes: [allValues.proposerTypes] || null, // 提出者
                form: allValues.relevantideas['id'] || null, // 概念类型
                isCoreIdea: isCoreIdea ? isCoreIdea['id'] === 'true' : null, // 核心概念
                isCrosscuttingIdea: isCrosscuttingIdea ? isCrosscuttingIdea['id'] === 'true' : null, // 是否跨学科
                pointingDimension: allValues.pointingDimension['id'] || null, // 指向维度
                appearanceWay: allValues.appearanceWay['id'] || null, // 出现方式

                // 习得维度
                acquisitionDimensions: {
                    认知: cognitive ? cognitive['id'] === 'true' : null,
                    技能: skills ? skills['id'] === 'true' : null,
                    情感: emotions ? emotions['id'] === 'true' : null
                },

                reference: allValues.reference || null, // 参考来源

                complexity: allValues.complexity['id'] || null, // 复杂度
                abstractionDegree: allValues.abstractionDegree['id'] || null, // 抽象程度
                ideaFocusDegree: allValues.ideaFocusDegree['id'] || null, // 概念聚焦程度
                inertActivatingProbability: allValues.inertActivatingProbability['id'] || null, // 惰性可激活程度
                difficulty: allValues.difficulty['id'] || null // 难度
            };
        },
        resetForm: () => {
            reset();
            setKeyWords([]);
        }
    }));

    return (
        <Box sx={{ widht: '100%', padding: '20px', backgroundColor: 'rgba(176, 176, 176, 0.5)' }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                {/* 标签手动 关键词 */}
                <CustomAutocomplete
                    label='关键词：'
                    placeholder='请选择关键词'
                    limitTags={1}
                    value={keywords}
                    options={keywordOption}
                    handleCloseAll={() => {
                        setKeyWords([]);
                    }}
                    onChange={(e, newVal) => {
                        const saveNewValue = newVal as KeyWords;
                        setKeyWords(saveNewValue);
                    }}
                    onKeyDown={(e, v) => {
                        if (e.key === 'Enter' && v.toString().trim()) {
                            const kList = [...keywords, { id: v, title: v }];
                            setKeyWords(kList);
                        }
                    }}
                    handleTagClick={(v, i) => {
                        const newKwyWord = keywords.filter(k => k.id !== v.id);
                        setKeyWords(newKwyWord);
                    }}
                    style={{
                        wrapStyle: { ...formSx.wrap, height: '100px', width: '100%' },
                        autocompleteStyle: { width: '100%', zIndex: '1', input: { height: '26px' } },
                        labelStyle: formSx.labelStyle,
                        textFieldStyle: { backgroundColor: '#fff', width: '100%' }
                    }}
                />
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                {topPropertiesList.map(s => (
                    <FormHookControl key={s.stateName} name={s.stateName} control={control}>
                        <SelectField
                            label={s.label}
                            control={control}
                            multiple={false}
                            required={false}
                            getAllOption={s?.getAllOption}
                            optionsType={['id', 'name']}
                            registerRef={register(s.stateName, { required: false })}
                            // placeholder={'请选择' + s.label}
                            style={formSx}
                            options={s.options || []}
                        />
                    </FormHookControl>
                ))}
            </Box>
            <span style={{ color: '#fff' }}>习得维度</span>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                {contentPropertiesList.map(s => (
                    <FormHookControl key={s.stateName} name={s.stateName} control={control}>
                        <SelectField
                            label={s.label}
                            control={control}
                            multiple={false}
                            required={false}
                            getAllOption={s?.getAllOption}
                            optionsType={['id', 'name']}
                            registerRef={register(s.stateName, { required: false })}
                            // placeholder={'请选择' + s.label}
                            style={{
                                ...formSx,
                                wrap: {
                                    ...formSx.wrap,
                                    height: '40px',
                                    width: '31%',
                                    flexDirection: 'row',
                                    alignContent: 'center'
                                },
                                labelStyle: { ...formSx.labelStyle, lineHeight: '30px', width: '50px' }
                            }}
                            options={s.options || []}
                        />
                    </FormHookControl>
                ))}
            </Box>
            <InputField
                required={false}
                label='参考来源'
                // placeholder='请输入参考来源'
                registerRef={register('reference', { required: false })}
                style={{
                    ...formSx,
                    wrap: { ...formSx.wrap, width: '100%', height: '70px' }
                }}
            />
            <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                {footerPropertiesList.map(s => (
                    <FormHookControl key={s.stateName} name={s.stateName} control={control}>
                        <SelectField
                            label={s.label}
                            control={control}
                            multiple={false}
                            required={false}
                            getAllOption={s?.getAllOption}
                            optionsType={['id', 'name']}
                            registerRef={register(s.stateName, { required: false })}
                            // placeholder={'请选择' + s.label}
                            style={formSx}
                            options={s.options || []}
                        />
                    </FormHookControl>
                ))}
            </Box>
        </Box>
    );
});

export default AddNewNode;
