import React, { useEffect, useState, useRef } from 'react';
// import customize components
import { BasicDataTable, DialogBox } from '../../../../components/common';
// import MD
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
// import Divider from '@material-ui/core/Divider';
// import Typography from '@material-ui/core/Typography';
import SaveIcon from '@material-ui/icons/Save';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import DeleteIcon from '@material-ui/icons/Delete';
// import Tooltip from '@material-ui/core/Tooltip';
import FormatShapesIcon from '@material-ui/icons/FormatShapes';
// import react-color
import { CirclePicker } from 'react-color';
// import mock data
import { mockTags } from '../../../../settings/mocks/DefaultTags';
// import { rows } from '../../../../settings/mocks/DefaultNotebooks';
// import redux
import { useSelector } from '../../../../redux/hooks';
import { useDispatch } from 'react-redux';
import { updateNodeInfo, deleteNode } from '../../../../redux/knm/nodeSlice';
import { getGraphDetail } from '../../../../redux/knm/graphSlice';
import { getNodeNotebooks, getNotebookDetail } from '../../../../redux/knm/notebookSlice';
import { NotebookSlice } from '../../../../redux/knm/notebookSlice';
import { changeNodeInfo } from '@/redux/knm/graphSlice';
import { getIdeaDetail } from '@/api/creatMap';
import AddNewNode, { FormRef, GetDefaultValues } from '@components/sections/detail-page/AddNewNode';
import { InputWithLabel, CustomSelect } from '@components/common/form';
import { Box } from '@mui/material';
import { initCreatNodeList, initTagsList } from '@pages/main/knmDetailSubPage/infoPanelContent/AddNewNodePanel';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toggleBtn: {
            width: '100%',
            '& > *': {
                width: '50%'
            }
        },
        infoPanelTitle: {
            fontSize: '18px !important'
        },
        panelSubTitle: {
            fontSize: 16,
            marginTop: 14
        },
        infoPanelForms: {
            // marginBottom: theme.spacing(2),
            '&>*': {
                marginBottom: theme.spacing(2),
                width: '100%'
            }
        },
        colorPicker: {
            marginBottom: '0px !important'
        }
    })
);

interface NodeInfoEditPanelState {
    nodeName: string;
    nodeId: string;
    materialColor: any[];
    handleSwitchViews: (newView: string, isOpenSpecificNotebook?: boolean) => void;
    handleCloseInfoPanel: () => void;
}

interface NodeInfoState {
    nodeId: string;
    nodeName: string;
    nodeTags: any[];
    nodeIntro: string;
    nodeSize: string;
    nodeColor: string;
}

interface Semester {
    key: string;
    value: string;
}
type CreateNodeList = { content: string; nodeList: Semester[]; keywords?: [] }[];

/**
 * 节点地图 单节点编辑
 * @param param0
 * @returns
 */
export const NodeInfoEditPanel: React.FC<NodeInfoEditPanelState> = ({
    nodeName,
    nodeId,
    materialColor,
    handleSwitchViews,
    handleCloseInfoPanel
}) => {
    const createNodeRef = useRef<FormRef>(null);
    const classes = useStyles();
    const [values, setValues] = useState<NodeInfoState>({
        nodeId: '',
        nodeName: nodeName,
        nodeTags: [],
        nodeIntro: '',
        nodeSize: '',
        nodeColor: materialColor[0]
    });
    // 节点详细信息
    const [nodeDetail, setNodeDetail] = useState<GetDefaultValues | undefined>(undefined);
    const [notebooks, setNotebooks] = useState<any[]>([]);
    const [alignment, setAlignment] = React.useState<string>('info');

    // 全部新建列表
    const [allNodeList, setAllNodeList] = useState<Semester[]>(initCreatNodeList);
    // 新建概念全部列表
    const [createNodeList, setCreateNodeList] = useState<CreateNodeList>([]);

    // redux
    const dispatch = useDispatch();
    const currentOpenGraphInfo = useSelector(state => state.graph.currentOpenGraphInfo);
    const jwt = useSelector(state => state.user.token);
    const currentOpenMapInfo = useSelector(state => state.knmMap.currentOpenMapInfo);
    const nodeLoading = useSelector(state => state.node.loading);
    const nodeDeleteLoading = useSelector(state => state.node.deleteLoading);
    const currentNodeNotebooksList = useSelector(state => state.notebook.currentNotebooksList);

    // get current node info base nodeName 获取点击节点的全部属性
    useEffect(() => {
        // console.log('点击不同的节点 nodeName', nodeName, nodeId);
        currentOpenGraphInfo['nodes'].map(node => {
            if (node['properties']['name'] === nodeName || node['properties']['id'] === nodeId) {
                getNodeInfo(node['properties']['id']);
                setValues({
                    nodeId: node['properties']['id'],
                    nodeName: node['properties']['name'],
                    nodeTags: node['tags'] || [],
                    nodeIntro: node['properties']['content'] || '',
                    nodeSize: node['properties']['nodeSize'] || 55,
                    nodeColor: node['properties']['nodeColor'] || ''
                });
                // // get notebook
                // dispatch(
                //     getNodeNotebooks({
                //         jwt: jwt,
                //         graphId: currentOpenGraphInfo['_id'],
                //         nodeId: node['_id']
                //     })
                // );
            }
        });
    }, [nodeName, nodeId]);

    // 获取节点的详细详细
    const getNodeInfo = async id => {
        const nodeData = await getIdeaDetail(id);
        if (nodeData.code === 0) {
            setNodeDetail(nodeData.data);
            const { contentSemesterMap } = nodeData.data;
            const nodeList: CreateNodeList = [];
            for (const key of Object.keys(contentSemesterMap || {})) {
                nodeList.push({
                    content: key,
                    nodeList: contentSemesterMap[key].map(n => ({ key: n.id, value: n.name })),
                    keywords: []
                });
            }
            setCreateNodeList(nodeList);
        }
    };

    // get notebook from current node
    useEffect(() => {
        let newNotebooks: any[] = [];
        currentNodeNotebooksList.map(note => {
            // tags with Clips
            let tagsText: string[] = note['tags'];
            let tags = (
                <React.Fragment>
                    {tagsText.map((tag, index) => (
                        <React.Fragment key={`tag-${index}`}>
                            <Chip label={tag} color='secondary' size='small' variant='default' />
                            &nbsp;
                        </React.Fragment>
                    ))}
                </React.Fragment>
            );
            // let updateTime = new Date(note['updatedAt']).toLocaleString();
            // click button with much more func
            const handleCheckNotebook = async () => {
                // console.log(note);
                let target;
                let targetId;
                if (note['relationNode']) {
                    target = 'node';
                    targetId = note['relationNode'];
                }
                if (note['relationLink']) {
                    target = 'link';
                    targetId = note['relationLink'];
                }
                // console.log(target,' => ',targetId);
                await dispatch(
                    getNotebookDetail({
                        jwt: jwt,
                        graphId: currentOpenGraphInfo['_id'],
                        target: target,
                        targetId: targetId,
                        notebookId: note['_id']
                    })
                );
                //newView: string, isOpenSpecificNotebook?: boolean
                handleSwitchViews('newNotebookView', true);
            };
            let button = (
                <Button
                    key={note['_id']}
                    variant='outlined'
                    color='secondary'
                    size='small'
                    onClick={handleCheckNotebook}
                >
                    查看
                </Button>
            );

            // push into newNotebooks
            newNotebooks.push([note['title'], note['quotes'], tags, button]);
        });
        setNotebooks(newNotebooks);
    }, [currentNodeNotebooksList]);

    // 新建知识笔记
    const handleNewNodeNotebook = async () => {
        dispatch(
            NotebookSlice.actions.createSpecificNotebook({
                createSpecificNotebookRelationType: 'node',
                createSpecificNotebookRelationId: values.nodeId
            })
        );
        handleSwitchViews('newNotebookView');
        // console.log(currentNodeNotebooksList);
    };

    const handleAlignment = (event, newAlignment) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
        }
    };

    // text change
    const handleChangeText = (prop: keyof NodeInfoState) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({
            ...values,
            [prop]: event.target.value
        });
    };

    // node size select
    const handleChangeNodeSize = (event: React.ChangeEvent<{ value: unknown }>) => {
        setValues({
            ...values,
            nodeSize: event.target.value as string
        });
    };

    // node color select
    const handleChangeNodeColor = (newNodeColor, event) => {
        setValues({
            ...values,
            nodeColor: newNodeColor.hex
        });
    };

    // !修改节点信息
    const handleUpdateKnmInfo = async () => {
        // const openNodeInfo = currentOpenGraphInfo['nodes'].find(n => n['properties']['name'] === nodeName);
        // const searchNodeInfo = await getIdeaDetail(values.nodeId);
        const formData = createNodeRef.current?.getWatchSelect();
        const semesters = createNodeList.map(nl => nl.nodeList.map(n => ({ id: n.key, name: n.value }))).flat(2);
        const contentSemesterMap = createNodeList.reduce((all, n) => {
            if (all[n.content]) {
                all[n.content].push(...n.nodeList.map(n => ({ id: n.key, name: n.value })));
            } else {
                all[n.content] = n.nodeList.map(n => ({ id: n.key, name: n.value }));
            }
            return all;
        }, {});
        const params = {
            // ...searchNodeInfo.data.data,
            // ...values.nodeDetail,
            ...nodeDetail,
            ...formData,

            graphId: currentOpenMapInfo.id,
            contentSemesterMap,
            semesters,
            name: values.nodeName,
            content: values.nodeIntro,
            nodeSize: values.nodeSize,
            nodeColor: values.nodeColor
        };

        const editNodeInfo = () => {
            dispatch(
                getGraphDetail({
                    currentOpenMapId: currentOpenMapInfo['id'],
                    jwt: jwt,
                    xhrParams: { graphId: currentOpenMapInfo.id }
                })
            );
        };

        dispatch(
            updateNodeInfo({
                jwt: jwt,
                nodeInfo: values,
                graphId: '', // currentOpenGraphInfo['_id'],
                nodeId: values.nodeId,
                type: 'edit',
                openNodeInfo: params,
                // !刷新数据
                cb: editNodeInfo
            })
        );
    };

    // 删除节点
    const handleDeleteNode = async () => {
        // console.log('删除 节点', values);
        // close dialog
        await setOpenDialog(false);
        // 删除节点
        await dispatch(
            deleteNode({
                jwt: jwt,
                graphId: currentOpenGraphInfo['_id'],
                nodeId: values.nodeId
            })
        );
        // close panel
        handleCloseInfoPanel();
        // !刷新数据
        setTimeout(() => {
            dispatch(
                getGraphDetail({
                    xhrParams: { graphId: currentOpenMapInfo?.id }
                })
            );
        }, 300);
    };

    // 获取需要的筛选的下拉框
    const getFilterSelectArray = list => {
        const alreadyList = createNodeList.map(n => n.nodeList).flat(2);
        const allList = [...allNodeList, ...alreadyList];
        const result: Semester[] = [];
        allList.reduce((result, obj) => {
            // && a.value === obj.value
            if (!alreadyList.find(a => a.key === obj.key)) {
                result.push(obj);
            }
            return result;
        }, result);
        // return [...list, ...result];
        // !用来兼容旧数据避免重复
        const filterOptions = [...new Set([...list, ...result].map(l => l.key))].map(k =>
            initCreatNodeList.find(a => a.key === k)
        );
        // console.log('filterOptions', filterOptions);
        return filterOptions;
    };

    const [openDialog, setOpenDialog] = useState(false);
    interface DeleteNodeState {
        openDialog: boolean;
        handleCloseDialog: () => void;
    }
    /** 删除节点提示 */
    const DeleteNode: React.FC<DeleteNodeState> = ({ openDialog, handleCloseDialog }) => {
        return (
            <DialogBox
                boxSize='xs'
                open={openDialog}
                title={'删除知识节点'}
                contain={
                    <div>
                        请确认是否删除该知识节点？注意！
                        <b style={{ color: 'orange' }}>
                            与该知识节点相关联的知识笔记和知识关联，将在节点删除后同时被删除！且删除后无法恢复！
                        </b>
                        若想要更改信息，建议更新节点信息。若确认删除，则点击“确认”按钮。
                    </div>
                }
                actions={
                    <>
                        <Button size='small' variant='outlined' color='secondary' onClick={handleCloseDialog}>
                            取消
                        </Button>
                        <Button size='small' variant='text' color='primary' onClick={handleDeleteNode}>
                            确认
                        </Button>
                    </>
                }
            />
        );
    };

    return (
        <React.Fragment>
            <ToggleButtonGroup
                value={alignment}
                exclusive
                onChange={handleAlignment}
                aria-label='text alignment'
                size='small'
                className={classes.toggleBtn}
            >
                <ToggleButton value='info' aria-label='left aligned'>
                    <FormatShapesIcon fontSize='small' />
                </ToggleButton>

                <ToggleButton value='notebook' aria-label='centered'>
                    <LibraryBooksIcon fontSize='small' />
                </ToggleButton>
            </ToggleButtonGroup>
            {alignment === 'info' && (
                <form className={classes.infoPanelForms} noValidate autoComplete='off'>
                    <div className={classes.panelSubTitle}>节点信息编辑</div>
                    <TextField
                        id='knm-node-name'
                        label='观念节点名称'
                        size='small'
                        value={values.nodeName}
                        onChange={handleChangeText('nodeName')}
                    />
                    {/* <Autocomplete
                        multiple
                        id='tags-filled'
                        options={mockTags.map(option => option.title)}
                        value={values.nodeTags}
                        freeSolo
                        onChange={(event, newValue) => {
                            setValues({
                                ...values,
                                nodeTags: newValue
                            });
                        }}
                        renderTags={(value: string[], getTagProps) =>
                            value.map((option: string, index: number) => (
                                <Chip
                                    key={index}
                                    variant='default'
                                    label={option}
                                    size='small'
                                    color='primary'
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                        renderInput={params => <TextField {...params} placeholder='选择或输入新标签' />}
                    /> */}
                    {/* <TextField
                        id='knm-node-intro'
                        label='知识节点简介'
                        size='small'
                        value={values.nodeIntro}
                        onChange={handleChangeText('nodeIntro')}
                        multiline
                    /> */}
                    {/* <FormControl>
                        <InputLabel id='demo-simple-select-label'>节点大小</InputLabel>
                        <Select
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            value={values.nodeSize}
                            onChange={handleChangeNodeSize}
                        >
                            <MenuItem value={55}>小</MenuItem>
                            <MenuItem value={64}>较小</MenuItem>
                            <MenuItem value={76}>适中</MenuItem>
                            <MenuItem value={88}>较大</MenuItem>
                            <MenuItem value={100}>大</MenuItem>
                        </Select>
                    </FormControl> */}
                    {/* <div>节点颜色</div>
                    <CirclePicker
                        className={classes.colorPicker}
                        color={values.nodeColor}
                        onChangeComplete={handleChangeNodeColor}
                        colors={materialColor}
                        circleSize={20}
                        width={'350px'}
                    /> */}

                    {/* // !关联概念选择 关联概念内容,关键词 */}
                    {createNodeList.map((n, i) => (
                        <Box key={i} sx={{ marginBottom: '4px' }}>
                            <CustomSelect
                                key={i}
                                multiple
                                value={n.nodeList}
                                selectItemArray={getFilterSelectArray(n.nodeList)}
                                onChange={e => {
                                    setCreateNodeList(pre => {
                                        const nPre = [...pre];
                                        nPre[i].nodeList = e.target.value as Semester[];
                                        return nPre;
                                    });
                                }}
                                formControlStyle={{ backgroundColor: '#fff' }}
                                selectStyle={{ width: '360px' }}
                            />
                            <InputWithLabel
                                placeholder={'请输入内容'}
                                value={n.content}
                                title={n.content}
                                sx={{ display: 'flex', flexDirection: 'column' }}
                                inputStyle={{ input: { backgroundColor: '#fff' } }}
                                onChange={e => {
                                    setCreateNodeList(pre => {
                                        const content: string = e.target.value;
                                        const newPre = [...pre];

                                        newPre[i].content = content;
                                        return newPre;
                                    });
                                }}
                            />
                            {/* <CustomSelect
                                multiple
                                placeholder={'请选择关键词'}
                                // value={semesterKeywords}
                                value={n.keywords}
                                selectItemArray={initTagsList}
                                onChange={e => {
                                    setCreateNodeList(pre => {
                                        const keywords = e.target.value as [];
                                        const newPre = [...pre];
                                        newPre[i].keywords = keywords;
                                        return newPre;
                                    });
                                }}
                                formControlStyle={{ backgroundColor: '#fff' }}
                                selectStyle={{ width: '360px' }}
                            /> */}
                        </Box>
                    ))}
                    {/* 详情 */}
                    <AddNewNode ref={createNodeRef} defaultData={nodeDetail} />
                    <div>
                        <Button
                            variant='contained'
                            color='primary'
                            style={{ width: '48%' }}
                            startIcon={
                                nodeLoading ? (
                                    <CircularProgress style={{ width: 20, height: 20, color: 'white' }} />
                                ) : (
                                    <SaveIcon />
                                )
                            }
                            onClick={handleUpdateKnmInfo}
                        >
                            保存节点信息
                        </Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button
                            variant='text'
                            color='secondary'
                            endIcon={
                                nodeDeleteLoading ? (
                                    <CircularProgress style={{ width: 20, height: 20, color: 'orange' }} />
                                ) : (
                                    <DeleteIcon />
                                )
                            }
                            style={{ width: '48%' }}
                            onClick={() => setOpenDialog(true)}
                        >
                            删除节点
                        </Button>
                        <DeleteNode openDialog={openDialog} handleCloseDialog={() => setOpenDialog(false)} />
                    </div>
                </form>
            )}
            {alignment === 'notebook' && (
                <div>
                    <Grid container direction='row' justifyContent='space-between' alignItems='center'>
                        <Grid item>
                            <div className={classes.panelSubTitle} style={{ marginBottom: 14, fontSize: 17 }}>
                                {values.nodeName} - 笔记列表
                            </div>
                        </Grid>
                        <Grid item>
                            <Button
                                variant='text'
                                color='secondary'
                                size='small'
                                onClick={handleNewNodeNotebook}
                                startIcon={<NoteAddIcon />}
                            >
                                新建笔记
                            </Button>
                        </Grid>
                    </Grid>
                    {notebooks.length === 0 ? (
                        <h2 style={{ textAlign: 'center', color: 'grey' }}>该观念节点暂无笔记&nbsp;&nbsp;请新建笔记</h2>
                    ) : (
                        <BasicDataTable
                            isSmall={true}
                            header={['笔记标题', '引用', '笔记标签', '操作']}
                            rows={notebooks}
                            // buttons={['查看']}
                            // actions={[() => { alert('查看笔记'); }]}
                        />
                    )}
                </div>
            )}
        </React.Fragment>
    );
};
