import { useLocation, useHistory } from 'react-router-dom';
import axios from 'axios';
import { store } from '@/redux/store';

import { toast } from 'react-toastify';

import type { AxiosRequestConfig } from 'axios';

const { REACT_APP_BASE_URL } = process.env;
const BASE_URL = REACT_APP_BASE_URL;

const request = axios.create({
    baseURL: BASE_URL,
    headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
    }
    // withCredentials: true
});

// export const initInterceptor = (store: any) => {
// 请求
request.interceptors.request.use(config => {
    const sessonToken = localStorage.getItem('token');
    if (sessonToken) {
        if (!config.headers) {
            config.headers = {};
        }
        config.headers.token = sessonToken;
    }

    // 让errorMsg默认生效
    if (config.errorMsg === undefined) {
        config.errorMsg = true;
    }

    return config;
});

// 响应
request.interceptors.response.use(
    res => {
        let errorMsg, successMsg;
        if (res.config) {
            errorMsg = res.config.errorMsg;
            successMsg = res.config.successMsg;
        }

        if (res.data.code === 0) {
            // 结果为true 那么返回去掉外层data之后的数据 如果successMsg，则会提示成功信息
            if (successMsg) {
                // 根据successMsg类型来判断是提示successMsg内容，还是后端返回
                if (typeof successMsg === 'string') {
                    toast.success(successMsg);
                } else {
                    toast.success(res.data.msg);
                }
            }
        } else {
            if (typeof errorMsg === 'string') {
                toast.error(errorMsg);
            } else {
                toast.error(res.data.msg);
            }

            if (res.data.code === 10001) {
                const { origin } = window.location;
                localStorage.removeItem('token');
                window.location.href = origin + '/login';
            }
        }

        return res.data;
    },
    error => {
        toast.error(error.message);
        Promise.reject(error?.response?.data || '出错啦');
    }
);
// };

// initInterceptor();
export default request;
