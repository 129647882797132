import React from 'react';
import { Popover, Box, Button } from '@mui/material';
import ErrorIcon from '@material-ui/icons/Error';

interface DelConfirm {
    /** 开关*/
    open: boolean;
    /** 确认*/
    onConfirm?: () => void;
    /** 取消*/
    onClose?: () => void;
    /** id*/
    id?: string;
    /** anchorEl*/
    anchorEl?: any;
    /** 删除文案*/
    delText?: string | JSX.Element;
}

/**
 * create by 李美仪
 * @visibleName  PopConfirm 确认删除弹框
 */
export default function PopConfirm(props: DelConfirm) {
    const { onConfirm, onClose, open, id, anchorEl, delText } = props;
    return (
        <Popover
            id={id}
            anchorEl={anchorEl}
            open={open}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
            }}
            sx={{
                '& .MuiPopover-paper': {
                    overflow: 'auto',
                    width: ' 214px',
                    padding: '16px 15px',
                    background: '#FEFFFF',
                    boxShadow: ' 0px 8px 16px 1px #0000000F',
                    borderRadius: '3px 3px 3px 3px',
                    boxSizing: 'border-box'
                }
            }}
        >
            <Box sx={{ display: 'flex', mb: '21px' }}>
                <ErrorIcon style={{ color: '#FF7D00', marginRight: '9px' }} />
                <span>{delText ? delText : '您确认删除吗？'}</span>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    '& button': {
                        height: '24px',
                        fontSize: '12px',
                        padding: 0,
                        minWidth: '40px'
                    }
                }}
            >
                <Button
                    sx={{
                        width: '64px',
                        borderRadius: '2px 2px 2px 2px'
                    }}
                    variant='contained'
                    onClick={() => onConfirm && onConfirm()}
                >
                    确认删除
                </Button>
                <Button
                    sx={{
                        width: '40px',
                        minWidth: '40px',
                        padding: 0,
                        background: '#F2F3F5',
                        borderRadius: '3px 3px 3px 3px',
                        mr: '8px'
                    }}
                    onClick={() => onClose && onClose()}
                >
                    取消
                </Button>
            </Box>
        </Popover>
    );
}
