import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
// import { createStyles, makeStyles, styled, Theme, ThemeOptions } from '@mui/material/styles';
import { Box } from '@mui/material';

export const NotebookSx = makeStyles((theme: Theme) =>
    createStyles({
        wrap: {
            padding: '30px 50px'
        },
        top_wrap: {
            display: 'flex',
            justifyContent: 'flex-end',
            // justifyContent: 'space-between',
            alignItems: 'center',
            '& button': {
                '&:first-child': {
                    marginRight: '18px'
                }
            }
        },
        select_wrap: {
            widht: '100%',
            padding: '8px 0'
        },
        select_style: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '8px 0'
        }
    })
);
