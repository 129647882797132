import React, { useCallback, useState, useEffect, MutableRefObject, DependencyList } from 'react';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import {
    TypeDataGridProps,
    TypeDataSource,
    TypeComputedProps,
    TypeRowSelection
} from '@inovua/reactdatagrid-enterprise/types';
import { Box, Checkbox } from '@mui/material';
import { Pagination } from '@components/common/pagination';
import '@inovua/reactdatagrid-enterprise/index.css';
// 预设
import { zhCN } from './preset/zhCN';
import { ColumnsType, getColumnsByType } from './columns';
import { useLoading } from '@/utils/hooks';
import { omit, mapValues, isEmpty } from 'lodash';
import { ellipsis, gridRootStyle } from './style';

// 行高
const defaultRowHeight = 42;

export interface DataGridProps extends Partial<Omit<TypeDataGridProps, 'dataSource'>> {
    /** 表格类型，不同类型对应不同的预设列。具体有哪些类型看columns里的getColumnsByType */
    type?: ColumnsType;
    /** 数据 */
    dataSource: TypeDataSource;
    /** 是否有展开列查看详细信息的功能 */
    hasExpandDetail?: boolean;
    /** 自定义渲染详细信息 */
    customRenderRowDetails?: any;
    /** 根据什么设置自动高度。external:根据外部容器; row:行数; pageLimit:一页的行个数 */
    autoHeight?: 'external' | 'row' | 'pageLimit';
    /** 当前数据 */
    curData?: { data: any[]; count: number };
    /** 是否是dataTable */
    isTableFlag?: boolean;
    /** 是否默认锁定操作栏。默认为true */
    lockedActionColumn?: boolean;
    /** 操作栏宽度。默认120 */
    actionsWidth?: number;
    /** datasource为函数时的依赖数组 */
    deps?: DependencyList;
    /** 操作列的按钮 props中的内容见[文档](https://reactdatagrid.io/docs/getting-started#custom-rendering) */
    renderActions?(props): JSX.Element;
    /** 设置当前数据 */
    setCurData?(curData): void;
    /** 设置默认激活行（默认选中） */
    defaultActiveIndex?: number;
}

/**
 * create by 季卓琦
 * @visibleName  DataGrid 数据表格
 */
export const DataGrid: React.FC<DataGridProps> = (props: DataGridProps) => {
    const {
        type,
        columns = getColumnsByType(type),
        dataSource,
        className,
        renderActions,
        hasExpandDetail,
        customRenderRowDetails,
        defaultExpandedRows = {},
        rowExpandHeight,
        autoHeight = 'row',
        defaultLimit = 10,
        checkboxColumn,
        selected,
        unselected,
        idProperty,
        curData,
        setCurData,
        isTableFlag,
        deps = [],
        style,
        lockedActionColumn = true,
        actionsWidth = 120,
        onSelectionChange,
        ...others
    } = props;
    const [limit, setLimit] = useState(defaultLimit);
    const [skip, setSkip] = useState(0);

    // ref
    const [gridRef, setGridRef] = useState<MutableRefObject<TypeComputedProps | null> | null>(null);

    // 当前页面是否全选中
    const [curPageSelect, setCurPageSelect] = useState(false);

    const isDataSourceFucntion = typeof dataSource === 'function';

    // 依赖数组变化时更新数据
    // eslint-disable-next-line
    const loadData = useCallback(isDataSourceFucntion ? dataSource : () => {}, deps) as TypeDataSource;
    // 加载
    const [loading, wrapDataSource] = useLoading(isDataSourceFucntion ? loadData : dataSource, setCurData);
    // 超出后以 tooltip 的方式显示
    const defaultRenderCell = ({ value }) => value;
    const addTooltip = (renderer, params) => (
        <Box sx={ellipsis} title={renderer(params)}>
            {renderer(params)}
        </Box>
    );
    // useEffect(() => {
    //     console.log(idProperty, selected, unselected, 'selected unselected');
    //     console.log(curData, 'curData');
    // }, [selected, unselected, curPageSelect, curData]);
    // 样式
    const dataGridStyle = {
        minHeight:
            dataSource instanceof Array
                ? autoHeight === 'external'
                    ? '100%'
                    : ((autoHeight === 'row' ? (dataSource?.length < 10 ? dataSource?.length : 10) : limit) + 2) *
                          defaultRowHeight +
                      2
                : autoHeight === 'external'
                ? '100%'
                : (limit + 2) * defaultRowHeight + 2,
        border: 'none',
        ...style
    };
    // header 去掉列分割线（边框）
    const headerProps = {
        style: { borderLeft: 'none' }
    };
    // 处理传入的 column，增加超出隐藏
    const costomsColumns = columns?.map(column => {
        return {
            ...column,
            headerProps,
            render: params =>
                column.render ? addTooltip(column.render, params) : addTooltip(defaultRenderCell, params)
        };
    });
    // 如果有renderActions传入则加上一列操作按钮列
    const finalColumns = renderActions
        ? [
              ...costomsColumns,
              {
                  defaultLocked: lockedActionColumn ? ('end' as any) : false,
                  header: '操作',
                  name: 'action',
                  render: renderActions,
                  minWidth: actionsWidth,
                  headerProps: {
                      style: { borderLeft: 'none', paddingLeft: 8 }
                  },
                  cellProps: { className: 'data-grid-action-columns' }
              }
          ]
        : costomsColumns;

    // 展开详细面板
    const renderRowDetails = ({ data }) => {
        return (
            <div style={{ padding: 20, paddingLeft: 50 }}>
                <table>
                    <tbody>
                        {data.detailInfo?.map((name, i) => {
                            return (
                                <tr key={i}>
                                    <td>{name.header}：</td>
                                    <td>{name.value}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    };

    // 展开行高：根据详细信息个数返回高度
    const defaultRowExpandHeight = ({ data }) => {
        return defaultRowHeight + data.detailInfo.length * 30 + 40;
    };

    // 自定义菜单：这里删去了原有菜单的调整列选项（因为似乎没有起作用）
    const renderColumnContextMenu = useCallback((menuProps, { cellProps }) => {
        menuProps.items.splice(11, 4);
        return undefined;
    }, []);

    const expandRelatedProps = () => {
        if (hasExpandDetail || dataSource?.[0]?.detailInfo) {
            const renderDetails = customRenderRowDetails ? customRenderRowDetails : renderRowDetails;
            return {
                defaultExpandedRows: defaultExpandedRows,
                renderRowDetails: renderDetails,
                rowExpandHeight: rowExpandHeight ? rowExpandHeight : defaultRowExpandHeight
            };
        } else {
            return {};
        }
    };

    // 显示为空
    const emptyText = <div>内容为空</div>;
    /** -----checkbox相关------ */

    // 数据变动时修改标题栏checkbox状态
    useEffect(() => {
        if (selected === true) {
            if (unselected === null || (unselected && Object.keys(unselected).length === 0)) {
                // unselected 为空时当前页为全选
                setCurPageSelect(true);
            } else {
                // unselected 不为空，判断一下当前页是否在unselected中
                const isCurPageInUnselected = isUnselectedContainsOne(unselected, curData?.data, idProperty);
                if (isCurPageInUnselected) {
                    // 当前页有至少一项在unselected中
                    setCurPageSelect(false);
                } else {
                    // 当前页没有一项在unselected中
                    setCurPageSelect(true);
                }
            }
        } else {
            if (!loading && curData) {
                // 数据变化时判断一下当前数据有没有全部被选中
                if (!isEmpty(selected) && isSelectedContainsAll(selected, curData?.data, idProperty)) {
                    setCurPageSelect(true);
                } else {
                    setCurPageSelect(false);
                }
            }
        }
    }, [curData, selected, idProperty, loading, unselected]);

    // header中的checkbox响应事件
    const onPageAllSelect = () => {
        // console.log('curPageSelect', curPageSelect);
        const dataMap = gridRef?.current?.dataMap;
        if (curPageSelect) {
            // 此页全选时变全不选
            setCurPageSelect(false);

            // 当前页的数据组成unselected
            const us = mapValues(dataMap, () => true);

            if (dataMap) {
                if (typeof selected === 'object') {
                    onSelectionChange?.({
                        selected: omit(selected, Object.keys(dataMap)),
                        unselected: { ...unselected, ...us }
                    });
                } else {
                    // selected === true
                    if (Object.keys(us).length === curData?.count) {
                        // 取消选择的个数等于总条数，把selected为true改掉
                        onSelectionChange?.({
                            selected: {},
                            unselected: null
                        });
                    } else {
                        onSelectionChange?.({
                            selected: selected as TypeRowSelection,
                            unselected: { ...unselected, ...us }
                        });
                    }
                }
            }
        } else {
            // 此页全未选或部分选择时变全选
            setCurPageSelect(true);
            if (dataMap) {
                if (typeof selected === 'object') {
                    onSelectionChange?.({
                        selected: { ...selected, ...dataMap },
                        unselected
                    });
                } else {
                    // selected === true
                    onSelectionChange?.({
                        selected: selected as TypeRowSelection,
                        unselected: omit(unselected, Object.keys(dataMap))
                    });
                }
            }
        }
    };

    // 自定义checkbox
    const customCheckboxColumn = {
        renderCheckbox: (checkboxProps, cellProps) => {
            if (cellProps.headerCell) {
                const s = selected ? selected : {};
                return (
                    <Checkbox
                        size='small'
                        checked={curPageSelect}
                        onChange={onPageAllSelect}
                        indeterminate={!curPageSelect && (s === true || Object.keys(s).length !== 0)}
                        disableRipple
                        sx={{ p: 0 }}
                    />
                );
            }
        }
    };
    const handleChangeSkip = newSkip => {
        setSkip(newSkip);
    };
    return (
        <Box sx={gridRootStyle}>
            <ReactDataGrid
                style={dataGridStyle}
                licenseKey='AppName=TimegeekApp,Company=Timegeek,ExpiryDate=2023-06-13,LicenseDeveloperCount=1,LicenseType=single_app,Ref=TimegeekLicenseRef,Z=-6670164251463018992-1055108528-2089884949-1234642433-823335566'
                shareSpaceOnResize
                showZebraRows={false}
                showCellBorders='horizontal'
                i18n={zhCN}
                rowHeight={defaultRowHeight}
                headerHeight={defaultRowHeight}
                lockedRowCellStyle={() => ({ background: 'red' })}
                columnMinWidth={100}
                // 菜单相关
                renderColumnContextMenu={renderColumnContextMenu}
                // 展开相关
                {...expandRelatedProps()}
                // 数据相关
                idProperty={idProperty}
                columns={finalColumns}
                // FIXME: datagrid直接用wrapDataSource一直在加载，暂不清楚具体问题所在，先这么解决
                dataSource={isTableFlag ? wrapDataSource : isDataSourceFucntion ? loadData : dataSource}
                emptyText={emptyText}
                // 分页
                pagination
                limit={limit}
                skip={skip}
                onLimitChange={setLimit}
                onSkipChange={setSkip}
                renderPaginationToolbar={({ count, skip }) => {
                    return (
                        <RenderPaginationToolbar
                            count={count}
                            skip={skip}
                            limit={limit}
                            onChangeSkip={handleChangeSkip}
                        />
                    );
                }}
                // ref
                onReady={setGridRef}
                // checkbox
                checkboxColumn={checkboxColumn ? customCheckboxColumn : false}
                selected={selected}
                unselected={unselected}
                onSelectionChange={onSelectionChange}
                {...others}
            />
        </Box>
    );
};
export default DataGrid;
const RenderPaginationToolbar = ({ count, skip, limit, onChangeSkip }) => {
    // const [prevCount, setPrevCount] = useState(0);
    useEffect(() => {
        if (Math.floor(skip / limit + 1) > 1 && skip >= count) {
            // 调整高亮到第一页
            onChangeSkip?.(1);
        }
        // if (count) {
        //     setPrevCount(count);
        // }
    }, [skip, count, limit, onChangeSkip]);

    const handleChangePage = (_, value: number) => {
        onChangeSkip?.((value - 1) * limit);
    };

    return (
        <Pagination
            rootSx={{
                height: defaultRowHeight
            }}
            totalCount={count}
            // 总页数
            totalPage={Math.ceil(count / limit)}
            // 使高亮变为受控模式，当设置到第一页时，需要让第一页高亮
            page={Math.floor(skip / limit + 1)}
            onChange={handleChangePage}
        />
    );
};
// 当前selected是否全部包含list
const isSelectedContainsAll = (selected, list: any[] | undefined, idProperty) =>
    list?.every(item => {
        const id = item[idProperty];
        return id in selected && selected[id] !== undefined;
    });

// 当前unselectecd 是否包含list中的至少一个
const isUnselectedContainsOne = (unselected, list: any[] | undefined, idProperty) =>
    list?.some(item => {
        const id = item[idProperty];
        return id in unselected && unselected[id] !== undefined;
    });
