import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
// import { getNewKnmList } from '../../../redux/knm/knmMapSlice';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { Box, Modal, Button, Dialog } from '@mui/material';
import { DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { FormProvider, InputField, SelectField } from '@/components/common/form';
// import { subjects } from '../../../settings/mocks/DefaultTags';
import { getThemeSetStyle } from '@/utils/hooks';
import { addGraph } from '@/api/creatMap';
import { customizeToast } from '@components/common/toast';

export interface NewKNMInfoState {
    title: string;
    tags: any[];
    intro: string;
}

const formSx = {
    wrap: {
        height: '75px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    labelStyle: { mb: '0.8rem', width: '100%', color: '#000' }
};
const defaultValues = {
    title: '',
    tags: [],
    intro: ''
};
const selectItem = [
    { key: 'chinese', value: 'chinese', name: '语文' },
    { key: 'mathematics', value: 'mathematics', name: '数学' },
    { key: 'english', value: 'english', name: '英语' },
    { key: 'history', value: 'history', name: '历史' },
    { key: 'geography', value: 'geography', name: '地理' },
    { key: 'politics', value: 'politics', name: '政治' },
    { key: 'physics', value: 'physics', name: '物理' },
    { key: 'chemistry', value: 'chemistry', name: '化学' },
    { key: 'biology', value: 'biology', name: '生物' }
];

export default function AddKNMDialog({ openDialog, handleCloseDialog }) {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const defaultStyle = getThemeSetStyle();
    const knmListInfo = useSelector(state => state);

    // const [values, setValues] = useState<NewKNMInfoState>({
    //     title: '',
    //     tags: [],
    //     intro: ''
    // });

    const methods = useForm({
        resolver: yupResolver(
            Yup.object().shape({
                title: Yup.string().required('请输入观念地图名称'),
                tags: Yup.array().of(Yup.string().required('请选择学科')).min(1, '请至少选择一个学科'),
                intro: Yup.string().optional()
            })
        ),
        defaultValues
    });
    const {
        register,
        handleSubmit,
        reset,
        formState: { isSubmitting, errors }
    } = methods;

    const onSubmit = handleSubmit(data => {
        const param = {
            name: data.title,
            subjects: data?.tags
                ?.map(v => {
                    if (!v) return undefined;
                    else return { id: JSON.parse(v).key, name: JSON.parse(v).name };
                })
                .filter(s => s),
            description: data.intro
        };
        addGraph(param).then(res => {
            if (res.code === 0) {
                customizeToast.success('创建成功');
                handleCloseDialog();
                if (location.pathname === '/main/list') {
                    history.push('/main/list', { reload: true });
                }
            } else {
                customizeToast.error(res.data.msg ?? '创建失败');
            }
        });
    });

    // // 创建一个新的概念地图
    // const handleNewKNM = () => {
    //     const param = {
    //         name: values.title,
    //         subjects: values.tags.map(v => {
    //             return { id: v.key, name: v.value };
    //         }),
    //         description: values.intro
    //     };
    //     // dispatch(getNewKnmList(param));
    //     // setValues({ title: '', tags: [], intro: '' });
    //     addGraph(param).then(res => {
    //         if (res.code === 0) {
    //             handleCloseDialog();
    //             setValues({ title: '', tags: [], intro: '' });
    //         }
    //     });
    // };

    useEffect(() => {
        reset();
    }, [openDialog]);

    return (
        <Dialog
            open={openDialog}
            // onClose={() => {
            //     // setValues({ title: '', tags: [], intro: '' });
            // }}
        >
            <DialogTitle>大观念地图</DialogTitle>
            <DialogContent>
                <Box sx={{ width: '450px' }}>
                    <FormProvider methods={methods} onSubmit={onSubmit}>
                        <InputField
                            required={true}
                            label='观念地图名称:'
                            placeholder='请输入观念地图名称'
                            registerRef={register('title', { required: true })}
                            error={Boolean(errors.title)}
                            errorText='请输入观念地图名称'
                            style={formSx}
                        />

                        <SelectField
                            label=''
                            multiple
                            getAllOption
                            optionsType={['key', 'name']}
                            registerRef={register('tags', { required: true })}
                            placeholder='请选择学科'
                            style={{
                                ...formSx,
                                wrap: { ...formSx.wrap, height: '40px' },
                                labelStyle: { display: 'none' }
                            }}
                            error={Boolean(errors.tags)}
                            errorText='请至少选择一个学科'
                            options={selectItem}
                        />
                        <InputField
                            required={false}
                            label='请输入观念地图描述:'
                            placeholder='请输入观念地图名称'
                            registerRef={register('intro', { required: false })}
                            error={Boolean(errors.intro)}
                            errorText='请输入观念地图名称'
                            style={formSx}
                        />

                        {/* <InputWithLabel
                            placeholder={'请输入观念地图名称'}
                            label={'观念地图名称'}
                            value={values.title}
                            sx={{ display: 'flex', flexDirection: 'column' }}
                            onChange={e => {
                                setValues({ ...values, title: e.target.value });
                            }}
                        /> */}
                        {/* <CustomSelect
                            multiple
                            placeholder='请选择学科'
                            value={values.tags}
                            selectItemArray={selectItem}
                            onChange={e => {
                                // console.log(e, '年级学期修改');
                                setValues({ ...values, tags: e.target.value as any });
                            }}
                            selectStyle={{ margin: '20px 0 10px 0', width: '450px' }}
                        /> */}
                        {/* <InputWithLabel
                            placeholder={'请输入观念地图描述'}
                            label={'观念地图描述'}
                            value={values.intro}
                            sx={{ display: 'flex', flexDirection: 'column' }}
                            onChange={e => {
                                setValues({ ...values, intro: e.target.value });
                            }}
                        /> */}
                    </FormProvider>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant='text' color='secondary' onClick={handleCloseDialog}>
                    取消
                </Button>
                <Button variant='text' color='primary' onClick={onSubmit}>
                    新建
                </Button>
            </DialogActions>
        </Dialog>
    );
}
