import React, { useState, useMemo, useEffect, useRef } from 'react';
import { Box, Button, Input, IconButton, Typography } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogActions, Stack } from '@mui/material';

import { HighlightOff, CloudDownload } from '@material-ui/icons';
import LoadingButton from '@mui/lab/LoadingButton';

// import Iconify from '@/components/common/iconify';
// import { FormProvider, RHFAutocomplete } from '@components/common/hook-form';
// import { ModalProps, ExportModalProps } from '@pages/management/data/initial-data/types';

export interface ModalProps<T = any> {
    open: boolean;
    title: React.ReactElement | string;
    // 取消
    onClose: VoidFunction;
    close?: {
        title?: React.ReactElement | string;
        sx?: any;
        other?: any;
    };
    // 确定
    onOk?: (data: T) => void;
    ok?: {
        title?: React.ReactElement | string;
        sx?: any;
        other?: any;
    };
    // 上传 input ID名称
    // inputId?: string;
    content?: React.JSX.Element | React.ReactNode;
    actions?: React.JSX.Element;
}

export default function Modal(props: ModalProps) {
    const { open, onClose, onOk, title, close, ok, content, actions } = props;

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth={'xs'}
            onClose={onClose}
            PaperProps={{
                sx: { minWidth: '500px' }
            }}
        >
            <DialogTitle
                sx={{
                    pb: 2,
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Box>{title}</Box>
                <IconButton color='default' onClick={onClose}>
                    {/* <Iconify icon='ep:close-bold' /> */}
                    <HighlightOff />
                </IconButton>
            </DialogTitle>
            <DialogContent>{content}</DialogContent>
            <DialogActions>
                {actions ?? (
                    <>
                        <Button variant='outlined' color='inherit' onClick={onClose} sx={close?.sx}>
                            {close?.title ?? '取消'}
                        </Button>
                        <Button variant='contained' onClick={onOk} sx={ok?.sx} {...ok?.other}>
                            {ok?.title ?? '选择文件'}
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
}

/**
 * 上传 下载 文件
 * @param props
 * @returns
 */
export const ExcelModal = (props: any) => {
    const saveFile = useRef<FormData | null>(null);

    const { title, open, hasSystem = true, onClose, onOk, content, actions } = props;
    const { modelFile, uplodeFile, options } = props;
    const { type, exportRelationship, exportConception } = props;

    // 已选择文件
    const [haveFile, setHaveFile] = useState(false);
    const [fileName, setFileName] = useState('');

    // 关系
    const [relationshiploading, setRelationshiploading] = useState(false);
    // 概念
    const [conceptionloading, setConceptionloading] = useState(false);

    // 修改文件
    const handleChangeFile = (e, type) => {
        // setHaveFile(true);
        setFileName('');
        const selectedFiles = e.target.files;
        // console.log('上传的文件', e, selectedFiles);
        if (selectedFiles.length) {
            // 处理所选文件，可以循环遍历 selectedFiles 数组来处理每个文件
            for (let i = 0; i < selectedFiles.length; i++) {
                // const formData = new FormData();
                const file = selectedFiles[i];
                setFileName(file.name);
                // console.log(`选择的文件 ${i + 1}:`, file, '文件名称', file.name);
                // formData.append('file', file.fileList);
                saveFile.current = file;
            }
        }
        handleUploadFile(type);
    };

    // 下载 模板
    const downloadModel = (path, name) => {
        if (!path || !name) return;
        // const { path, name } = modelFile;
        const downloadLink = document.createElement('a');
        downloadLink.href = path;
        downloadLink.setAttribute('download', name + new Date().toLocaleString() + '.xlsx');
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    // 上传 文件
    const handleUploadFile = data => {
        // console.log('确认上传', data, saveFile);
        uplodeFile?.(
            saveFile.current,
            () => {
                saveFile.current = null;
                setFileName('');
            },
            data
        );
    };

    // !文件选择 关系
    const InputGXFile = ({ id = 'file-upload-GX' }) => (
        <Input
            id={id}
            type='file'
            onChange={e => {
                handleChangeFile(e, 'gx');
            }}
            inputProps={{ accept: '.xls, .xlsx', multiple: false }}
            style={{ display: 'none' }}
            onLoad={() => {
                console.log('文件是否上传完成');
            }}
        />
    );
    // !文件选择 概念
    const InputFile = ({ id = 'file-upload-GN' }) => (
        <Input
            id={id}
            type='file'
            onChange={e => {
                handleChangeFile(e, 'gn');
            }}
            inputProps={{ accept: '.xls, .xlsx', multiple: false }}
            style={{ display: 'none' }}
            onLoad={() => {
                console.log('文件是否上传完成');
            }}
        />
    );

    // !根据类型 改变内容
    const fileDom = useMemo(() => {
        return haveFile
            ? {
                  content: (
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <InputFile />
                          {/* <Iconify icon='file-icons:microsoft-excel' sx={{ width: '100px', height: '100px' }} /> */}
                          <CloudDownload />
                          <Box component='span'></Box>
                          <Typography variant='body2' component='span' sx={{ mt: 2, mb: 2 }}>
                              {fileName}
                          </Typography>
                          <label htmlFor='file-upload'>
                              <Button variant='outlined' color='inherit' component='span'>
                                  重新选择文件
                              </Button>
                          </label>
                      </Box>
                  ),
                  actions: (
                      <>
                          <Button variant='outlined' size='small' color='inherit' onClick={onClose}>
                              取消
                          </Button>
                          <Button variant='contained' size='small' onClick={handleUploadFile}>
                              确认上传
                          </Button>
                      </>
                  )
              }
            : {
                  content: (
                      <>
                          {type === 'down' ? (
                              <Stack display='flex' flexDirection='column' spacing={3}>
                                  <Box display='flex' alignItems='center' justifyContent='space-between'>
                                      <Box>
                                          <CloudDownload />
                                          <Typography
                                              variant='subtitle2'
                                              component='span'
                                              onClick={() => downloadModel('/assets/概念关系导入.xlsx', '概念关系导入')}
                                              sx={{ pl: 1, verticalAlign: 'top' }}
                                          >
                                              {modelFile?.label ?? '导出当前地图概念关联关系Excel'}
                                          </Typography>
                                      </Box>
                                      <LoadingButton
                                          size='small'
                                          color='inherit'
                                          type='submit'
                                          variant='contained'
                                          loading={relationshiploading}
                                          onClick={() => {
                                              setRelationshiploading(true);
                                              exportRelationship(setRelationshiploading(false));
                                          }}
                                      >
                                          导出关系
                                      </LoadingButton>
                                  </Box>
                                  <Box display='flex' alignItems='center' justifyContent='space-between'>
                                      <Box>
                                          <CloudDownload />
                                          <Typography
                                              variant='subtitle2'
                                              component='span'
                                              onClick={() => downloadModel('/assets/概念关系导入.xlsx', '概念关系导入')}
                                              sx={{ pl: 1, verticalAlign: 'top' }}
                                          >
                                              {modelFile?.label ?? '导出当前地图概念节点Excel'}
                                          </Typography>
                                      </Box>
                                      <LoadingButton
                                          size='small'
                                          color='inherit'
                                          type='submit'
                                          variant='contained'
                                          loading={conceptionloading}
                                          onClick={() => {
                                              setConceptionloading(true);
                                              exportConception(setConceptionloading(false));
                                          }}
                                      >
                                          导出概念
                                      </LoadingButton>
                                  </Box>
                              </Stack>
                          ) : (
                              <>
                                  <InputGXFile />
                                  <InputFile />
                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                      <Box sx={{ display: 'flex', flexDirection: 'column', color: '#637381' }}>
                                          <Typography variant='body2' component='span'>
                                              请使用Excel模版填写和上传数据
                                          </Typography>
                                          <Typography variant='body2' component='span' sx={{ pt: 1 }}>
                                              文件格式为.xlsx或.xls
                                          </Typography>
                                      </Box>
                                      <Box>
                                          <Box
                                              display='flex'
                                              flexDirection='row'
                                              alignItems='center'
                                              sx={{ cursor: 'pointer', ml: 4 }}
                                          >
                                              <CloudDownload />
                                              <Typography
                                                  variant='subtitle2'
                                                  component='span'
                                                  onClick={() =>
                                                      downloadModel('/assets/概念数据导入.xlsx', '概念数据导入')
                                                  }
                                                  sx={{ pl: 1, verticalAlign: 'top' }}
                                              >
                                                  {modelFile?.label ?? '下载概念节点Excel'}
                                              </Typography>
                                          </Box>
                                          <Box
                                              display='flex'
                                              flexDirection='row'
                                              alignItems='center'
                                              sx={{ cursor: 'pointer', ml: 4 }}
                                          >
                                              <CloudDownload />
                                              <Typography
                                                  variant='subtitle2'
                                                  component='span'
                                                  onClick={() =>
                                                      downloadModel('/assets/概念关系导入.xlsx', '概念关系导入')
                                                  }
                                                  sx={{ pl: 1, verticalAlign: 'top' }}
                                              >
                                                  {modelFile?.label ?? '下载概念关联关系Excel'}
                                              </Typography>
                                          </Box>
                                      </Box>
                                  </Box>
                                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                      <Typography variant='body2' component='span' sx={{ mt: 2, mb: 2 }}>
                                          {fileName}
                                      </Typography>
                                  </Box>
                              </>
                          )}
                      </>
                  ),
                  actions: (
                      <>
                          {type === 'down' ? (
                              <></>
                          ) : (
                              <>
                                  <Button variant='outlined' color='inherit' size='small' onClick={onClose}>
                                      取消
                                  </Button>
                                  <label htmlFor='file-upload-GN'>
                                      <Button variant='contained' size='small' component='span' sx={{ ml: 1.5 }}>
                                          导入概念
                                      </Button>
                                  </label>
                                  <label htmlFor='file-upload-GX'>
                                      <Button variant='contained' size='small' component='span' sx={{ ml: 0.5 }}>
                                          导入关系
                                      </Button>
                                  </label>
                              </>
                          )}
                      </>
                  )
              };
    }, [haveFile, fileName, type]);

    useEffect(() => {
        if (!open) {
            setHaveFile(false);
            setFileName('');
        }
        return () => {};
    }, [open]);

    return (
        <React.StrictMode>
            <Modal open={open} title={haveFile ? '上传文件' : title} onClose={onClose} {...fileDom} />
        </React.StrictMode>
    );
};
