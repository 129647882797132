export const mockTags = [
    { title: '元认知' },
    { title: '知识建构' },
    { title: '认知论信念' },
    { title: '复杂系统' },
    { title: '知识观' },
    { title: '概念转变' }
];

export const mockKNMTags = ['相似', '抽象', '构成', '特点', '因果', '前提', '先后', '相关'];

export const subjects = [
    { key: 'chinese', value: '语文' },
    { key: 'mathematics', value: '数学' },
    { key: 'english', value: '英语' },
    { key: 'history', value: '历史' },
    { key: 'geography', value: '地理' },
    { key: 'politics', value: '政治' },
    { key: 'physics', value: '物理' },
    { key: 'chemistry', value: '化学' },
    { key: 'biology', value: '生物' }
];

export const mockDiaryTags = [{ title: '学习计划' }, { title: '学习收获' }, { title: '学习反思' }];
