/**
 * ! deal with the main info of KNM
 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
// api
import { API } from '../../settings/api';
import { getGraphList, addGraph, delGraph, editGraph } from '@/api/creatMap';

import { customizeToast } from '@components/common/toast';

interface KnmMapState {
    loading: boolean;
    deleteLoading: boolean;
    error: string | null;
    knmList: [];
    currentOpenMapInfo: { id: string; subjects: { id: string; name: string }[]; name: string };
    newKnmList: [];
}

const initialKnmMapState: KnmMapState = {
    loading: false,
    deleteLoading: false,
    error: null,
    knmList: [],
    currentOpenMapInfo: { id: '', subjects: [], name: '' },
    newKnmList: []
};

// action: get all knm map lists -> user own
/**
 * ! 可使用getKnmList的地方: 对MongoDB中的map表进行修改时才能够调用此方法, 有以下几个情况
 * 1. create - create knm => drawer上点击"新建知识地图"之后, 更新redux中的knmList
 * 2. update - 在KNMDetailPage的Graph Info面板中更新信息后, 更新redux中的knmList
 * 3. initial - 在初始化项目的时候, 即刚刚进入项目的时候, 使用getKnmList获取 => index.tsx中
 * ! 其余场景, 均从redux中获取 全部的列表
 */
export const getKnmList = createAsyncThunk(
    'knmMap/list',
    async (params: { jwt: string | null; pageNumber?: string; pageSize?: string }, thunkAPI) => {
        try {
            // const knmMapsList = await axios.get(API.map, {
            //     headers: {
            //         Authorization: `bearer ${params.jwt}`
            //     }
            // });
            // console.log('knmMapsList', knmMapsList);
            // return knmMapsList.data;
            const { pageNumber, pageSize } = params;
            const myParams = { pageNumber, pageSize };
            const NewKnmMapsList = await getGraphList(myParams);
            console.log('NewKnmMapsList', NewKnmMapsList);
            return NewKnmMapsList.data.list;
        } catch (error) {
            // console.log(thunkAPI.rejectWithValue(error));
            return thunkAPI.rejectWithValue(error);
        }
    }
);

/**
 * 新接口的全局数据
 */

export const getNewKnmList = createAsyncThunk(
    'knmMap/newList',
    async (param: { name: string; subjects: { id: string; name: string }[]; description: string }) => {
        const newMap = await addGraph(param);
        return newMap;
    }
);

// action: create a new knm map
export const knmCreate = createAsyncThunk(
    'knmMap/create',
    async (
        params: {
            currentKnmMaps: [];
            jwt: string | null;
            title: string;
            tags: string[];
            introduction: string;
            emoji: string;
        },
        thunkAPI
    ) => {
        try {
            const newKnm = await axios.post(
                API.map,
                {
                    title: params.title,
                    tags: params.tags,
                    introduction: params.introduction,
                    emoji: params.emoji
                },
                {
                    headers: {
                        Authorization: `bearer ${params.jwt}`
                    }
                }
            );
            const newKnmMapsInfo = [...params.currentKnmMaps, newKnm.data];
            // console.log(newKnmMapsInfo);
            return newKnmMapsInfo;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

// action: get a detail knm map, with full info
export const getKnmDetail = createAsyncThunk(
    'knmMap/detail',
    async (params: { knmId: string; jwt: string | null; currentKnmList?: []; pageTabs?: [] }, thunkAPI) => {
        try {
            const detailKnm = params?.currentKnmList?.find(knm => knm['id'] === params.knmId);
            // console.log('detailKnm', detailKnm, 'params', params);
            return detailKnm;
            // const detailKnm = await axios.get(
            //     `${API.map}/${params.knmId}`,
            //     {
            //         headers: {
            //             Authorization: `bearer ${params.jwt}`
            //         }
            //     }
            // );
            // return detailKnm.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

// 更新地图信息
export const knmUpdate = createAsyncThunk(
    'knmMap/update',
    async (
        params: {
            // knmId: string;
            // jwt: string | null;
            updateKnmInfo: {
                id: string;
                name: string;
                subjects: { id: string; name: string }[];
                description: string;
            };
            knmList: [];
            cb: VoidFunction;
            // currentOpenMapInfo: {};
        },
        thunkAPI
    ) => {
        try {
            // 更新地图信息
            const newKnmInfo = await editGraph(params.updateKnmInfo);
            console.log('newKnmInfo 更新地图', newKnmInfo);
            if (newKnmInfo.code !== 0) {
                customizeToast.error('修改失败');
                return {
                    knmList: params.knmList,
                    currentOpenMapInfo: params.knmList.find((n: any) => n.id === params.updateKnmInfo.id)
                };
            }
            const newKnmList = params.knmList.map((n: any) => {
                if (n.id === params.updateKnmInfo.id) return params.updateKnmInfo;
                return n;
            });
            params.cb();
            customizeToast.success('修改成功');
            // console.log('修改成功后  newKnmList ====', newKnmList);
            return {
                knmList: newKnmList,
                currentOpenMapInfo: params.updateKnmInfo
            };
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

// action: update a knm map
export const knmDelete = createAsyncThunk(
    'knmMap/delete',
    async (params: { mapId: string; knmList: any[]; cb: VoidFunction }, thunkAPI) => {
        await delGraph(params.mapId)
            .then(res => {
                if (res.code === 0) {
                    const newKnmList = params.knmList.filter(l => l.id !== params.mapId);
                    params.cb();
                    return {
                        knmList: newKnmList
                    };
                }
                return {
                    knmList: params.knmList
                };
            })
            .catch(rej => {});

        // try {
        //     // update current open map info
        //     const newKnmList = await axios.delete(`${API.map}/${params.knmId}`, {
        //         headers: {
        //             Authorization: `bearer ${params.jwt}`
        //         }
        //     });
        //     return {
        //         knmList: newKnmList.data
        //     };
        // } catch (error) {
        //
        // }
    }
);

// knm Map slice
export const KnmMapSlice = createSlice({
    name: 'knmMap',
    initialState: initialKnmMapState,
    reducers: {
        addNewKnmList(state, { payload }) {
            state.knmList = payload;
        }
    },
    extraReducers: {
        // get all knm map lists
        [getKnmList.pending.type]: state => {
            state.loading = true;
        },
        [getKnmList.fulfilled.type]: (state, action) => {
            state.knmList = action.payload;
            state.loading = false;
            state.error = null;
        },
        [getKnmList.rejected.type]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },

        // ! 新的概念列表数据
        [getNewKnmList.pending.type]: state => {
            state.loading = true;
        },
        [getNewKnmList.fulfilled.type]: (state, action) => {
            state.newKnmList = action.payload;
            state.loading = false;
            state.error = null;
        },

        // create a new knm map
        [knmCreate.pending.type]: state => {
            state.loading = true;
        },
        [knmCreate.fulfilled.type]: (state, action) => {
            state.knmList = action.payload;
            state.loading = false;
            state.error = null;
        },
        [knmCreate.rejected.type]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        // get a knm map with detail info
        [getKnmDetail.pending.type]: state => {
            state.loading = true;
        },
        [getKnmDetail.fulfilled.type]: (state, action) => {
            state.currentOpenMapInfo = action.payload;
            state.loading = false;
            state.error = null;
        },
        [getKnmDetail.rejected.type]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        // update a knm map with detail info
        [knmUpdate.pending.type]: state => {
            state.loading = true;
        },
        [knmUpdate.fulfilled.type]: (state, action) => {
            state.knmList = action.payload.knmList;
            state.currentOpenMapInfo = action.payload.currentOpenMapInfo;
            state.loading = false;
            state.error = null;
        },
        [knmUpdate.rejected.type]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        // 删除知识地图
        [knmDelete.pending.type]: state => {
            state.deleteLoading = true;
        },
        [knmDelete.fulfilled.type]: (state, action) => {
            if (action.payload?.knmList) state.knmList = action.payload.knmList;
            state.currentOpenMapInfo = { id: '', subjects: [], name: '' };
            state.deleteLoading = false;
            state.error = null;
        },
        [knmDelete.rejected.type]: (state, action) => {
            state.deleteLoading = false;
            state.error = action.payload;
        }
    }
});

export const { addNewKnmList } = KnmMapSlice.actions;
