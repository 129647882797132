import React, { useState, useCallback, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import DataGrid from '@components/common/datagrid';
import { getQuestionList, delQuestion, findGraph } from '@/api/creatMap';
import { State, SnackbarAlert } from '@components/common/SnackbarAlert';
import PopConfirm from '@components/common/popconfirm';
import NotebookDefault from '@/components/sections/detail-page/NotebookDefault';
import { customizeToast } from '@components/common/toast';

/** 全部问题 */
export default function QuestionPage() {
    // 刷新表格
    const [renderTable, setRenderTable] = useState(0);
    // 问题类型
    const [questionType, setQuestionType] = useState<'create' | 'repair'>('create');
    // 问题ID
    const [questionId, setQuestionId] = useState('');
    // 节点ID
    // const [nodeId, setNodeId] = useState('');
    // 打开创建问题
    const [showCreateQuestion, setShowCreateQuestion] = useState(false);
    // 全部节点ID
    const [nodeIdMap, setNodeIdMap] = useState<Map<string, any>>(new Map());

    // 提示信息
    // const [snackbar, setSnackbar] = useState<State>({ open: false, type: 'success', msg: '' });
    // 弹窗信息
    const [popConfirmData, setPopConfirmData] = useState<{ id: string; open: boolean; anchorEl: any }>({
        id: '',
        open: false,
        anchorEl: null
    });

    // const setSnackData = data => {
    //     setSnackbar({ ...snackbar, ...data });
    // };

    // 问题列表 创建
    const handleChangeType = () => {
        setQuestionType('create');
        // setTopCreatType(type);
        // if (type === 'question') {
        // 	setShowCreateQuestion(true);
        // }
        setShowCreateQuestion(true);
    };

    // !权限审批 - 表格
    const dataSource = useCallback(
        ({ skip, limit, sortInfo }) => {
            const params: any = {};
            return getQuestionList({
                pageNumber: skip / limit + 1,
                pageSize: limit,
                // ideaId: currentActivatedTab.id,
                ...params
            }).then(({ code, data }) => {
                return {
                    data: code === 0 ? data.list : [],
                    count: code === 0 ? data.totalCount : 0
                };
            });
        },
        [renderTable]
    );

    // 表格 -> 删除
    const delTableQuestion = id => {
        delQuestion(id).then(res => {
            if (res.code === 0) {
                setRenderTable(new Date().getTime());
                customizeToast.success('删除成功');
                setPopConfirmData({ id: '', open: false, anchorEl: null });
            } else {
                customizeToast.error('删除失败');
            }
        });
    };

    // useEffect(() => {
    //     findGraph().then(res => {
    //         const { code, data } = res;
    //         if (code === 0) {
    //             data.nodes.map(n => {
    //                 nodeIdMap.set(n['properties']['id'], n);
    //             });
    //         }
    //     });
    // }, []);

    return (
        <Box sx={{ padding: '10px 30px' }}>
            {showCreateQuestion ? (
                <NotebookDefault
                    id={questionId}
                    type={questionType}
                    nodeIdMap={nodeIdMap}
                    goBack={() => {
                        setShowCreateQuestion(false);
                        // setNodeId('');
                    }}
                    // setSnackData={setSnackData}
                />
            ) : (
                <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h1>全部问题</h1>
                        <Button size='small' onClick={handleChangeType}>
                            创建问题
                        </Button>
                    </Box>

                    <DataGrid
                        type='kngViewList'
                        dataSource={dataSource}
                        deps={[{ renderTable }]}
                        renderActions={({ data }) => (
                            <>
                                <Button
                                    size='small'
                                    onClick={() => {
                                        setQuestionType('repair');
                                        setQuestionId(data.id);
                                        setShowCreateQuestion(true);
                                    }}
                                >
                                    查看
                                </Button>
                                <Button
                                    size='small'
                                    variant='outlined'
                                    color='error'
                                    onClick={e => {
                                        setPopConfirmData({
                                            id: data.id,
                                            open: true,
                                            anchorEl: e.currentTarget
                                        });
                                    }}
                                >
                                    删除
                                </Button>
                            </>
                        )}
                    />
                </Box>
            )}
            {/* 二次确认弹窗 */}
            <PopConfirm
                delText={'确定是否删除？'}
                open={popConfirmData.open}
                anchorEl={popConfirmData.anchorEl}
                onConfirm={() => {
                    delTableQuestion(popConfirmData.id);
                }}
                onClose={() => {
                    setPopConfirmData({ id: '', open: false, anchorEl: null });
                }}
            />
            {/* 提示 */}
            {/* <SnackbarAlert open={snackbar.open} type={snackbar.type} msg={snackbar.msg} duration={2000} /> */}
        </Box>
    );
}
