import React from 'react';
import { Box, Pagination as MuiPagination, PaginationProps as MuiPaginationProps, SxProps } from '@mui/material';

interface PaginationProps extends MuiPaginationProps {
    /** 跳过总数判断 */
    skip?: false;
    /** 根节点sx */
    rootSx?: SxProps;
    /** 总项数 */
    totalCount: number;
    /** 总页数 */
    totalPage: number;
}

/**
 * create by 季卓琦<br/>
 * @visibleName  Pagination 分页组件
 */
export const Pagination = (props: PaginationProps) => {
    const { skip, rootSx, totalCount, totalPage, page, onChange, size, ...others } = props;
    // if (!totalCount && !skip) return <></>;
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap',
                color: '#00000099',
                ...(size === 'small' && {
                    fontSize: 12
                }),
                ...rootSx
            }}
        >
            <Box>共 {totalCount} 项数据</Box>
            <MuiPagination
                // 总页数
                count={totalPage}
                // 使高亮变为受控模式，当设置到第一页时，需要让第一页高亮
                page={page}
                variant='outlined'
                shape='rounded'
                color='primary'
                onChange={onChange}
                size={size}
                {...others}
            />
        </Box>
    );
};
