// import axios from 'axios';
import { request } from '@/api';

// const { REACT_APP_BASE_URL } = process.env;
// const BASE_URL = REACT_APP_BASE_URL;
// const BASE_URL = 'http://192.168.124.10:7000';

export interface Page {
    pageNumber: number;
    pageSize: number;
}
export interface TableData<T> extends Page {
    /** 表格数据 */
    list: T[];
    /** 总共页数 */
    totalPage: number;
    /** 总共条数 */
    totalCount: number;
}

// 基础返回
interface AxiosReturn<T> {
    code: number;
    msg: string;
    data: T;
}
// 表格数据
interface TableAndPage<T> {
    pageNumber: number;
    pageSize: number;
    totalCount: number;
    totalPage: number;
    list: T[];
}

// 地图列表
export type GraphList = {
    createTime: string;
    description: string;
    id: string;
    ideaQuantity: string;
    modifiedTime: string;
    name: string;
    subjects: { id: string; name: string }[];
};

// export const request = axios.create({
//     baseURL: BASE_URL,
//     headers: {
//         Accept: 'application/json',
//         'Content-type': 'application/json'
//     }
// });

// !登录
export const userLogins = (params = {}) => request.post('/concept/login', params);
// !登出
// export const userLogOut = (params = {}) => request.post('/concept/', params);

// 地图分页查询
export const getGraphList = async (params = {}) =>
    await request.post<TableData<GraphList>>('/concept/graph-list', params);
// 删除地图
export const delGraph = async (id: string) => await request.delete('/concept/graph/' + id);
// 新增地图
export const addGraph = async (params = {}) => await request.post('/concept/graph', params);
// 修改地图
export const editGraph = async (params = {}) => await request.post('/concept/graph', params);
// 查询 全部、指定 地图
export const findGraph = async (params = {}) =>
    await request.post<{ nodes: []; relationships: [] }>('/concept/graph/find-graph', params);

/**
 * 新增概念
 * @param params
 * @returns
 */
export const addIdeaDetail = async (params = {}) => await request.post('/concept/idea', params);
/**
 * 修改概念
 * @param params
 * @returns
 */
export const setIdeaDetail = async (params = {}) => await request.put('/concept/idea', params);
/**
 * 获取概念详情
 * @param id 节点ID
 * @returns
 */
export const getIdeaDetail = async (id: string) => await request.get('/concept/idea/' + id);
/**
 * 删除节点
 * @param id string
 * @param params
 * @returns
 */
export const delIdeaDetail = async (id: string) => await request.delete('/concept/idea/' + id);

interface AddNodeLinkParams {
    graphId: string;
    name: string;
    description: string;

    startNodeLabel: string;
    endNodeLabel: string;
    relationshipType: string;
    startNodeId: string;
    endNodeId: string;
}
type EditNodeLinkParams = Omit<AddNodeLinkParams, 'graphId' | 'description'>;

// !创建节点关系
export const addNodeLink = async (params: AddNodeLinkParams) => await request.post('/concept/relationship', params);
// !删除节点关系
export const delNodeLink = async (id: string) => await request.delete('/concept/relationship/delete/' + id);
// 编辑节点关系
export const editNodeLink = async (params: EditNodeLinkParams) => await request.put('/concept/relationship', params);

export interface QuestionParams {
    id: string;
    ideaIds: string[];
    name: string;
    content: string;
    keywords: string[];
    proposerTypes: { id: string; name: string }[];
    reference: string;
    problemSituationTypes: [];
    isEssentialProblem: boolean;
    isCrosscuttingProblem: boolean;
    situationAuthenticityDegree: number;
    complexity: number;
    difficulty: number;
    illStructureDegree: number;
    learningTransferType: string;
}
// 问题分页查询
export const getQuestionList = async (params = {}) =>
    await request.post<TableData<QuestionParams>>('/concept/problem-list/by-query', params);
// 创建问题
export const createQuestion = async params => await request.post('/concept/problem', params);
// 编辑问题
export const editQuestion = async params => await request.put('/concept/problem', params);
// 获取问题详情
export const getQuestion = async (id: string) => await request.get('/concept/problem/' + id);
// 删除问题
export const delQuestion = async (id: string) => await request.delete('/concept/problem/' + id);

export interface UploadParams {
    file: File;
    graphId: string;
}
// excel 上传 节点概念
export const excelUploadConcept = params => request.post('/concept/idea/import', params);
// excel 上传 节点关系
export const excelUploadRelationship = params =>
    request.post('/concept/relationship/import', params, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });

// Excel下载 概念导出
export const exportConception = (id: string) =>
    request.get('/concept/idea/export/' + id, {
        responseType: 'blob'
    });
// Excel下载 概念关系导出
export const exportRelationship = params =>
    request.post('/concept/relationship/export', params, {
        responseType: 'blob'
    });
