import { DefaultOperate } from './BasicRender';

// 资源管理
export const kngManageViewColumns = [
    {
        name: 'name',
        header: '地图名称',
        defaultFlex: 2
    },
    {
        name: 'subjects',
        header: '学科',
        defaultFlex: 2,
        render: ({ value }) => value?.map(s => s?.name).join('、')
    },
    {
        name: 'description',
        header: '描述',
        defaultFlex: 1
    },
    {
        name: 'createTime',
        header: '创建时间',
        defaultFlex: 2
    },
    {
        name: 'modifiedTime',
        header: '更新时间',
        defaultFlex: 2
    }
];

export const kngViewColumns = [
    {
        name: 'id',
        header: '序号',
        defaultFlex: 0.5,
        render: ({ rowIndex }) => rowIndex
    },
    {
        name: 'name',
        header: '问题名称',
        defaultFlex: 1
    },
    {
        name: 'semesters',
        header: '年级学期',
        defaultFlex: 2,
        render: ({ value }) => value.map(v => v.name).join('、')
    },
    {
        name: 'keywords',
        header: '关键词',
        defaultFlex: 1,
        render: ({ value }) => value?.join('、')
    },
    {
        name: 'ideas',
        header: '关联概念',
        defaultFlex: 1,
        render: ({ value }) => value.map(v => v.name).join('、')
    },
    {
        name: 'isEssentialProblem',
        header: '是否是本质问题',
        defaultFlex: 1,
        render: ({ value }) => (value ? '是' : '否')
    }
];
