import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import type { ToastOptions, TypeOptions } from 'react-toastify';

// !默认配置
const defaultOption: ToastOptions = {
    position: 'bottom-left',
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
    // theme: 'light'
};

export class CustomizeToast {
    constructor(dOption = defaultOption) {
        this.option = dOption;
    }
    option: ToastOptions | null = null;

    _default(title: string, type: TypeOptions = 'default', option?: ToastOptions) {
        toast[type](title, {
            ...this.option,
            ...option
        });
    }

    myDefault(title = '上传成功') {
        toast(title, {
            position: 'bottom-left',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        });
    }

    success(title: string, option?: ToastOptions) {
        this._default(title, 'success', option);
    }
    warning(title: string, option?: ToastOptions) {
        this._default(title, 'warning', option);
    }
    info(title: string, option?: ToastOptions) {
        this._default(title, 'info', option);
    }
    error(title: string, option?: ToastOptions) {
        this._default(title, 'error', option);
    }

    closeAll() {
        toast.dismiss();
    }
}
export const customizeToast = new CustomizeToast();

/**
 * 提示
 * @param props
 * @returns
 */
export default function MyToast(props: ToastOptions) {
    return (
        <ToastContainer
            closeButton={true}
            position='bottom-left'
            autoClose={2000}
            hideProgressBar={true}
        ></ToastContainer>
    );
}
