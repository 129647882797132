import React, { useEffect, useRef, useState } from 'react';
// import { useSelector } from '@redux/hooks';
// import { TinyMCE, DialogBox } from '@components/common';
import TinymceEditor from '@components/common/richTextEditor/TinymceEditor';
import { Box, Button } from '@mui/material';
import { AddCircleOutline } from '@material-ui/icons';
import { getThemeSetStyle } from '@/utils/hooks';
import { NotebookSx } from './style';
import { InputWithLabel, CascaderSelect, CustomSelect } from '@components/common/form';
import MoreProperties from '@components/sections/detail-page/MoreProperties';
import CustomAutocomplete from '@components/common/autocomplete';
import QuestionSetForm from '@/components/sections/detail-page/QuestionSetForm';
import type { FormRef, GetDefaultValues } from '@/components/sections/detail-page/QuestionSetForm';
import { getQuestion, createQuestion, editQuestion, delQuestion } from '@/api/creatMap';
import { useDebounce } from '@/utils/hooks';
import { State } from '@components/common/SnackbarAlert';
import PopConfirm from '@components/common/popconfirm';
import { customizeToast } from '@components/common/toast';

interface NoteBookProps {
    id: string;
    nodeIdMap: Map<string, any>;
    type: 'create' | 'repair';
    goBack?: () => void;
}

interface FilterCriteria {
    keyWord: { id: number | string; title: string }[];
}

/** 概念地图 - 新建资源 默认 */
export default function NotebookDefault(props: NoteBookProps) {
    const formRef = useRef<FormRef>(null);
    const editorRef = useRef<any>(null);
    const editorAnswerRef = useRef<any>(null);

    const defaultStyle = getThemeSetStyle();
    // 样式
    const styles = NotebookSx();
    const { type, id, nodeIdMap, goBack } = props;

    // 筛选条件
    const [filterCriteria, setFilterCriteria] = useState<FilterCriteria>({
        keyWord: []
    });
    // open more properties
    const [showMoreProperties, setShowMoreProperties] = useState(false);
    // 节点详细信息
    const [questionDetail, setQuestionDetail] = useState<GetDefaultValues | undefined>(undefined);
    // 弹窗信息
    const [popConfirmData, setPopConfirmData] = useState<{ open: boolean; anchorEl: any }>({
        open: false,
        anchorEl: null
    });

    // 创建更多属性
    const handleMoreProperties = () => {
        setShowMoreProperties(!showMoreProperties);
    };

    // 筛选条件赋值
    const handleChangeFilter = v => {
        console.log('多选', v);
        setFilterCriteria({ ...filterCriteria, keyWord: v.target.value });
    };

    const initSate = () => {
        if (!formRef.current) return;
        formRef.current?.resetForm();
        editorRef.current?.clearEditor();
    };

    const changeTitle = (title: string) => {
        if (questionDetail) {
            setQuestionDetail({ ...questionDetail, name: title });
        }
    };

    // const handleEditorChange = (content, editor) => {
    //     console.log('上下文的值:', content);
    //     console.log('editor', editor);

    //     // console.log('useDebounce', useDebounce(editor, 300));
    //     // setEditorContent(JSON.stringify(content));
    // };

    // 创建问题
    const handleCreateQuestion = () => {
        const formData = formRef.current?.getWatchSelect();
        const content = JSON.stringify(editorRef.current.getContent());
        // console.log('formData', formData);
        createQuestion({
            ...formData,
            content
        }).then(res => {
            if (res.code === 0) {
                initSate();
                customizeToast.success('创建成功');
            } else {
                customizeToast.error('创建失败');
            }
        });
    };

    // 编辑问题
    const handleEditorQuestion = () => {
        const formData = formRef.current?.getWatchSelect();
        const content = JSON.stringify(editorRef.current.getContent());
        editQuestion({
            ...questionDetail,
            ...formData,
            content
        }).then(res => {
            if (res.code === 0) {
                customizeToast.success('😃 编辑保存成功');
            } else {
                customizeToast.error('🥹 编辑保存失败');
            }
        });
    };

    useEffect(() => {
        initSate();
    }, [type]);
    useEffect(() => {
        if (type === 'create' || !id) return;
        getQuestion(id).then(res => {
            if (res.code === 0) {
                setQuestionDetail(res.data);
            }
        });
    }, [id]);

    return (
        <Box className={styles.wrap}>
            <Box className={styles.top_wrap} sx={{ justifyContent: type === 'repair' ? 'space-between' : 'flex-end' }}>
                {type === 'repair' && (
                    <Box>
                        <h2>问题：{questionDetail?.name}</h2>
                    </Box>
                )}
                <Box>
                    <Button size='small' variant='contained' onClick={goBack}>
                        返回
                    </Button>
                    {/* 编辑 */}
                    {type === 'repair' ? (
                        <>
                            <Button
                                size='small'
                                variant='outlined'
                                sx={{
                                    marginRight: '18px',
                                    background: 'rgb(154, 163, 170)',
                                    color: '#fff',
                                    border: '1px solid rgb(154, 163, 170)',
                                    '&:hover': { color: 'red' }
                                }}
                                onClick={e => {
                                    setPopConfirmData({ open: true, anchorEl: e.currentTarget });
                                }}
                            >
                                删除
                            </Button>
                            <PopConfirm
                                delText={'确定是否删除？'}
                                open={popConfirmData.open}
                                anchorEl={popConfirmData.anchorEl}
                                onClose={() => {
                                    setPopConfirmData({ open: false, anchorEl: null });
                                }}
                                onConfirm={() => {
                                    delQuestion(id).then(res => {
                                        if (res.code === 0) {
                                            setPopConfirmData({ open: false, anchorEl: null });
                                            customizeToast.success('删除成功');
                                            goBack?.();
                                        } else {
                                            customizeToast.error('删除失败');
                                        }
                                    });
                                }}
                            />
                            <Button size='small' variant='outlined' onClick={handleEditorQuestion}>
                                保存
                            </Button>
                        </>
                    ) : (
                        <Button size='small' variant='outlined' onClick={handleCreateQuestion}>
                            新建
                        </Button>
                    )}
                </Box>
            </Box>

            <Box className={styles.select_wrap}>
                {type === 'repair' ? (
                    //! 编辑 查看 选择框
                    // <React.Fragment>
                    //     <Box className={styles.select_style}>
                    //         <CascaderSelect
                    //             enumType={'group'}
                    //             inputProps={{
                    //                 label: '关键概念',
                    //                 placeholder: '请选择关键概念'
                    //             }}
                    //             styleProps={{
                    //                 inputStyle: { width: '200px', backgroundColor: defaultStyle.backgroundColor }
                    //             }}
                    //             value={{}}
                    //             // onChangeYearData={partInfo => handleChangeSourceData({ mainKey: 'schoolYearInfo', partInfo })}
                    //             onReset={() => {
                    //                 // handleChangeData({
                    //                 //     keyword: 'year',
                    //                 //     newValue: {}
                    //                 // });
                    //             }}
                    //         />
                    //         <CustomSelect
                    //             value={''}
                    //             label='问题情境类型'
                    //             placeholder='请选择问题情境类型'
                    //             selectItemArray={['设计类', '探究类', '决策类', '鉴赏类']}
                    //             onChange={e => {
                    //                 console.log(e, '问题情境类型');
                    //             }}
                    //             formControlStyle={{
                    //                 backgroundColor: defaultStyle.backgroundColor
                    //             }}
                    //         />
                    //     </Box>
                    //     <Box className={styles.select_style}>
                    //         <CustomAutocomplete
                    //             label='关键词：'
                    //             placeholder='请选择关键词'
                    //             limitTags={1}
                    //             value={filterCriteria.keyWord}
                    //             options={[
                    //                 { id: 'j', title: '教师' },
                    //                 { id: 'x', title: '学生' },
                    //                 { id: 'jy', title: '教研员' },
                    //                 { id: 'g', title: '官方' }
                    //             ]}
                    //             handleCloseAll={() => {
                    //                 setFilterCriteria(pre => {
                    //                     pre.keyWord = [];
                    //                     return pre;
                    //                 });
                    //             }}
                    //             onChange={(e, newVal) => {
                    //                 const { keyWord } = filterCriteria;
                    //                 const saveNewValue = newVal as FilterCriteria['keyWord'];
                    //                 const kwIdList = keyWord.map(k => k.id);
                    //                 const filterVal = saveNewValue.filter(n => !kwIdList.includes(n.id));
                    //                 setFilterCriteria({
                    //                     ...filterCriteria,
                    //                     keyWord: [...filterCriteria.keyWord, ...filterVal]
                    //                 });
                    //             }}
                    //             onKeyDown={(e, v) => {
                    //                 if (e.keyCode === 13 && v.toString().trim()) {
                    //                     const kList = [...filterCriteria.keyWord, { id: v, title: v }];
                    //                     setFilterCriteria({ ...filterCriteria, keyWord: kList });
                    //                 }
                    //             }}
                    //             handleTagClick={(v, i) => {
                    //                 const { keyWord } = filterCriteria;
                    //                 const newKwyWord = keyWord.filter(k => k.id !== v.id);
                    //                 setFilterCriteria({ ...filterCriteria, keyWord: newKwyWord });
                    //             }}
                    //             style={{
                    //                 textFieldStyle: {
                    //                     backgroundColor: defaultStyle.backgroundColor,
                    //                     color: defaultStyle.color,
                    //                     width: '220px'
                    //                 }
                    //             }}
                    //         />
                    //         <CustomSelect
                    //             value={''}
                    //             label='提出者'
                    //             placeholder='请选择提出者'
                    //             selectItemArray={['教师', '学生', '教研员', '官方']}
                    //             onChange={e => {
                    //                 console.log(e, '提出者');
                    //             }}
                    //             formControlStyle={{
                    //                 backgroundColor: defaultStyle.backgroundColor
                    //             }}
                    //         />
                    //     </Box>
                    //     <Box className={styles.select_style}>
                    //         <CustomSelect
                    //             value={''}
                    //             label='是否是本质问题'
                    //             placeholder='请选择是否是本质问题'
                    //             selectItemArray={['是', '否']}
                    //             onChange={e => {
                    //                 //
                    //                 console.log(e, '下拉选择框');
                    //             }}
                    //             formControlStyle={{
                    //                 backgroundColor: defaultStyle.backgroundColor
                    //             }}
                    //         />
                    //         <Button
                    //             size='small'
                    //             onClick={handleMoreProperties}
                    //             sx={{ display: 'flex', alignItems: 'center' }}
                    //         >
                    //             <AddCircleOutline />
                    //             <span style={{ lineHeight: '24px', marginLeft: '8px' }}>更多属性</span>
                    //         </Button>
                    //     </Box>
                    // </React.Fragment>
                    <QuestionSetForm
                        key={type}
                        ref={formRef}
                        nodeIdMap={nodeIdMap}
                        defaultData={questionDetail}
                        changeTitle={changeTitle}
                    />
                ) : (
                    <QuestionSetForm key={type} ref={formRef} nodeIdMap={nodeIdMap} />
                    //! 默认 选择框
                    // <React.Fragment>
                    //     <Box className={styles.select_style}>
                    //         <InputWithLabel
                    //             placeholder={'请输入数据刻画名称'}
                    //             label={'问题名称'}
                    //             value={''}
                    //             inputStyle={{
                    //                 input: {
                    //                     backgroundColor: defaultStyle.backgroundColor
                    //                     // '&::placeholder': {
                    //                     // color: defaultStyle.color
                    //                     // }
                    //                 }
                    //             }}
                    //         />
                    //         <InputWithLabel
                    //             placeholder={'请输入关键字'}
                    //             label={'关键字'}
                    //             value={''}
                    //             inputStyle={{
                    //                 input: {
                    //                     backgroundColor: defaultStyle.backgroundColor
                    //                     // '&::placeholder': {
                    //                     //     color: defaultStyle.color
                    //                     // }
                    //                 }
                    //             }}
                    //         />
                    //     </Box>
                    //     <Box className={styles.select_style}>
                    //         <CascaderSelect
                    //             enumType={'group'}
                    //             inputProps={{
                    //                 label: '分析对象',
                    //                 placeholder: '请选择分析对象'
                    //             }}
                    //             styleProps={{
                    //                 inputStyle: { width: '200px', backgroundColor: defaultStyle.backgroundColor }
                    //             }}
                    //             value={{}}
                    //             // onChangeYearData={partInfo => handleChangeSourceData({ mainKey: 'schoolYearInfo', partInfo })}
                    //             onReset={() => {
                    //                 // handleChangeData({
                    //                 //     keyword: 'year',
                    //                 //     newValue: {}
                    //                 // });
                    //             }}
                    //         />
                    //         <CustomSelect
                    //             value={''}
                    //             label='是否是本质问题'
                    //             placeholder='请选择是否是本质问题'
                    //             selectItemArray={['是', '否']}
                    //             onChange={e => {
                    //                 //
                    //                 console.log(e, '下拉选择框');
                    //             }}
                    //             formControlStyle={{
                    //                 backgroundColor: defaultStyle.backgroundColor
                    //             }}
                    //         />
                    //     </Box>
                    //     <Button
                    //         size='small'
                    //         onClick={handleMoreProperties}
                    //         sx={{ display: 'flex', alignItems: 'center' }}
                    //     >
                    //         <AddCircleOutline />
                    //         <span style={{ lineHeight: '24px', marginLeft: '8px' }}>更多属性</span>
                    //     </Button>
                    // </React.Fragment>
                )}
            </Box>
            {/* 富文本编辑区域 */}
            <Box>
                <span style={{ lineHeight: '24px' }}>问题内容：</span>
                <TinymceEditor
                    ref={editorRef}
                    initialValue={questionDetail?.content ? JSON.parse(questionDetail?.content) : ''}
                />
                {/* <span style={{ lineHeight: '24px' }}>答案内容：</span>
                <TinymceEditor ref={editorAnswerRef} initialValue='' /> */}
            </Box>
            {/* 更多属性 */}
            <MoreProperties
                open={showMoreProperties}
                onClose={() => {
                    setShowMoreProperties(false);
                    return new Promise((resolve, reject) => {});
                }}
            />
        </Box>
    );
}
