import React, { useCallback } from 'react';
import { Box } from '@mui/material';
import DataGrid from '@components/common/datagrid';
import { getQuestionList } from '@/api/creatMap';

interface NodeQuestionProps {
    nodeId: string;
}

// 详细节点的问题
export default function NodeQuestion(props: NodeQuestionProps) {
    const { nodeId } = props;

    // !权限审批 - 表格
    const dataSource = useCallback(
        ({ skip, limit, sortInfo }) => {
            const params: any = {};
            return getQuestionList({
                pageNumber: skip / limit + 1,
                pageSize: limit,
                ideaId: nodeId,
                ...params
            }).then(({ code, data }) => {
                return {
                    data: code === 0 ? data.list : [],
                    count: code === 0 ? data.totalCount : 0
                };
            });
        },
        [nodeId]
    );

    return (
        <Box sx={{ p: 1 }}>
            <DataGrid
                type='kngViewList'
                dataSource={dataSource}
                defaultLimit={5}
                deps={[{ nodeId }]}
                // renderActions={({ data }) => (
                //     <>
                //         <Button
                //             size='small'
                //             onClick={() => {
                //                 setQuestionType('repair');
                //                 setQuestionId(data.id);
                //                 setShowCreateQuestion(true);
                //             }}
                //         >
                //             查看
                //         </Button>
                //         <Button
                //             size='small'
                //             variant='outlined'
                //             color='error'
                //             onClick={e => {
                //                 setPopConfirmData({
                //                     id: data.id,
                //                     open: true,
                //                     anchorEl: e.currentTarget
                //                 });
                //             }}
                //         >
                //             删除
                //         </Button>
                //     </>
                // )}
            />
        </Box>
    );
}
