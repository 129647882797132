import React, { useMemo } from 'react';
import { Box, Typography, Autocomplete, TextField, Chip } from '@mui/material';
import type { AutocompleteChangeReason } from '@mui/material';
// XXX：MUI5 没有下载ICON
import CloseIcon from '@material-ui/icons/Close';

type Option = { id: number | string; title: string }[];
type CustomAutocompleteProps = {
    options: Option;
    value?: Option;
    /** 最多显示多少个标签 默认两个 */
    limitTags?: number;
    /** 提示 */
    label?: string;
    /** 为空 占位符 */
    placeholder?: string;
    /** 默认选中 数据 */
    defaultValue?: Option;
    /** 选中禁止点击 数据 */
    disableOption?: Option;
    /** 样式 */
    style?: {
        wrapStyle?: any;
        /** label 样式 */
        labelStyle?: any;
        /** TextFieldStyle 样式 */
        textFieldStyle?: any;
        /** autocomplete 样式 */
        autocompleteStyle?: any;
        /** 气泡外包裹 样式 */
        chipWrapStyle?: any;
        /** 气泡样式 */
        chipStyle?: any;
    };

    /** 数据改变函数 */
    onChange: (
        event: React.SyntheticEvent<Element, Event>,
        value: Option | null,
        reason: AutocompleteChangeReason
    ) => void;
    /** 清除全部函数 */
    handleCloseAll: () => void;
    /** 输入框回车, 使用该功能是需要先开启多选 */
    onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>, v: string) => void;
    /** 标签点击删除 */
    handleTagClick?: (o: { id: number | string; title: string }, i: number) => void;
};

export default function CustomAutocomplete(props: CustomAutocompleteProps) {
    const {
        value,
        options,
        defaultValue,
        label,
        placeholder,
        limitTags,
        disableOption,
        style,
        onChange,
        handleCloseAll,
        onKeyDown,
        handleTagClick,
        ...others
    } = props;

    // ! 显示占位符提示词
    const setPlaceholder = useMemo(() => {
        let p = placeholder;
        if (typeof value === 'object') {
            if (value instanceof Array && value.length) {
                p = '';
            }
        }
        return p;
    }, [value]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', ...style?.wrapStyle }}>
            <Typography sx={{ display: 'inline-block', ...style?.labelStyle }}>
                <span>{label}</span>
            </Typography>
            <Autocomplete
                size='small'
                multiple
                // clearOnEscape
                // disablePortal
                // ! 清除按钮
                clearIcon={<CloseIcon onClick={handleCloseAll}></CloseIcon>}
                // freeSolo
                limitTags={limitTags ?? 2}
                id='size-small-outlined-multi'
                value={value}
                options={options}
                defaultValue={defaultValue}
                getOptionLabel={option => option.title}
                // 显示选中
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={onChange}
                renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                        <div
                            key={index}
                            style={{ cursor: 'pointer', overflow: 'hidden', ...style?.chipWrapStyle }}
                            onClick={() => handleTagClick?.(option, index)}
                            title={option.title}
                        >
                            <Chip
                                variant='outlined'
                                label={option.title}
                                size='small'
                                {...getTagProps({ index })}
                                disabled={disableOption?.indexOf(option) !== -1}
                                // key={index}
                                // onDelete={() => handleTagClick?.(option, index)}
                                sx={style?.chipStyle}
                            />
                        </div>
                    ))
                }
                renderInput={params => (
                    <TextField
                        {...params}
                        placeholder={setPlaceholder}
                        onKeyDown={e => onKeyDown?.(e, (params.inputProps?.value as string) || '')}
                        sx={{ width: '200px', ...style?.textFieldStyle }}
                    />
                )}
                sx={{ width: '200px', ...style?.autocompleteStyle }}
                {...others}
            />
        </Box>
    );
}
