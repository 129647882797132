import React from 'react';
// import React-Router-DOM
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import Layout
import { MainLayout, LoginLayout } from './layout';
// import page
import { HomePage, KNMListPage, SearchPage, KNMDetailPage, UserSpacePage } from './pages';
import { Login } from '@/pages/loginRegister/Login';
import UserSpace from '@pages/main/UserSpace';
import KNMManage from '@/pages/main/KNMManage';
import QuestionPage from '@/pages/main/QuestionPage';
import MyRouter from '@router/index';

const App: React.FC = () => {
    return (
        <React.StrictMode>
            {/* <MyRouter /> */}
            {/* // FIXME 这种路由配置有问题 */}
            <Router>
                <Switch>
                    {/* Main Page: need user login -> jwtVerify in PageTabs.tsx */}
                    <Route
                        render={() => (
                            <MainLayout>
                                <HomePage />
                            </MainLayout>
                        )}
                        exact
                        path='/'
                    />
                    <Route
                        render={() => (
                            <MainLayout>
                                <KNMManage />
                            </MainLayout>
                        )}
                        path='/main/list'
                    />
                    <Route
                        render={() => (
                            <MainLayout>
                                <SearchPage />
                            </MainLayout>
                        )}
                        path='/main/search'
                    />
                    <Route
                        render={() => (
                            <MainLayout>
                                <QuestionPage />
                            </MainLayout>
                        )}
                        path='/main/question'
                    />
                    <Route
                        render={() => (
                            <MainLayout>
                                <KNMDetailPage />
                            </MainLayout>
                        )}
                        path='/main/detail'
                    />
                    <Route
                        render={() => (
                            <MainLayout>
                                {/* <UserSpacePage /> */}
                                <UserSpace />
                            </MainLayout>
                        )}
                        path='/main/userSpace'
                    />
                    {/* Login & Register */}
                    <Route path='/login' render={() => <LoginLayout />} />
                    <Route path='/user/register' render={() => <LoginLayout />} />
                    {/* 404 NOT FOUND */}
                    <Route render={() => <h1>404 NOT FOUND</h1>} />
                </Switch>
            </Router>
        </React.StrictMode>
    );
};

export default App;
