export const DefaultKNM = [
    [
        // 'knm1',
        '📚学习科学地图',
        ['学习科学', '基本理论'],
        'Franz Zhao',
        // '这是一段知识地图的简介...',
        '2021年10月11日'
    ],
    [
        // 'knm2',
        '🧩学习设计地图',
        ['学习科学', '学习设计'],
        'Franz Zhao',
        // '这是一段知识地图的简介...',
        '2021年10月11日'
    ],
    [
        // 'knm4',
        '🪶元认知地图',
        ['认知心理学', '元认知'],
        'Franz Zhao',
        // '这是一段知识地图的简介...',
        '2021年10月11日'
    ],
    [
        // 'knm5',
        '🎁知识建构地图',
        ['学习科学', '知识建构'],
        'Franz Zhao',
        // '这是一段知识地图的简介...',
        '2021年10月11日'
    ],
    [
        // 'knm6',
        '🎨认识论信念地图',
        ['元认知', '认识论'],
        'Franz Zhao',
        // '这是一段知识地图的简介...',
        '2021年10月11日'
    ],
    [
        // 'knm7',
        '🎯情境认知理论',
        ['学习科学', '情景认知'],
        'Franz Zhao',
        // '这是一段知识地图的简介...',
        '2021年10月11日'
    ],
    [
        // 'knm8',
        '🎗️建构主义理论',
        ['学习科学', '建构主义'],
        'Franz Zhao',
        // '这是一段知识地图的简介...',
        '2021年10月11日'
    ],
    [
        // 'knm9',
        '📕认知加工主义',
        ['认知心理学'],
        'Franz Zhao',
        // '这是一段知识地图的简介...',
        '2021年10月11日'
    ]
];
