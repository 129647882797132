import React, { useState, useEffect, ReactNode } from 'react';
import { styled, Box, CircularProgress, SxProps } from '@mui/material';
// import './style/spin.less'

interface LoadingStyledProps {
    /** 屏幕内容类型*/
    type?: 'relative' | 'absolute';
    /** 是否在加载中 */
    isLoading: boolean;
    /** children的不透明度，默认是0.5 */
    opacity?: number;
    /** 大小，单位是像素 */
    size?: number;
}

interface LoadingProps extends LoadingStyledProps {
    /** 屏幕内容类型*/
    type?: 'relative' | 'absolute'; // absolute----如果屏幕超出一屏，loading 图会显示到下面，所以设置 vh,vw 单位（用户空间）
    /** 当作为包裹元素时，可以自定义描述文案 */
    tips?: ReactNode;
    /** 延迟显示加载效果的时间（防止闪烁） */
    delay?: number;
    /** 子元素 */
    children?: ReactNode;
    /** sx, children的父元素的样式 */
    sx?: SxProps;
    /** 加载图表样式 */
    loadIconSx?: SxProps;
}

let timer: number;

const Wrapper = styled(Box, {
    shouldForwardProp: prop => prop !== 'isLoading' && prop !== 'opacity' && prop !== 'size' && prop !== 'type'
})<LoadingStyledProps>(({ isLoading, opacity, size = 40, type }) => ({
    position: 'relative',
    height: '100%',
    '& .loading-container': {
        opacity: isLoading ? opacity : '1'
    },
    '& .spin-wrapper': {
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%'
    },
    '& .spin': {
        position: 'absolute',
        ...(type === 'absolute' ? { top: '50vh', left: '50vw' } : { top: '50%', left: '50%' }),
        transform: 'translate(-50%, -50%)',
        marginTop: `-${size / 2}px`,
        // lineHeight: 'normal',
        ...(isLoading ? {} : { display: 'none' })
    }
}));

/**
 * create by 季卓琦
 * @visibleName  Loading 加载组件
 */

export function Loading(props: LoadingProps) {
    const { isLoading, children, type = 'relative', tips, delay, opacity = 0.5, size = 40, sx, loadIconSx } = props;

    const [visible, setVisible] = useState(isLoading);

    useEffect(() => {
        if (delay) {
            // 防闪烁
            timer && clearTimeout(timer);
            if (props.isLoading) {
                timer = window.setTimeout(() => setVisible(true), props.delay);
            } else {
                setVisible(false);
            }
        } else {
            setVisible(isLoading);
        }
    }, [isLoading]);

    return (
        <Wrapper opacity={opacity} isLoading={visible} size={size} type={type}>
            {isLoading && (
                <Box sx={{ zIndex: 1100, lineHeight: 0, ...loadIconSx }} className={children ? 'spin-wrapper' : ''}>
                    {/* TODO: 设计组loading样式出图？ */}
                    <CircularProgress className={children ? 'spin' : ''} size={size} />
                </Box>
            )}
            {children && (
                <Box className='loading-container' sx={sx}>
                    {children}
                </Box>
            )}
        </Wrapper>
    );
}
