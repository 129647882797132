export const semestersOption = [
    { id: 'G01F', name: '一上' },
    { id: 'G01S', name: '一下' },
    { id: 'G02F', name: '二上' },
    { id: 'G02S', name: '二下' },
    { id: 'G03F', name: '三上' },
    { id: 'G03S', name: '三下' },
    { id: 'G04F', name: '四上' },
    { id: 'G04S', name: '四下' },
    { id: 'G05F', name: '五上' },
    { id: 'G05S', name: '五下' },
    { id: 'G06F', name: '六上' },
    { id: 'G06S', name: '六下' },

    { id: 'G07F', name: '七上' },
    { id: 'G07S', name: '七下' },
    { id: 'G08F', name: '八上' },
    { id: 'G08S', name: '八下' },
    { id: 'G09F', name: '九上' },
    { id: 'G09S', name: '九下' },

    { id: 'G10F', name: '高一上' },
    { id: 'G10S', name: '高一下' },
    { id: 'G11F', name: '高二上' },
    { id: 'G11S', name: '高二下' },
    { id: 'G12F', name: '高三上' },
    { id: 'G12S', name: '高三下' }
];
