import React from 'react';
import { FormControl, FormHelperText, TextField } from '@mui/material';
import { Box, Typography, Button, TextFieldProps } from '@mui/material';

export type TextInputWithLabelProps = {
    /** 标签 */
    label?: string;
    /** placeholder */
    placeholder?: string;
    /** 文本框值 */
    value?: any;
    /** 文本更改触发的回调函数 */
    onValueChange?: (data) => void;
    /** 行为按钮 */
    buttonText?: string | null;
    /** 行为按钮的触发函数 */
    onClickButton?: () => void;
    /** 输入框装饰*/
    endInputProps?: JSX.Element;
    startInputProps?: JSX.Element;
    /** 是否必选*/
    required?: boolean;
    /** 组件样式 */
    sx?: any;
    /** 标签样式 */
    labelStyle?: any;
    /** 输入框样式 */
    inputStyle?: any;
    /** 输入框父盒子的样式*/
    inputWrapperStyle?: any;
    /** formControl 的样式*/
    formControlStyle?: any;
} & TextFieldProps;

/**
 * create by 李美仪
 * @visibleName InputWithLabel 带标签的文本输入框
 */
export const InputWithLabel: React.FC<TextInputWithLabelProps> = ({
    label,
    value,
    required,
    onValueChange,
    placeholder = '请输入',
    buttonText,
    onClickButton,
    startInputProps,
    endInputProps,
    sx,
    labelStyle,
    inputStyle,
    inputWrapperStyle,
    formControlStyle,
    error,
    helperText,
    ...others
}: TextInputWithLabelProps) => {
    return (
        <Box sx={{ display: 'flex', ...sx }}>
            <Typography sx={{ ...labelStyle }}>
                <span
                    style={{
                        display: 'block',
                        lineHeight: '42px',
                        ...{ ...(inputStyle?.height ? { lineHeight: inputStyle.height } : {}) }
                    }}
                >
                    {label ? (
                        required ? (
                            <>
                                <span style={{ color: '#eb333f', marginRight: '2px' }}>*</span>
                                {label}：
                            </>
                        ) : (
                            `${label}：`
                        )
                    ) : (
                        ''
                    )}
                </span>
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', ...inputWrapperStyle }}>
                <FormControl size='small' sx={{ width: '100%', ...formControlStyle }}>
                    <TextField
                        placeholder={placeholder}
                        value={value ? value : ''}
                        size='small'
                        onChange={e => onValueChange?.(e)}
                        sx={{
                            width: '100%',
                            minWidth: 200,
                            height: '42px',
                            '& input': {
                                minHeight: '42px',
                                boxSizing: 'border-box'
                            },
                            ...inputStyle
                        }}
                        {...(startInputProps || endInputProps
                            ? {
                                  InputProps: {
                                      endAdornment: endInputProps,
                                      startAdornment: startInputProps
                                  }
                              }
                            : {})}
                        {...others}
                    />
                    {error && helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
                </FormControl>

                {buttonText && (
                    <Button variant='text' onClick={onClickButton}>
                        {buttonText}
                    </Button>
                )}
            </Box>
        </Box>
    );
};

export default InputWithLabel;
